import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { focusColor } from "../../shared/textFieldStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { createCard } from "../../../services/premisesServices";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";

const CreateMemberCard = () => {
  const { t } = useTranslation();
  const { premiseId } = useParams();
  const [duration, setDuration] = useState("forever");
  const [expirationDate, setExpirationDate] = useState("");
  const [durationMonths, setDurationMonths] = useState("");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [nameError, setNameError] = useState(false);
  const navigate = useNavigate();
  const [quantityError, setQuantityError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [monthsError, setMonthsError] = useState(false);

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    setNameError(!validateName(value));
  };

  const validateName = (value) => {
    const regex = /^[A-Za-z\sáéíóúÁÉÍÓÚüÜñÑ]+$/;
    return value.length <= 50 && regex.test(value);
  };

  const handleOptionChange = (event) => {
    setDuration(event.target.value);
    setMonthsError(false);
    setDurationMonths("");
  };

  const handleDateChange = (event) => {
    setExpirationDate(event.target.value);
  };

  const handleMonthsChange = (event) => {
    setDurationMonths(event.target.value);
    if (event.target.value < 0) {
      setMonthsError(true);
    } else {
      setMonthsError(false);
    }
  };
  const isFormValid = () => {
    if (!name || !quantity || !price || nameError || quantityError || priceError || monthsError) {
      return false;
    }
    return true;
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
    if (event.target.value < 0) {
      setQuantityError(true);
    } else {
      setQuantityError(false);
    }
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
    if (event.target.value < 0) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cardToSend = {
      premise_id: premiseId,
      name: name,
      initial_stock: parseInt(quantity),
      price: parseInt(price),
      duration: duration,
      renovation_date: expirationDate,
      renovation_months: parseInt(durationMonths),
    };
    try {
      const response = await createCard(cardToSend);
      if (response.result === "success" && response !== undefined) {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate("/premises");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{ mt: 8, mb: 5, display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid item xs={11} md={9}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CREATE_MEMBER_CARD")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={8}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    sx={focusColor}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    label={t("NAME")}
                    value={name}
                    onChange={handleNameChange}
                    error={nameError}
                    helperText={nameError && t("MAX_50")}
                  />
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={focusColor}
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      id="quantity"
                      name="quantity"
                      value={quantity}
                      onChange={handleQuantityChange}
                      helperText={quantityError && t("STOCK_ERROR")}
                      error={quantityError}
                      autoComplete="quantity"
                      autoFocus
                      label={t("CARD_QUANTITY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={focusColor}
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      name="price"
                      value={price}
                      onChange={handlePriceChange}
                      error={priceError}
                      helperText={priceError && t("INCORRECT")}
                      autoComplete="price"
                      autoFocus
                      label={t("PRICE")}
                      type="number"
                      step="0.01"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("CARD_DURATION")}
                    <Tooltip
                      title={
                        <Typography variant="caption" sx={{ whiteSpace: "pre-line" }}>
                          {t("CARD_DURATION_INFO")}
                        </Typography>
                      }
                      sx={{ color: "black" }}
                    >
                      <IconButton sx={{ height: 25, width: 35 }}>
                        <InfoIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                </Grid>
                <Grid container>
                  <RadioGroup value={duration} onChange={handleOptionChange}>
                    <FormControlLabel
                      value="forever"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color) !important" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("FOREVER")}
                    />
                    <FormControlLabel
                      value="expiration"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color) !important" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("RENEWAL_DATE")}
                    />
                    {duration === "expiration" && (
                      <TextField
                        sx={[focusColor, { mb: 2 }]}
                        type="date"
                        value={expirationDate}
                        onChange={handleDateChange}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                          max: "2100-12-31",
                        }}
                      />
                    )}
                    <FormControlLabel
                      value="duration"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color) !important" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("MONTHS_DURATION")}
                    />
                    {duration === "duration" && (
                      <TextField
                        sx={[focusColor, { mb: 2 }]}
                        type="number"
                        value={durationMonths}
                        onChange={handleMonthsChange}
                        error={monthsError}
                        helperText={monthsError && t("INCORRECT")}
                      />
                    )}
                  </RadioGroup>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: 2 }}>
                <Button
                  disabled={!isFormValid()}
                  type="submit"
                  className="oniria-btn"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
                >
                  {t("CREATE_CARD")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateMemberCard;
