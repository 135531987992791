import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import CardEvents from "../../../components/events/CardEvents";
import { focusColor } from "../../../components/shared/textFieldStyle";
import { getCities, getFilterTrips } from "../../../services/eventsServices";
import { getAllGrouping } from "../../../services/organizationsServices";
import { getPremisesList } from "../../../services/premisesServices";

let perPage = "10";
let numberEvents = "";
let totalTrips = 0;

const Trips = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [dateFilter, setFilterDates] = useState("upcoming");
  const [cityFilter, setCityFilter] = useState("all");
  const [tripsFiltered, setTripsFiltered] = useState([]);
  const [cityNoTrips, setCityNoTrips] = useState(false);
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [grouping, setGrouping] = useState("all");
  const [premise, setPremise] = useState("all");
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const [organization, setOrganization] = useState("");

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  const breadcrumbs = [
    {
      name: t("TRAVELS"),
      link: "/trips",
    },
  ];

  const handleQueryCities = (data) => {
    setCities(data);
  };
  const handleQuerySuccessFiltered = (data) => {
    totalTrips = data.total;
    const trips = data.data;
    perPage = data.perpage;
    numberEvents = data.total;
    setTripsFiltered(trips);
    setCityNoTrips(trips.length === 0);
  };

  const handleCityFilter = (data) => {
    setCityFilter(data);
    setPage(1);
  };
  const handleDateFilter = (data) => {
    setFilterDates(data);
    setPage(1);
  };
  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    setPage(1);
  };

  const { refetch, isLoading, isRefetching } = useQuery(
    ["tripsFiltered", cityFilter, search, dateFilter, page, grouping, premise],
    () => getFilterTrips(cityFilter, search, dateFilter, page, perPage, grouping, premise),
    {
      onSuccess: handleQuerySuccessFiltered,
    }
  );

  const { loading } = useQuery("AllTrips", () => getCities(), {
    onSuccess: handleQueryCities,
  });

  //Ciudades
  const menuItems = cities.map((city) => (
    <MenuItem key={city.city} value={city.city}>
      {city.city}
    </MenuItem>
  ));

  //Change page
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //Sedes
  const { data: premises } = useQuery(
    ["premises", grouping, organization],
    () => getPremisesList(grouping, organization),
    {
      refetchOnWindowFocus: false,
    }
  );

  const premiseItems = premises?.map((premise) => (
    <MenuItem key={premise.premise_id} value={premise.premise_id}>
      {premise.premise_name}
    </MenuItem>
  ));

  //Agrupaciones
  const { data: groups, refetch: refetchGrouping } = useQuery("groupings", () => getAllGrouping(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
  //la ejecutamos solo si es super admin
  useEffect(() => {
    if (isSuperAdmin) {
      refetchGrouping();
    }
  }, []);

  const groupItems = groups?.map((group) => (
    <MenuItem key={group.id} value={group.id}>
      {group.name}
    </MenuItem>
  ));

  const handleGroupingFilter = (data) => {
    setGrouping(data);
    setPage(1);
  };
  const handlePremiseFilter = (data) => {
    setPremise(data);
    setPage(1);
  };

  const numberPages = Math.ceil(parseFloat(numberEvents) / parseFloat(perPage));

  const renderLoading = (refetch) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={refetch ? "100px" : "100vh"}
    >
      <CircularProgress sx={{ color: "var(--secondary-color)" }} />
    </Box>
  );
  const renderNoTripsFound = () => <Grid ml={3}>{t("TRIPS_NOT_FOUND")}</Grid>;

  const renderTrips = () => (
    <Grid
      container
      rowSpacing={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
    >
      {tripsFiltered.map((trip, i) => (
        <CardEvents event={trip} key={i} refetch={refetch} />
      ))}
    </Grid>
  );
  if (isLoading) {
    return renderLoading();
  } else {
    return (
      <>
        <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_TRIPS")} />
        <Grid
          item
          xs={11}
          sm={12}
          sx={{ mb: 5, ml: 3, display: "flex", flexDirection: "row", justifyContent: "initial" }}
        >
          <Grid
            container
            spacing={1}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
              <TextField
                label={t("SEARCH")}
                value={search}
                onChange={handleSearchChange}
                size="small"
                sx={[focusColor, { width: "75%" }]}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              columnGap={1}
              rowGap={1}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {isSuperAdmin && (
                <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                  <Select
                    value={grouping}
                    onChange={(e) => handleGroupingFilter(e.target.value)}
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    <MenuItem value="all">{t("ALL_ENTITIES")}</MenuItem>
                    {groupItems}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={premise}
                  onChange={(e) => handlePremiseFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
                  {premiseItems}
                </Select>
              </FormControl>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={cityFilter}
                  onChange={(e) => handleCityFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem value="all">{t("ALLS_CITIES")}</MenuItem>
                  {menuItems}
                </Select>
              </FormControl>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={dateFilter}
                  onChange={(e) => handleDateFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem value="upcoming">{t("UPCOMING")}</MenuItem>
                  <MenuItem value="pasts">{t("PAST")}</MenuItem>
                  <MenuItem value="alls">{t("ALLS")}</MenuItem>
                </Select>
              </FormControl>
              <Button
                type="submit"
                onClick={() => navigate("/create_trip")}
                className="oniria-btn"
                variant="contained"
                sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
              >
                {t("ADD_TRIP")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {totalTrips > 0 && (
          <Grid container justifyContent={"center"} pb={2}>
            <Grid
              item
              xs={11}
              sm={12}
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>
                {t("TRAVELS")} : {totalTrips}
              </Typography>
              {numberPages > 1 && (
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bolder",
                    "& .MuiPaginationItem-root": {
                      "&.Mui-selected": {
                        background: "#DC4451",
                        color: "#fff",
                        fontWeight: "bolder",
                      },
                      fontWeight: "600",
                    },
                  }}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </Grid>
          </Grid>
        )}
        {cityNoTrips ? renderNoTripsFound() : isRefetching ? renderLoading(true) : renderTrips()}
        {numberPages > 1 && (
          <Pagination
            count={numberPages}
            sx={{
              mb: 20,
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": { background: "#DC4451", color: "#fff", fontWeight: "bolder" },
                fontWeight: "600",
              },
            }}
            page={page}
            onChange={handleChangePage}
          />
        )}
      </>
    );
  }
};
export default Trips;
