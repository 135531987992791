import { SERVICE } from "../constants/api"
import http from "../constants/axios"

const formatoFecha = (fecha) => {
  const [anio, mes, dia] = fecha.split("-")
  return `${mes}/${dia}/${anio}`
}

//todas las facturas con sus filtros
export async function getBills(
  search,
  page,
  perPage,
  selectedPremise,
  selectedEvent,
  date,
  order,
  method,
  billType
) {
  let params = new URLSearchParams()

  if (page) params.append("page", page)
  if (perPage) params.append("perpage", perPage)
  if (selectedPremise) params.append("premiseid", selectedPremise)
  if (selectedEvent) params.append("eventid", selectedEvent)
  if (date.from) params.append("fromdate", formatoFecha(date.from))
  if (date.to) params.append("todate", formatoFecha(date.to))
  if (order.asc.length > 0) params.append("asc", order.asc.join(","))
  if (order.desc.length > 0) params.append("desc", order.desc.join(","))
  if (search) params.append("search", search)
  if (method) params.append("method", method)
  if (billType) params.append("purchasetype", billType)

  const { data } = await http.get(`${SERVICE.BILLING}?${params.toString()}`)
  return data
}

export async function getBillingPdfId(id) {
  const { data } = await http.get(`${SERVICE.BILLING}/pdf-invoices/${id}`)
  return data
}

//pdf lista facturas
export async function getBillingPdfInvoices(
  selectedEvent,
  selectedPremise,
  date,
  type,
  order,
  billType
) {
  let params = new URLSearchParams()

  if (date.from) params.append("fromdate", formatoFecha(date.from))
  if (date.to) params.append("todate", formatoFecha(date.to))
  if (selectedPremise) params.append("premiseid", selectedPremise)
  if (selectedEvent) params.append("eventid", selectedEvent)
  if (type) params.append("type", type)
  if (order.asc.length > 0) params.append("asc", order.asc.join(","))
  if (order.desc.length > 0) params.append("desc", order.desc.join(","))
  if (billType) params.append("purchasetype", billType)

  const { data } = await http.get(`${SERVICE.BILLING}/pdf-invoices?${params.toString()}`)
  return data
}

export async function getBillingSepa(event, premise, date, billType) {
  const parameters = [
    date.from ? `fromdate=${formatoFecha(date.from)}` : "",
    date.to ? `todate=${formatoFecha(date.to)}` : "",
    premise ? `premiseid=${premise}` : "",
    event ? `eventid=${event}` : "",
    billType ? `purchasetype=${billType}` : "",
  ]
    .filter((param) => param !== "")
    .join("&")
  const { data } = await http.get(
    `${SERVICE.BILLING}/sepa-xml${parameters ? "?" : ""}${parameters}`
  )
  return data
}

export async function getBillingSepaDate(date) {
  const dateFromParameter = formatoFecha(date.from)
  const dateToParameter = formatoFecha(date.to)
  const { data } = await http.get(
    `${SERVICE.BILLING}/sepa-xml?fromdate=${dateFromParameter}&todate=${dateToParameter}`
  )
  return data
}

export async function getManualOk(id) {
  const { data } = await http.get(`${SERVICE.PAYMENTS}/manual-ok?oid=${id}`)
  return data
}

//FACTURACIÓN SUPER ADMIN
export async function getSepas(premiseId, date, page, perPage, order) {
  let params = new URLSearchParams()

  if (premiseId) params.append("premiseid", premiseId)
  if (date.from) params.append("fromdate", date.from)
  if (date.to) params.append("todate", date.to)
  if (page) params.append("page", page)
  if (perPage) params.append("perpage", perPage)
  if (order?.asc.length > 0) params.append("asc", order.asc.join(","))
  if (order?.desc.length > 0) params.append("desc", order.desc.join(","))
  const { data } = await http.get(`${SERVICE.SEPAS}?${params.toString()}`)
  return data
}

export async function getDownloadSepa(id) {
  const { data } = await http.get(`${SERVICE.SEPAS}/download/${id}`)
  return data
}

export async function getSepaMarkPayed(id) {
  const { data } = await http.get(`${SERVICE.SEPAS}/mark-as-payed/${id}`)
  return data
}

export async function putSepasRegenerate(id) {
  const { data } = await http.put(`${SERVICE.SEPAS}/regenerate/${id}`)
  return data
}

export async function getSepasPdf(date, page, perPage, order) {
  let params = new URLSearchParams()

  if (date.from) params.append("fromdate", date.from)
  if (date.to) params.append("todate", date.to)
  if (page) params.append("page", page)
  if (perPage) params.append("perpage", perPage)
  if (order?.asc.length > 0) params.append("asc", order.asc.join(","))
  if (order?.desc.length > 0) params.append("desc", order.desc.join(","))

  const { data } = await http.get(`${SERVICE.SEPAS}/pdfs?${params.toString()}`)
  return data
}

export async function getDownloadPdf(id) {
  const { data } = await http.get(`${SERVICE.SEPAS}/pdfs/download/${id}`)
  return data
}

export async function putPdfRegenerate(id) {
  const { data } = await http.put(`${SERVICE.SEPAS}/pdfs/regenerate/${id}`)
  return data
}

//FIN FACTURACIÓN SUPER ADMIN
