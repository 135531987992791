import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getUserByToken, serviceSignin } from "../services/authenticationServices";
import * as Jwt from "jose";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { userData, userInfo, userToken } from "../store/reducers/userInfoReducer";
import { setHttpBearerToken } from "../constants/axios";
import { secret } from "../constants/secret";
import { focusColor } from "../components/shared/textFieldStyle.jsx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import bcrypt from "bcryptjs";
import { SALT } from "../constants/salt";
import { toastMessageError } from "../components/shared/toastMessage";
import { getCanPublishTrips } from "../services/usersServices.jsx";

const Login = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  };
  const isFormValid = () => {
    if (!email || emailError || !password) {
      return false;
    }
    return true;
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const rememberCheck = data.get("remember");

    const hashedPassword = await bcrypt.hash(data.get("password"), SALT);
    console.log("Hashed password: ", hashedPassword);

    // const compareResult = await bcrypt.compare(data.get('password'), hashedPassword);

    let response = await serviceSignin(data.get("email"), hashedPassword);

    if (response.status === 200) {
      response = response.data;
      setHttpBearerToken(response.token);
      //miramos si el usuario puede publicar viajes
      let canPublishTrips = await getCanPublishTrips();

      var decoded = await Jwt.jwtVerify(response.token, secret);
      //check user roles
      if (decoded.payload.roles.length == 0) {
        toastMessageError(t("ERROR_USER"));
        return;
      }

      dispatch(
        userToken({
          token: response.token,
        })
      );

      dispatch(
        userInfo({
          id: decoded.payload.id,
          email: decoded.payload.email,
          roles: decoded.payload.roles,
          trips: canPublishTrips,
          organization_id: decoded.payload.organization_id,
          grouping_id: decoded.payload.grouping_id,
        })
      );
      decoded.payload.trips = canPublishTrips;

      const userResponse = await getUserByToken();
      const userLogin = userResponse;
      dispatch(userData({ userData: userLogin }));

      if (rememberCheck != null) {
        localStorage.setItem("token", response.token);
        const userDataInfoJson = JSON.stringify(decoded.payload);
        localStorage.setItem("userInfo", userDataInfoJson);
        const userDataJson = JSON.stringify(userResponse);
        localStorage.setItem("userData", userDataJson);
      } else {
        sessionStorage.setItem("token", response.token);
        const userDataInfoJson = JSON.stringify(decoded.payload);
        sessionStorage.setItem("userInfo", userDataInfoJson);
        const userDataJson = JSON.stringify(userResponse);
        sessionStorage.setItem("userData", userDataJson);
      }
      navigate("/");
    } else {
      toastMessageError(t("ERROR_PASSWORD"));
      navigate("/login");
    }
  };

  return (
    <>
      <Grid container sx={{ mt: 3, mb: 2, px: 3 }}>
        <Grid item xs={11} md={6} sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 8 }}>
          {/* <Box sx={{ height: 185 }} component="img" alt="Logo" src={"/media/tardeo_logo.svg"} /> */}
        </Grid>
      </Grid>
      <Grid
        container
        mb={20}
        columnSpacing={4}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        {/* <Grid item xs={11} md={6} mb={2}>
          <Typography variant="h5" sx={{ mb: "20px", fontWeight: "bold" }}>
            {t("WHATS_TIKITUP")}
          </Typography>
          <Typography variant="h6" sx={{ mb: "20px" }}>
            {t("TIKITUP_DESCRIPTION")}
          </Typography>
          <Typography variant="h5" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
            {t("CUSTOMERS_PAY")}
          </Typography>
          <Typography variant="h6" sx={{ mb: "20px" }}>
            {t("CUSTOMERS_PAY_DESCRIPTION")}
          </Typography>
          <Typography variant="h5" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
            {t("TICKETS_WORKING")}
          </Typography>
          <Typography variant="h6" sx={{ mb: "20px" }}>
            {t("TICKETS_WORKING_DESCRIPTION")}
          </Typography>
          <Typography variant="h5" sx={{ mb: "20px", mt: "20px", fontWeight: "bold" }}>
            {t("USERS_REGISTER")}
          </Typography>
          <Typography variant="h6" sx={{ mb: "20px" }}>
            {t("USERS_REGISTER_DESCRIPTION")}
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={11}
          md={5}
          sx={{
            color: "black",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Box
              component="img"
              alt="Letras"
              src={"/media/tardeo_logo.svg"}
              sx={{ height: 150, mb: 4 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
              mb: 15,
              color: "black",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              component={Paper}
              elevation={3}
              sx={{
                py: 3,
                px: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
              }}
            >
              <Typography className="oniria-colorText" variant="h5">
                {t("SIGN_IN_YOUR_ACCOUNT")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1, mb: 1 }} onSubmit={handleLogin}>
                <InputLabel sx={{ fontSize: "20px", color: "black" }}>{t("EMAIL")}</InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  sx={focusColor}
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError && t("INCORRECT_EMAIL")}
                />
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{ fontSize: "20px", color: "black", mb: 1 }}
                >
                  {t("PASSWORD")}
                </InputLabel>
                <FormControl fullWidth variant="outlined" sx={[focusColor, { mb: 1 }]}>
                  <OutlinedInput
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "var(--secondary-color)" }} />
                          ) : (
                            <Visibility sx={{ color: "var(--secondary-color)" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      value="remember"
                      sx={{
                        color: "var(--secondary-color)",
                        "&.Mui-checked": {
                          color: "var(--secondary-color)",
                        },
                      }}
                    />
                  }
                  label={t("SESSION_STARTED")}
                />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    disabled={!isFormValid()}
                    className="oniria-btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, px: 6, fontSize: "20px", borderRadius: 3 }}
                  >
                    {t("LOGIN")}
                  </Button>
                </Box>
                <Link
                  onClick={() => navigate("/forgot")}
                  underline="none"
                  color="inherit"
                  sx={{
                    fontSize: "16px",
                    "&:hover": { color: "var(--primary-color)" },
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  {t("FORGOT_PASS")}
                </Link>
              </Box>
              {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  onClick={() => navigate("/createaccount")}
                  className="oniria-btn"
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ mt: 3, mb: 2, px: 6, fontSize: "20px", borderRadius: 3 }}
                >
                  {t("CREATE_ACCOUNT")}
                </Button>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
