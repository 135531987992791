import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { reorder } from "../../../constants/categories";
import {
  createCategory,
  getCategories,
  getCategoryPremise,
  updateCategoriesPremise,
} from "../../../services/categoriesServices";
import { getPremiseById } from "../../../services/premisesServices";
import Header from "../../Header";
import CustomDialog from "../../shared/CustomDialog";
import { toastMessageError } from "../../shared/toastMessage";
import { AddCategory } from "./AddCategory";
import { CategoryItem } from "./CategoryItem";
import { CreateCategory } from "./CreateCategory";

export const CategoriesList = () => {
  const { t } = useTranslation();
  const { premiseId } = useParams();
  const [premiseName, setPremiseName] = useState("");
  const [selectCategoryId, setSelectCategoryId] = useState("");
  //Queries
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  //Dialogs
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const [newCategory, setNewCategory] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");

  const breadcrumbs = [
    {
      name: t("VENUES"),
      link: "/premises",
    },
    {
      name: premiseName ? premiseName : "",
      link: `/premise/${premiseId}`,
    },
    {
      name: t("EVENT_CATEGORIES"),
      link: `/premise/${premiseId}/member_card`,
    },
  ];

  const handleQueryPremiseSuccess = (data) => {
    setPremiseName(data[0].name);
  };

  const handleSuccessCategories = (data) => {
    setCategories(data);
  };

  useQuery("premise", () => getPremiseById(premiseId), {
    onSuccess: handleQueryPremiseSuccess,
    refetchOnWindowFocus: false,
  });

  useQuery("categories", () => getCategoryPremise(premiseId), {
    onSuccess: handleSuccessCategories,
    refetchOnWindowFocus: false,
  });
  const { refetch: refetchCategories } = useQuery("allCategories", () => getCategories(), {
    onSuccess: (data) => setAllCategories(data),
    refetchOnWindowFocus: false,
  });
  //drag and drop
  const handleSetCategories = ({ destination, source }) => {
    if (!destination) return;
    const newItems = reorder(categories, source.index, destination.index);
    setCategories(newItems);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newItems });
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    }
  };
  const handleSetSubcategories = ({ destination, source }) => {
    const newCategories = categories.map((item) => {
      if (item.category_id !== destination.droppableId) return item;
      const newSubcategories = reorder(item.subcategories, source.index, destination.index);
      return { ...item, subcategories: newSubcategories };
    });
    setCategories(newCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleSetSubcategories", e);
      toastMessageError(t("ERROR_UPDATE_SUBCATEGORIES"));
    }
  };

  //Create new category super admin
  const handleCreateCategory = () => {
    try {
      const categoryToSend = {
        name: newCategory,
      };
      createCategory(categoryToSend);
      refetchCategories();
    } catch (e) {
      console.error("Error handleCreateCategory", e);
      toastMessageError(t("ERROR_ADD_CATEGORY"));
    }
    setNewCategory("");
    setOpenConfirm(false);
  };

  const handleOpenAddCategory = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  //Remove category from the list
  const handleOpenRemove = (category_id) => {
    setSelectCategoryId(category_id);
    setOpenRemove(true);
  };

  const handleRemoveCategory = () => {
    setOpenRemove(false);
    const newCategories = categories.filter((item) => item.category_id !== selectCategoryId);
    const updatedCategories = newCategories.map((category, index) => ({
      ...category,
      position: index,
    }));
    setCategories(updatedCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: updatedCategories });
    } catch (e) {
      console.error("Error handleRemoveCategory", e);
    }
  };

  //Add category to the list
  const handleAddCategory = (categoryId, categoryName, name) => {
    const categoryToAdd = {
      category_name: categoryName,
      category_id: categoryId,
      name: name,
      position: categories.length,
      premise_id: premiseId,
    };
    const newCategories = [...categories, categoryToAdd];
    setCategories(newCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleAddCategory", e);
      toastMessageError(t("ERROR_ADD_CATEGORY"));
    }
  };
  //Edit category
  const handleEditCategory = (id, name) => {
    const newItems = categories.map((category) => {
      if (category.category_id === id) {
        category.name = name;
      }
      return category;
    });
    setCategories(newItems);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newItems });
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    }
  };
  //SUBCATEGORIES
  const handleAddSubcategory = (categoryId, newName) => {
    const newCategories = categories.map((item) => {
      if (item.category_id !== categoryId) return item;
      if (item.subcategories) {
        item.subcategories.push({ name: newName, position: item.subcategories.length });
      } else {
        item.subcategories = [{ name: newName, position: 0 }];
      }
      return item;
    });
    setCategories(newCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleAddSubcategory", e);
      toastMessageError(t("ERROR_ADD_SUBCATEGORY"));
    }
  };
  const handleDeleteSubcategory = (categoryId, index) => {
    const newCategories = categories.map((item) => {
      if (item.category_id !== categoryId) return item;
      item.subcategories.splice(index, 1);
      return item;
    });
    setCategories(newCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleDeleteSubcategory", e);
      toastMessageError(t("ERROR_DELETE_SUBCATEGORY"));
    }
  };

  const handleEditSubcategory = (categoryId, index, newName) => {
    const newCategories = categories.map((item) => {
      if (item.category_id !== categoryId) return item;
      item.subcategories[index].name = newName;
      return item;
    });
    setCategories(newCategories);
    try {
      updateCategoriesPremise(premiseId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleEditSubcategory", e);
      toastMessageError(t("ERROR_EDIT_SUBCATEGORY"));
    }
  };
  //END SUBCATEGORIES

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Grid
        mb={12}
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={3}
      >
        <Typography variant="h7" sx={{ color: "var(--secondary-color)" }}>
          {t("HEADER_EVENT_CATEGORIES")}
        </Typography>
        <DragDropContext onDragEnd={handleSetCategories}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
              >
                {categories.map((item, index) => (
                  <CategoryItem
                    key={item.category_id}
                    item={item}
                    index={index}
                    handleOpenRemove={handleOpenRemove}
                    handleSetSubcategories={handleSetSubcategories}
                    handleEditCategory={handleEditCategory}
                    handleAddSubcategory={handleAddSubcategory}
                    handleDeleteSubcategory={handleDeleteSubcategory}
                    handleEditSubcategory={handleEditSubcategory}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {allCategories.length != categories.length || categories.length == 0 ? (
          <AddCategory
            allCategories={allCategories}
            categories={categories}
            handleAddCategory={handleAddCategory}
          />
        ) : null}
        {isSuperAdmin && (
          <CreateCategory
            newCategory={newCategory}
            setNewCategory={setNewCategory}
            handleOpenAddCategory={handleOpenAddCategory}
          />
        )}
      </Grid>
      {/* Dialog drop category */}
      <CustomDialog
        isOpen={openRemove}
        onClose={() => setOpenRemove(false)}
        title={t("CONFIRM_REMOVE_CATEGORY")}
        //content={t("CONFIRM_REMOVE_CATEGORY_CONTENT")}
        onAccept={handleRemoveCategory}
      />
      {/* Dialog confirm add category  */}
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("CONFIRM_ADD_CATEGORY") + ": " + newCategory}
        content={t("CONFIRM_ADD_CATEGORY_CONTENT")}
        onAccept={handleCreateCategory}
      />
    </>
  );
};
