import { Box, Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";
import CloseIcon from '@mui/icons-material/Close';
import { groupDiscountErrors, groupDiscountErrorsAllOK } from "../../classes/tripClass";

const GroupDiscount = (props) => {

    const { t } = useTranslation();

    const { ticket, checkTicketField, errors,
        ticketIndex, discountIndex,
        isDisabled, checkTicketDiscountField, closeDiscount } = props;
    
    return (
        <Box component={Paper} elevation={0}
            sx={{
                py: 2,
                px: 1,
                mb: 2,
                border: "1px solid #E4E4E4",
                borderRadius: "15px"
            }}
        >
            <Grid container spacing={1} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    {discountIndex == (ticket.group_discounts.length - 1)&&
                    <IconButton onClick={() => closeDiscount(ticketIndex,discountIndex)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>}
                </Grid>
                <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1, textAlign: "center" }}>{t("FROM_MIN")}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        onChange={e => checkTicketDiscountField('min_size', ticketIndex, discountIndex, e, t("INCORRECT"), /^(0|[1-9]\d*)$/)}
                        value={ticket.group_discounts[discountIndex].min_size}
                        type='number'
                        size='small'
                        id="min_size"
                        name="min_size"
                        autoComplete="min_size"
                        error={errors?.min_size !== "" && errors?.min_size !== "empty"}
                        helperText={errors?.min_size !== 'empty' ? errors?.min_size : ''}
                        disabled={isDisabled()}
                        fullWidth
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>
                        {t("TO")}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} >
                    <TextField
                        onChange={e => checkTicketDiscountField('max_size', ticketIndex, discountIndex, e, t("INCORRECT"), /^(0|[1-9]\d*)$/)}
                        value={ticket.group_discounts[discountIndex].max_size}
                        type='number'
                        size='small'
                        id="max_size"
                        name="max_size"
                        autoComplete="max_size"
                        error={errors?.max_size !== "" && errors?.max_size !== "empty"}
                        helperText={errors?.max_size !== 'empty' ? errors?.max_size : ''}
                        disabled={isDisabled()}
                        fullWidth
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>{t("DISCOUNT_OF")}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} >
                    <TextField
                        onChange={e => checkTicketDiscountField('discount', ticketIndex, discountIndex, e, t("INCORRECT"), /^\d+(?:\.\d+)?$/)}
                        value={ticket.group_discounts[discountIndex].discount}
                        type='number'
                        size='small'
                        id="discount"
                        name="discount"
                        autoComplete="discount"
                        error={errors?.discount !== "" && errors?.discount !== "empty"}
                        helperText={errors?.discount !== 'empty' ? errors?.discount : ''}
                        disabled={isDisabled()}
                        fullWidth
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>€</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

export default GroupDiscount;