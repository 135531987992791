import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userClient, userClientErrors } from "../../classes/userClass";
import Address from "../../components/account/forms/Address";
import Contact from "../../components/account/forms/Contact";
import {
  allFieldsOk,
  checkField,
  handleImageSelect,
  transformObjectWithUrls,
} from "../../components/shared/FormsValidator";
import ImageUpload from "../../components/shared/ImageUpload";
import { focusColor } from "../../components/shared/textFieldStyle";
import { USER_STATUS } from "../../constants/variables";
import { getCountries } from "../../services//utilsServices";
import { getPremises } from "../../services/premisesServices";
import { createUser, getGroupingName } from "../../services/usersServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const UserForm = (props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(props.formData);
  const [imageDisable, setImageDisable] = useState(props.imageDisable);
  const [userError, setUserError] = useState(userClientErrors);
  const [premises, setPremises] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountryNationality, setSelectedCountryNationality] = useState("");
  const [selectedCountryStay, setSelectedCountryStay] = useState("");
  const [selectedOptionTypeStay, setSelectedOptionTypeStay] = useState("");
  const documents = ["NIE", "NIF", t("Passport")];
  const [selectedDocument, setSelectedDocument] = useState("");
  const navigate = useNavigate();
  const [redirecting, setRedirecting] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const url = window.location.href;
  const path = url.split("/").slice(4).join("/");
  const [isSending, setIsSending] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");

  useEffect(() => {
    const getGrouping = async () => {
      if (!isSuperAdmin && path == "create_user") {
        const group = await getGroupingName();
        formData.grouping_name = group[0].name;
      }
    };
    getGrouping();
  }, []);

  const handleQueryCountriesSuccess = (data) => {
    setCountries(data);
  };
  const { isLoading } = useQuery("countries", () => getCountries(), {
    onSuccess: handleQueryCountriesSuccess,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (formData.nationality !== "" && countries.length > 0) {
      const result = countries.find((obj) => obj.country_code === formData.nationality);
      setSelectedCountryNationality(
        result?.country_code.toLowerCase() + "-" + result?.official_name_en
      );
    }
    if (formData.country_stay !== "" && countries.length > 0) {
      const result = countries.find((obj) => obj.country_code === formData.country_stay);
      setSelectedCountryStay(result?.country_code.toLowerCase() + "-" + result?.official_name_en);
    }
  }, [formData.nationality, formData.country_stay, countries]);

  const handleChangeCountryNationality = (event) => {
    setSelectedCountryNationality(event.target.value);
    formData.nationality = event.target.value?.split("-")[0].toUpperCase();
    setUserError((prevUserError) => ({ ...prevUserError, nationality: "" }));
  };
  const handleChangeCountryStay = (event) => {
    setSelectedCountryStay(event.target.value);
    formData.country_stay = event.target.value?.split("-")[0].toUpperCase();
    setUserError((prevUserError) => ({ ...prevUserError, country_stay: "" }));
  };
  const handleChangeTypeStay = (event) => {
    setSelectedOptionTypeStay(event.target.value);
    formData.type_of_stay = event.target.value;
    setUserError((prevUserError) => ({ ...prevUserError, type_of_stay: "" }));
  };
  const handleChangeDocument = (event) => {
    setSelectedDocument(event.target.value);
    formData.type_document_identity = event.target.value;
    setUserError((prevUserError) => ({ ...prevUserError, type_document_identity: "" }));
  };
  const handleCheckboxUserGender = (event) => {
    const genderValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      genre: genderValue,
    }));
  };
  const handleCheckboxUserProfession = (event) => {
    const genderValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      profession: genderValue,
    }));
  };
  const handleCheckboxAffiliatedStatus = (event) => {
    const affiliatedValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      affiliated_status: affiliatedValue,
    }));
    setUserError((prevUserError) => ({ ...prevUserError, affiliated_status: "" }));
  };
  const handleChangeOrganization = (event) => {
    //setSelectedOptionTypeStay(event.target.value);
    formData.grouping_name = event.target.value;
    setUserError((prevUserError) => ({ ...prevUserError, grouping_name: "" }));
  };
  function setFormParams(objectName, field, e, atribute = "value") {
    let object = formData;
    object[field] = e.target[atribute];
    setFormData((prev) => ({ ...object }));
  }
  const setFormErrors = (objectName, field, value) => {
    let object = userError;
    object[field] = value;
  };
  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData;
    object[field] = value;
    setFormData((prev) => ({ ...object }));
  };
  const handleFieldChange = (event, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      [fieldName]: fieldValue,
    }));
    if (regex) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: regex.test(fieldValue) ? "" : helperText,
      }));
    } else if (
      fieldName === "born_at" ||
      fieldName === "erasmus_expires_at" ||
      fieldName === "doc_expiration_date"
    ) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: "",
      }));
    }
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };
  useEffect(() => {
    const fetchPremises = async () => {
      try {
        const data = await getPremises();
        setPremises(data);
      } catch (error) {
        console.error("Error fetching premises:", error);
      }
    };
    fetchPremises();
  }, []);
  const handleSelectPremise = (event) => {
    const selectedPremisesIds = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      premise_id: selectedPremisesIds,
    }));
    setUserError((prevUserError) => ({ ...prevUserError, premise_id: "" }));
  };

  const renderTextField = (
    label,
    fieldName,
    value,
    errorProperty,
    helperText,
    regex,
    disabled,
    type
  ) => (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, regex, helperText)}
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && buttonClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : helperText}
        disabled={path !== "create_user" ? true : disabled}
      />
    </>
  );
  const handleCreateUserSubmit = async (e) => {
    e.preventDefault();
    setButtonClicked(true);
    if (allFieldsOk(userError)) {
      const formDataToSend = await transformObjectWithUrls(formData);
      //formDataToSend.password = await bcrypt.hash(formDataToSend.password, SALT)
      try {
        setIsSending(true);
        const response = await createUser(formDataToSend);
        if (response === "success" && response !== undefined) {
          toastMessageSuccess(t("EDIT_SUCCESS"));
          setRedirecting(true);
          setTimeout(() => {
            setFormData("");
            navigate("/users");
          }, 1500);
        }
      } catch (error) {
        setIsSending(false);
        toastMessageError(t("EDIT_ERROR") + error.response.data.error);
      }
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Grid
        container
        sx={{
          mt: 5,
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleCreateUserSubmit}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}
              >
                <Grid item xs={12} md={6}>
                  {renderTextField(
                    t("NAME"),
                    "name",
                    formData.name,
                    userError.name,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderTextField(
                    t("SURNAME"),
                    "surname",
                    formData.surname,
                    userError.surname,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Contact
                    classForm={userClient}
                    formParams={setFormParams}
                    formData={formData}
                    checkField={checkField}
                    errors={userError}
                    setErrors={setFormErrors}
                    buttonClicked={buttonClicked}
                    isDisabled={path !== "create_user" && true}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("IDENTIFY")}</InputLabel>
                  <Grid container sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={4}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="genre"
                            checked={formData.genre === "woman"}
                            onChange={handleCheckboxUserGender}
                            value="woman"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("WOMAN")}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="genre"
                            checked={formData.genre === "male"}
                            onChange={handleCheckboxUserGender}
                            value="male"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("MAN")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="genre"
                            checked={formData.genre === "other"}
                            onChange={handleCheckboxUserGender}
                            value="other"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("OTHER")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("BORN_AT")}</InputLabel>
                  <TextField
                    disabled={path !== "create_user" && true}
                    sx={focusColor}
                    margin="normal"
                    required
                    fullWidth
                    id={"born_at"}
                    name={"born_at"}
                    autoComplete={"born_at"}
                    value={
                      formData.born_at !== "" ? formatDate(formData.born_at) : formData.born_at
                    }
                    type="date"
                    onChange={(e) => handleFieldChange(e, null, t("INCORRECT"))}
                    error={
                      (userError.born_at !== "" && userError.born_at !== "empty") ||
                      (userError.born_at === "empty" && buttonClicked)
                    }
                    helperText={
                      userError.born_at === "empty" || userError.born_at === ""
                        ? ""
                        : t("INCORRECT")
                    }
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1 }}>
                    {t("NATIONALITY")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={selectedCountryNationality === "" && buttonClicked}
                  >
                    <InputLabel id="demo-select-small-label">{t("NATIONALITY")}</InputLabel>
                    <Select
                      disabled={path !== "create_user" && true}
                      label={t("NATIONALITY")}
                      value={selectedCountryNationality ? selectedCountryNationality : ""}
                      onChange={handleChangeCountryNationality}
                      renderValue={(selected) => (
                        <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              objectFit: "cover",
                              marginRight: 10,
                            }}
                            alt="Flag"
                            src={`/media/flags/${selected.split("-")[0]}.svg`}
                          />
                          <Typography>{selected.split("-")[1]}</Typography>
                        </Grid>
                      )}
                    >
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_code}
                          value={
                            country.country_code.toLowerCase() + "-" + country.official_name_en
                          }
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              objectFit: "cover",
                              marginRight: 10,
                            }}
                            alt="Flag"
                            src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                          />
                          {country.official_name_en}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1, mt: 1 }}>
                    {t("COUNTRY_STAY")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={selectedCountryStay === "" && buttonClicked}
                  >
                    <InputLabel id="demo-select-small-label">{t("COUNTRY")}</InputLabel>
                    <Select
                      disabled={path !== "create_user" && true}
                      label={t("COUNTRY")}
                      value={selectedCountryStay ? selectedCountryStay : ""}
                      onChange={handleChangeCountryStay}
                      renderValue={(selected) => (
                        <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              objectFit: "cover",
                              marginRight: 10,
                            }}
                            alt="Flag"
                            src={`/media/flags/${selected.split("-")[0]}.svg`}
                          />
                          <Typography>{selected.split("-")[1]}</Typography>
                        </Grid>
                      )}
                    >
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_code}
                          value={
                            country.country_code.toLowerCase() + "-" + country.official_name_en
                          }
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "35px",
                              objectFit: "cover",
                              marginRight: 10,
                            }}
                            alt="Flag"
                            src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                          />
                          {country.official_name_en}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mt: 1 }}>
                    {t("CITY_STAY")}
                  </InputLabel>
                  {renderTextField(
                    t("CITY_STAY"),
                    "city_stay",
                    formData.city_stay,
                    userError.city_stay,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} mt={1}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("OCCUPATION")}
                  </InputLabel>
                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="profession"
                            checked={formData.profession === "student"}
                            onChange={handleCheckboxUserProfession}
                            value="student"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("STUDENT")}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="profession"
                            checked={formData.profession === "worker"}
                            onChange={handleCheckboxUserProfession}
                            value="worker"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("WORKER")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        disabled={path !== "create_user" && true}
                        control={
                          <Checkbox
                            name="profession"
                            checked={formData.profession === "other"}
                            onChange={handleCheckboxUserProfession}
                            value="other"
                            sx={{
                              color: "var(--secondary-color)",
                              "&.Mui-checked": {
                                color: "var(--secondary-color)",
                              },
                            }}
                          />
                        }
                        label={t("OTHER")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={selectedOptionTypeStay === "" && buttonClicked}
                  >
                    <InputLabel>{t("TYPE_STAY")}</InputLabel>
                    <Select
                      disabled={path !== "create_user" && true}
                      labelId="type_stay"
                      id="type_stay"
                      value={
                        formData.type_of_stay !== ""
                          ? formData.type_of_stay
                          : selectedOptionTypeStay
                      }
                      onChange={handleChangeTypeStay}
                      label={t("TYPE_STAY")}
                    >
                      <MenuItem value="erasmus">Erasmus</MenuItem>
                      <MenuItem value="seneca">Seneca</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {isSuperAdmin && (
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth variant="outlined" sx={focusColor}>
                      <InputLabel>{t("GROUPING")}</InputLabel>
                      <Select
                        disabled={path !== "create_user" && true}
                        labelId="organization_name"
                        id="organization_name"
                        value={formData.grouping_name}
                        onChange={handleChangeOrganization}
                        label={t("GROUPING")}
                      >
                        <MenuItem value="tardeo">Tardeo</MenuItem>
                        <MenuItem value="spotit">Spotit</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} mt={2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={formData.premise_id === "" && buttonClicked}
                  >
                    <InputLabel>{t("SITE")}</InputLabel>
                    <Select
                      disabled={path !== "create_user" && true}
                      sx={{ mb: 5 }}
                      labelId="premise"
                      id="premise"
                      label={t("SITE")}
                      value={formData.premise_id !== "" ? formData.premise_id : ""}
                      onChange={handleSelectPremise}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {premises.map((premise) => (
                        <MenuItem key={premise.premise_id} value={premise.premise_id}>
                          {premise.premise_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("INTERNATIONAL_STAY_DOCUMENT")}
                  </InputLabel>
                </Grid>
                <Grid
                  container
                  sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}
                >
                  <Grid item xs={12} md={8}>
                    <ImageUpload
                      error={formData.affiliated_document === "" && buttonClicked}
                      url={formData.affiliated_document}
                      selectedImagesInForm={formData.affiliated_document}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          formData,
                          url,
                          "affiliated_document",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      multi={false}
                      disabled={imageDisable}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("MEMBER_CARD_STATUS")}
                  </InputLabel>
                </Grid>
                <Grid container sx={{ mb: 2, ml: 2 }}>
                  <Grid item xs={6} md={3}>
                    <FormControlLabel
                      disabled={path !== "create_user" && true}
                      control={
                        <Checkbox
                          name="affiliated_status"
                          checked={formData.affiliated_status === USER_STATUS.NO_AFFILIATED}
                          onChange={handleCheckboxAffiliatedStatus}
                          value={USER_STATUS.NO_AFFILIATED}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("NOT_REQUESTED")}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControlLabel
                      disabled={path !== "create_user" && true}
                      control={
                        <Checkbox
                          name="affiliated_status"
                          checked={formData.affiliated_status === USER_STATUS.PENDING_VALIDATE}
                          onChange={handleCheckboxAffiliatedStatus}
                          value={USER_STATUS.PENDING_VALIDATE}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("REQUESTED")}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControlLabel
                      disabled={path !== "create_user" && true}
                      control={
                        <Checkbox
                          name="affiliated_status"
                          checked={formData.affiliated_status === USER_STATUS.PENDING_PAYMENT}
                          onChange={handleCheckboxAffiliatedStatus}
                          value={USER_STATUS.PENDING_PAYMENT}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("VALIDATED")}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControlLabel
                      disabled={path !== "create_user" && true}
                      control={
                        <Checkbox
                          name="affiliated_status"
                          checked={formData.affiliated_status === USER_STATUS.VALIDATE}
                          onChange={handleCheckboxAffiliatedStatus}
                          value={USER_STATUS.VALIDATE}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("PAID")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ color: "var(--secondary-color)", mt: 1, mb: 2 }}>
                    {t("TRAVEL_INFORMATION")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("DOCUMENT")}
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    sx={focusColor}
                    error={selectedDocument === "" && buttonClicked}
                  >
                    <InputLabel id="demo-select-small-label">{t("TYPE")}</InputLabel>
                    <Select
                      disabled={path !== "create_user" && true}
                      label={t("TYPE")}
                      value={
                        formData.type_document_identity
                          ? formData.type_document_identity
                          : selectedDocument
                      }
                      onChange={handleChangeDocument}
                    >
                      {documents.map((document) => (
                        <MenuItem key={document} value={document}>
                          {document}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sx={{ mt: -1 }}>
                  {renderTextField(
                    t("IDENTIFICATION_NUMBER"),
                    "identity_number",
                    formData.identity_number,
                    userError.identity_number,
                    t("INCORRECT"),
                    /^(?:[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]|[A-Z0-9]{3,20})$/i
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("EXPIRY_DATE")}
                  </InputLabel>
                  <TextField
                    disabled={path !== "create_user" && true}
                    onChange={(e) => handleFieldChange(e, null, t("INCORRECT"))}
                    type="date"
                    value={
                      formData.doc_expiration_date !== ""
                        ? formatDate(formData.doc_expiration_date)
                        : formData.doc_expiration_date
                    }
                    margin="normal"
                    fullWidth
                    id="doc_expiration_date"
                    name="doc_expiration_date"
                    autoComplete="doc_expiration_date"
                    sx={focusColor}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      max: "2100-12-31",
                    }}
                    error={
                      (userError.doc_expiration_date !== "" &&
                        userError.doc_expiration_date !== "empty") ||
                      (userError.doc_expiration_date === "empty" && buttonClicked)
                    }
                    helperText={
                      userError.doc_expiration_date === "empty" ||
                      userError.doc_expiration_date === ""
                        ? ""
                        : t("INCORRECT")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Address
                    classForm={userClient}
                    formParams={setFormParams}
                    formData={formData}
                    checkField={checkField}
                    errors={userError}
                    setErrors={setFormErrors}
                    buttonClicked={buttonClicked}
                    isDisabled={path !== "create_user" && true}
                  />
                </Grid>
              </Grid>
              {!allFieldsOk(userError) && buttonClicked && (
                <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
              )}
              {path === "create_user" && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: 2 }}>
                  {isSending && <CircularProgress sx={{ color: "var(--secondary-color)" }} />}
                  <Button
                    type="submit"
                    className="oniria-btn"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
                  >
                    {t("CREATE_USER")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
