import React, { useState } from 'react'
import Header from '../../components/Header'
import { useTranslation } from 'react-i18next';
import { userClient } from '../../classes/userClass';
import UserForm from '../../components/users/UserForm';

const CreateUser = () => {
    const { t } = useTranslation();
    const breadcrumbs = [
        {
            name: t("USERS"),
            link: "/users",
        },
        {
            name: t("CREATE_USER"),
            link: `/user/create_user`,
        },
    ];
    const [formData, setFormData] = useState(userClient);

    return (
        <>
            <Header breadcrumbs={breadcrumbs} description={t("CREATE_USER_DESCRIPTION")} />
            <UserForm formData={formData} />
        </>
    );
};

export default CreateUser;
