import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { blockErrorsAllOk } from '../../../classes/tripClass';
import { focusColor } from '../../shared/textFieldStyle';
import ImageUpload from '../../shared/ImageUpload';
import { deleteBlockEvent } from '../../../services/eventsServices';

const Block = (props) => {

  const { t } = useTranslation();
  const { handleBlockChange, handleBlockImageChange} = props
  const index = props.index
  const formData = props.formController.formData.moreInformation
  const lastBlock = formData.blocks.length - 1
  const errorsBlocks = props.blocksErrors
  const setBlocksErrors = props.setBlocksErrors
  const buttonClicked = props.buttonClicked
  const { formParams, setFormParamsNotEvent, setFormErrors } = props.formController
  const [open, setOpen] = useState(false);
  const editEvent = props.editEvent

  // For edition - In case of not exisiting the block errors we create it
  if (!errorsBlocks[index]) errorsBlocks[index] = blockErrorsAllOk;

  //Validacion NOK
  const handleChangeFormParamsAndErrors = (key, event, index, regex, errorMessage) => {
    const inputValue = event.target.value;

    if (regex.test(inputValue)) {
      errorsBlocks[index][key] = "";
    } else {
      errorsBlocks[index][key] = errorMessage
    } 
    // setErrorsTextArea(errorsTextArea)
    setBlocksErrors(errorsBlocks);
    handleBlockChange(index, key, event);
    formData.blocks[index][key] = inputValue;
  };

  const handleRemoveBlock = async () => {
    //eliminarlo del back
     if (formData.blocks[index].id){
       const blockResponse = await deleteBlockEvent(formData.blocks[index].id)
    }
    props.removeBlock(index);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Box component={Paper} elevation={3}
      sx={{
        py: 2,
        px: 3,
        mb: 2,
        border: "1px solid #E4E4E4",
        borderRadius: "15px"
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
          <Typography variant='h6'>{t("BLOCK")} {index + 1}</Typography>
            <IconButton onClick={handleClickOpen}>
              <CloseIcon fontSize="small" />
            </IconButton>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              onChange={e => handleChangeFormParamsAndErrors('title', e, index, /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,50}$/, t("MAX_50"))}
              margin="normal"
              fullWidth
              value={formData.blocks[index]?.title || ""}
              id="title"
              name="title"
              autoComplete="title"
              label={t("TITLE")}
              error={(errorsBlocks[index].title !== "" && errorsBlocks[index].title !== "empty") || (errorsBlocks[index].title === "empty" && buttonClicked) }
              helperText={errorsBlocks[index].title !== 'empty' ? errorsBlocks[index].title : ''}
              sx={focusColor}
            //disabled={isDisabled()}
            />
            {/* {errorsTextArea[index].title !== "" && <Typography variant="caption" color="error">{errorsTextArea[index].title}</Typography>} */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={e => handleChangeFormParamsAndErrors('subtitle', e, index, /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,50}$/, t("MAX_50"))}
              margin="normal"
              fullWidth
              value={formData.blocks[index]?.subtitle || ""}
              id="subtitle"
              name="subtitle"
              autoComplete="subtitle"
              label={t("SUBTITLE")}
              error={errorsBlocks[index].subtitle !== ""}
              helperText={errorsBlocks[index].subtitle}
              sx={focusColor}
            //disabled={isDisabled()}
            />
            {/* {errorsTextArea.blocks[index].subtitle !== "" && <Typography variant="caption" color="error">{errorsTextArea.blocks[index].subtitle}</Typography>} */}
          </Grid>
          <Grid item xs={12} >
            <InputLabel sx={{ fontSize: "18px", color: "black", mt: 1}}>
              {t("TRIP_IMAGES")}
            </InputLabel>
              <ImageUpload 
                url={formData.blocks[index].images_urls} 
                selectedImagesInForm={formData.blocks[index].images_urls} 
                onImageSelect={(url) => handleBlockImageChange(index, 'images_urls', url)} 
                multi={true}
                editEvent={editEvent}
              />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
              {t("DESCRIPTION")}
            </InputLabel>
            <TextField
              onChange={e => handleChangeFormParamsAndErrors('description', e, index, /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{0,1000}$/s, t("MAX_1000"))}
              value={formData.blocks[index]?.description || ""}
              margin="normal"
              fullWidth
              id="description"
              name="description"
              label={t("DESCRIPTION")}
              autoComplete="description"
              multiline
              rows={4}
              error={errorsBlocks[index].description !== ""}
              helperText={errorsBlocks[index].description}
              sx={focusColor}
            // disabled={isDisabled()}
            />
            {/* {errorsTextArea.blocks[index].description !== "" && <Typography variant="caption" color="error">{errorsTextArea.blocks[index].description}</Typography>} */}
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
          {t("CANCEL_BLOCK")}
        </DialogTitle>
        <DialogContent>
        <DialogContentText sx={{ color: "black" }}>
          {t("CONFIRM_CANCEL_BLOCK")}
        </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", }}>
          <Button type="submit" className="oniria-btn-cancel" variant="contained" size="medium" sx={{ fontSize: "12px", borderRadius: 3 }} onClick={handleClose}>
            {t("CANCEL")}
          </Button>
          <Button type="submit" className="oniria-btn" variant="contained" size="medium" sx={{ fontSize: "12px", borderRadius: 3 }} onClick={handleRemoveBlock}>
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Block