import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { focusColor } from "../../../shared/textFieldStyle";
import { useTranslation } from "react-i18next";

const TypesFilter = (props) => {
  const { value, onChange, options } = props;
  const { t } = useTranslation();

  console.log("options", options);
  return (
    <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ fontSize: "12px", borderRadius: 3 }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.category_name ? option.category_name : option.name}>
            {option.name != "no-trip" ? option.name : t("ALL_TYPES")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypesFilter;
