import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileNavBar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isAffiliate = roles.find((element) => element === "affiliated");
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isAdmin = roles.find((element) => element === "admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const canPublishTrips = userInfo.trips;

  return (
    <>
      <List sx={{ display: "flex", flexDirection: "column", color: "white" }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/home");
              props.setIsMobileOpen(!props.isMobileOpen);
            }}
          >
            <ListItemText primary={t("HOME")} />
          </ListItemButton>
        </ListItem>
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              gap: "10px",
              display: "flex",
            }}
          >
            {t("MANAGER")}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isAdmin || isManager) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/organizations");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("ENTITIES")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/premises");
                  props.setIsMobileOpen(!props.isMobileOpen);
                }}
              >
                <ListItemText primary={t("VENUES")} />
              </ListItemButton>
            </ListItem>
            {(isSuperAdmin || isManager || isAdmin) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/admins");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("ADMINISTRATOR_MANAGER")} />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isAffiliate) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/users");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("USER_MANAGER")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/rrpps");
                  props.setIsMobileOpen(!props.isMobileOpen);
                }}
              >
                <ListItemText primary={t("RRPP_MANAGER")} />
              </ListItemButton>
            </ListItem>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              gap: "10px",
              display: "flex",
            }}
          >
            {t("TRANSACTIONS")}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isManager || isAdmin) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/sales");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("SALES")} />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isManager || isAdmin) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/billing");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("BILLING_PLUS")} />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isManager) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/refunds");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("REFUNDS")} />
                </ListItemButton>
              </ListItem>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              gap: "10px",
              display: "flex",
            }}
          >
            {t("EVENTS")}
          </AccordionSummary>
          <AccordionDetails>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/events");
                  props.setIsMobileOpen(!props.isMobileOpen);
                }}
              >
                <ListItemText primary={t("EVENT_MANAGER")} />
              </ListItemButton>
            </ListItem>
            {(canPublishTrips || isSuperAdmin) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/trips");
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("TRAVEL_MANAGER")} />
                </ListItemButton>
              </ListItem>
            )}
          </AccordionDetails>
        </Accordion>
      </List>
    </>
  );
};

export default MobileNavBar;
