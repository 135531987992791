export const rrppClient = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  born_at: "",
  organization_id: "",
  alias: "",
  premises_ids: [],
  avatar_url: "",
  site_verification_url: "",
};

export const rrppClientErrors = {
  name: "empty",
  surname: "empty",
  email: "",
  phone: "empty",
  born_at: "empty",
  organization_id: "",
  alias: "empty",
  premises_ids: [],
  avatar_url: "", // OPTIONAL
  site_verification_url: "", // OPTIONAL
};

export const editRrpp = {
  name: "",
  surname: "",
  phone: "",
  premises_ids: [],
};

export const editRrppErrors = {
  name: "",
  surname: "",
  phone: "",
  premises_ids: "",
};
