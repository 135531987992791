import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const DataCard = (props) => {
    const { value, label } = props;

    return (
        <Grid item component={Paper} elevation={3} xs={12} sm={5} md={2.5}
              sx={{ py: 4, px: 5, mb: 2, border: "1px solid #E4E4E4", borderRadius: "15px", minHeight: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Typography variant="h4" sx={{ mb: 2 }}>{value}</Typography>
            <Typography variant="h6" textAlign="center">{label}</Typography>
        </Grid>
    )
}

export default DataCard;