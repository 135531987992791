import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserById } from '../../services/authenticationServices';
import { useQuery } from 'react-query';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import Header from '../../components/Header';
import UserForm from '../../components/users/UserForm';
import { buyAffiliatedCard } from '../../services/usersServices';
import { toastMessageError, toastMessageSuccess } from '../../components/shared/toastMessage';
import { getCards } from '../../services/premisesServices';

const UserDetail = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const [userName, setUserName] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [imageDisable,setImageDisable] = useState(true);
  const [showCardBuyConfirmation, setShowCardBuyConfirmation] = useState(false);
  const [premise, setPremise] = useState("");
  const [cards, setCards] = useState([]);
  const [cardExists, setCardExist] = useState('');

  const handleBuyClose = () => {
    setShowCardBuyConfirmation(false);
  };

  const handleBuyAccept = async () => {

    try{
      
      const body = {
        premise_id:data.premisesResult[0].id,
        user_id: data.id,
        payment_type: "cash"
      }
      const response = await buyAffiliatedCard(body);

      setShowCardBuyConfirmation(false);
      toastMessageSuccess(t("CARD_BOUGHT"));
      navigate('/users');

    }catch(e){
      console.log(e);
      toastMessageError(e.response.data.message)
    }
    
  };

  const breadcrumbs = [
    {
      name: t("USERS"),
      link: "/users"
    },
    {
      name: userName ? userName : "",
      link: `/users/${userId}`
    }
  ];

  const { data } = useQuery('user', () => getUserById(userId), {
    onSuccess: (userData) => {
      setUserName(userData.name);
      setPremise(userData.premisesResult[0].id)
      setIsLoadingData(false);
    },
    refetchOnWindowFocus: false
  });
  //Comprobación de si la sede tiene tarjeta
  const handleCardsSuccess = (data) => {
    setCards(data)
  }
  useQuery("cards", () => getCards(), {
    onSuccess: handleCardsSuccess,
    refetchOnWindowFocus: false,
  });

  useEffect(()=> {
    if(premise && cards){
      const foundCard = cards.find(
        (card) => card.premise_id === premise
      );
      setCardExist(foundCard);
    }
  }, [premise])


  if (isLoadingData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_USER_DETAIL") + userName} />
      {
        data.affiliated_status === "pending_payment" && cardExists ?
          <Grid item xs={11} sm={12} sx={{ mb: 5, ml: 3, display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Button
              onClick={() => setShowCardBuyConfirmation(true)}
              className='oniria-btn'
              variant="contained"
              sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}>
              {t("BUY_CARD")}
            </Button>
          </Grid>
        :''
      }
      <UserForm formData={data} imageDisable={imageDisable} />
      <Dialog
          open={showCardBuyConfirmation}
          onClose={handleBuyClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle className='oniria-colorText' sx={{ fontWeight: "bolder" }}>
              {t("BUY_CARD")}
          </DialogTitle>
          <DialogContent>
              <DialogContentText sx={{ color: "black" }} >
                  {t("CONFIRM_BUY_CARD")}
              </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <Button type="submit"
                  className='oniria-btn-cancel'
                  variant="contained"
                  size='medium'
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  onClick={handleBuyClose}>
                  {t("CANCEL")}
              </Button>
              <Button type="submit"
                  className='oniria-btn'
                  variant="contained"
                  size='medium'
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  onClick={handleBuyAccept}>
                  {t("ACCEPT")}
              </Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetail;
