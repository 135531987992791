import { Grid, IconButton, ListItem, ListItemText, Paper, Tooltip } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import RemoveIcon from "@mui/icons-material/Remove";
import { Subcategories } from "./Subcategories";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import AddCategoryDialog from "./AddCategoryDialog";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

export const CategoryItem = ({
  item,
  index,
  handleOpenRemove,
  handleSetSubcategories,
  handleEditCategory,
  handleAddSubcategory,
  handleDeleteSubcategory,
  handleEditSubcategory,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openAddSubcategory, setOpenAddSubcategory] = useState(false);

  const handleOpenAddCategory = (item) => {
    setSelectedCategory(item);
    setOpenEditCategory(true);
  };

  const handleAcceptDialog = () => {
    setOpenEditCategory(false);
    handleEditCategory(selectedCategory.category_id, name);
  };

  const handleOpenAddSubcategory = (item) => {
    setSelectedCategory(item);
    setOpenAddSubcategory(true);
  };

  const handleAcceptAddSubcategory = () => {
    setOpenAddSubcategory(false);
    handleAddSubcategory(selectedCategory.category_id, name);
  };

  return (
    <>
      <Draggable draggableId={item.category_id} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            component={Paper}
            sx={{
              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Grid container>
              <ListItemText
                primary={item.name}
                sx={{ margin: 0, display: "flex", alignItems: "center" }}
              />
              <Grid>
                <Tooltip title={t("EDIT")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddCategory(item)}
                    sx={{ color: "var(--secondary-color)" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("DELETE")} placement="top">
                  <IconButton
                    onClick={() => handleOpenRemove(item.category_id)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("ADD_SUBCATEGORY")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddSubcategory(item)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {item.subcategories && (
              <Subcategories
                id={item.category_id}
                subcategories={item.subcategories}
                handleSetSubcategories={handleSetSubcategories}
                handleDeleteSubcategory={handleDeleteSubcategory}
                handleEditSubcategory={handleEditSubcategory}
              />
            )}
          </ListItem>
        )}
      </Draggable>
      <AddCategoryDialog
        name={name}
        setName={setName}
        isOpen={openEditCategory}
        onClose={() => setOpenEditCategory(false)}
        onAccept={handleAcceptDialog}
        title={t("EDIT_CATEGORY")}
        text={t("ADD_CATEGORY_NEW_NAME")}
      />
      <AddCategoryDialog
        name={name}
        setName={setName}
        isOpen={openAddSubcategory}
        onClose={() => setOpenAddSubcategory(false)}
        onAccept={handleAcceptAddSubcategory}
        title={t("ADD_SUBCATEGORY")}
        text={t("ADD_SUBCATEGORY_NEW_NAME")}
      />
    </>
  );
};
