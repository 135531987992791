import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPremiseById } from '../../services/premisesServices';
import { getCards } from '../../services/premisesServices';
import Header from '../../components/Header'
import EditMemberCard from '../../components/premises/membersCard.jsx/EditMemberCard';
import CreateMemberCard from '../../components/premises/membersCard.jsx/CreateMemberCard';
import { Box, CircularProgress } from '@mui/material';

const MemberCard = () => {

  const { t } = useTranslation();
  const { premiseId } = useParams();
  const [premiseName, setPremiseName] = useState("");
  const [allMemberscards, setAllMembersCard] = useState([])
  const [cardExist, setCardExist] = useState("");

  const breadcrumbs = [{
    name: t("VENUES"),
    link: "/premises"
  },
  {
    name: premiseName ? premiseName : "",
    link: `/premise/${premiseId}`
  },
  {
    name: t("MEMBER_CARD"),
    link: `/premise/${premiseId}/member_card`
  }];

  const handleQueryPremiseSuccess = (data) => {
    setPremiseName(data[0].name);
  };

  const handleQueryCardSuccess = (data) => {
    setAllMembersCard(data);
  };

  const { isLoading } = useQuery("premise", () => getPremiseById(premiseId), {
    onSuccess: handleQueryPremiseSuccess,
    refetchOnWindowFocus: false,
  });
  const cardQuery = useQuery("cards", () => getCards(), {
    onSuccess: handleQueryCardSuccess,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (cardQuery.isSuccess) {
      const foundCard = allMemberscards.find(
        (card) => card.premise_id === premiseId
      );
      setCardExist(foundCard);
    }
  }, [cardQuery.isSuccess, allMemberscards, premiseId]);

  const isCardExist = (cardExist) => {
    return cardExist ? <EditMemberCard card={cardExist} /> : <CreateMemberCard />
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      {isCardExist(cardExist)}
    </>
  )
}

export default MemberCard