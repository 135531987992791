import { useState } from "react";
import { useQuery } from "react-query"
import { getDownloadPdf, getDownloadSepa, getSepasPdf, putPdfRegenerate } from "../../services/billingServices";
import { Box, Button, ButtonGroup, CircularProgress, FormControl, Grid, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ExpandLess, ExpandMore, FileDownload } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import { focusColor } from "../shared/textFieldStyle";
import { useTranslation } from "react-i18next";
import { handleDownloadPdf } from "./functions/fileDownloads";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import CustomDialog from "../shared/CustomDialog";

let pdfAmount;
let perPage = 10;

const ListaPdfPlus = () => {
    const [pdfList, setPdfList] = useState([]);
    const { t } = useTranslation();
    const [date, setDate] = useState({ from: '', to: '' });
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState({ asc: [], desc: ["from_date"] });
    const [isOpenRegenerate, setOpenRegenerate] = useState(false);
    const [selectedBill, setSelectedBill] = useState('');

    //Get de lista pdf
    const handlePdfOk = (data) => {
        pdfAmount = data.amount
        setPdfList(data.data)
    }
    const { isLoading, isRefetching, refetch } = useQuery(
        ['pdfList', date, page, order], 
        () => getSepasPdf(date, page, perPage, order),
        {
        onSuccess: handlePdfOk,
        refetchOnWindowFocus: false,
        }
    )
    //marcar pdf descargado
    const handlePdfDownloaded = (id) => {
        getDownloadPdf(id)
        .then(data => {
            refetch();
        })
        .catch(error => console.log(error))
        
    }
    //descargar pdf
    const downloadPdf = (id) => {
        //
        const pdfEntry = pdfList.find(item => item.id === id);
        const fileName = "pdf_for_manager-"+(new Date(pdfEntry.from_date).toLocaleDateString())+"-"+(new Date(pdfEntry.to_date).toLocaleDateString());
        //
        getDownloadPdf(id)
        .then(data => {
            handleDownloadPdf(data, fileName)
            toastMessageSuccess(t("DOWNLOAD_START"))
            refetch();
        })
        .catch(error => toastMessageError(error.response.data.error ?? 'Error'))
    }

    //manejo de filtro fecha
    const handleChangeDate = (field, value) => {
        setPage(1)
        setDate(prevDate => {
            if (field === 'from' && prevDate.to && new Date(value) > new Date(prevDate.to)) {
                return {
                    ...prevDate,
                    [field]: value,
                    to: value
                };
            } else {
                return {
                    ...prevDate,
                    [field]: value
                };
            }
        });
    };
    //filtros pdf
    const handleSortBills = (id, orderType) => {
        setPage(1)
        setOrder((prevOrder) => {
            const oppositeOrderType = orderType === 'asc' ? 'desc' : 'asc';
            return {
                ...prevOrder,
                [orderType]: prevOrder[orderType].includes(id) ? prevOrder[orderType].filter(columnId => columnId !== id) : [...prevOrder[orderType], id],
                [oppositeOrderType]: prevOrder[oppositeOrderType].filter(columnId => columnId !== id),
            };
        });
    };

     //BUTTONS TO SORT THE PDF
     const renderArrowButtons = (id) => {
        return (
            <ButtonGroup sx={{ fontSize: "5px" }}>
                <IconButton aria-label='sort' size="small" name={'asc'} onClick={() => handleSortBills(id, 'asc')} className={(order['asc'].includes(id)) ? 'oniria-btn-check-validated' : 'oniria-btn-check'} sx={{ height: "20px", width: "20px" }}><ExpandLess /></IconButton>
                <IconButton aria-label='sort' size="small" name={'desc'} onClick={() => handleSortBills(id, 'desc')} className={(order['desc'].includes(id)) ? 'oniria-btn-check-validated' : 'oniria-btn-check'} sx={{ height: "20px", width: "20px" }}><ExpandMore /></IconButton>
            </ButtonGroup>
        )
    }

    //regenerate pdf
    const handleOpenRegenerate = (id) => {
        setSelectedBill(id)
        setOpenRegenerate(true);
    }

    const handleRegenerateBill = async  () => {
        try {
            await putPdfRegenerate(selectedBill)
            toastMessageSuccess('Factura regenerada correctamente')
            await refetch()
            setOpenRegenerate(false)
        } catch(e){
            toastMessageError(e.response.data.error ?? 'Error')
        }
    }
    const handleCloseRegenerate = () => {
        setOpenRegenerate(false);
    }

    //Change page
    const handleChangePage = (e, value) => {
        setPage(value);
    };
    const numberPages = Math.ceil(pdfAmount / perPage);
    const today = new Date().toISOString().split('T')[0];

    return (
        <>
            <Grid container component={Paper} sx={{padding: 2, marginBottom: 2}}>
                <Typography variant="h6">{t("LIST_PDF_PLUS")}</Typography>
                {/* DATE FILTER */}
                <FormControl sx={[focusColor, { gap: 2 }]} fullWidth >
                    <Grid container alignItems="center" justifyContent="space-between" rowGap={2} >
                        <Grid item container alignItems="center" justifyContent="space-between" xs={12} md={5}>
                            <Typography>{t("FROM")}</Typography>
                            <TextField
                                type="date"
                                value={date.from}
                                onChange={(e) => handleChangeDate('from', e.target.value)}
                                sx={[focusColor, {}]}
                                inputProps={{ max: today }}
                            />
                        </Grid>
                        <Grid item container alignItems="center" justifyContent="space-between" xs={12} md={5}>
                            <Typography>{t("UNTIL")}</Typography>
                            <TextField
                                type="date"
                                value={date.to}
                                onChange={(e) => handleChangeDate('to', e.target.value)}
                                sx={[focusColor, {}]}
                                inputProps={{ min: date.from, max: today }}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>

            {isLoading || isRefetching ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
                    <CircularProgress sx={{ color: "var(--secondary-color)" }}/>
                </Grid>
            ) : (
                pdfList.length > 0 ? (
                    <>
                        {/* PAGINATION */}
                        <Grid item xs={12} container justifyContent={{ xs: 'center', sm: 'space-between' }}  alignItems="center" rowGap={2} paddingY={2}>
                            <Typography>{pdfAmount} {t("LAST_MOVEMENTS")}</Typography>
                            {numberPages > 1 &&(
                                <Pagination count={numberPages} page={page} onChange={handleChangePage} 
                                sx={{
                                    display: "flex", justifyContent: "center", fontWeight: "bolder",
                                    "& .MuiPaginationItem-root": { "&.Mui-selected": { background: "#DC4451", color: "#fff", fontWeight: "bolder" },
                                    fontWeight: "600", }
                                }}/>
                            )}
                        </Grid>
                        <TableContainer sx={{marginBottom: 20}}>
                            <Table>
                                <TableHead>
                                <TableRow sx={{ borderBottom: '2px solid var(--secondary-color)' }}>
                                <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            Nº Factura
                                            {/* {renderArrowButtons('invoice_number')} */}
                                        </Grid>
                                    </TableCell>
                                    {/* <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            ID
                                            {renderArrowButtons('id')}
                                        </Grid>
                                    </TableCell> */}
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            {t("START_DATE")}
                                            {renderArrowButtons('from_date')}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            {t("END_DATE")}
                                            {renderArrowButtons('to_date')}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            {t("AMOUNT")}
                                            {renderArrowButtons('total_amount')}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            {t("COMMISSION")}
                                            {renderArrowButtons('total_commission')}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            {t("DOWNLOAD_BILLS")}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexDirection="row" wrap='nowrap' alignItems="center" columnGap={1}>
                                            Regenerar factura
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pdfList?.map((bill, index) => (
                                        <TableRow key={index} >
                                            <TableCell>{bill.invoice_serie}-{bill.invoice_number}</TableCell>
                                            {/* <TableCell>{bill.id}</TableCell> */}
                                            <TableCell>{bill.from_date ? new Date(bill.from_date).toLocaleDateString() : ''}</TableCell>
                                            <TableCell>{bill.from_date ? new Date(bill.to_date).toLocaleDateString() : ''}</TableCell>
                                            <TableCell>{bill.total_amount} €</TableCell>
                                            <TableCell>{bill.total_commission} €</TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <Button onClick={() => downloadPdf(bill.id)}>
                                                        <FileDownload />
                                                    </Button>
                                                    {bill.downloaded_at && <CheckIcon color='success'/>}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Button className="oniria-btn" sx={{color: "#fff"}} size="small" onClick={() => handleOpenRegenerate(bill.id)}>
                                                    {t("REGENERATE")}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {numberPages > 1 &&
                        <Grid item xs={12} sx={{mt: 10}}>
                            <Pagination count={numberPages}
                                sx={{
                                    mb: 30, display: "flex", justifyContent: "center", fontWeight: "bolder",
                                    "& .MuiPaginationItem-root": { "&.Mui-selected": { background: "#DC4451", color: "#fff", fontWeight: "bolder" }, fontWeight: "600" }
                                }}
                                page={page} onChange={handleChangePage} />
                        </Grid>
                        }
                    </>
                ) : (
                    <div>{t("EMPTY_PDF")}</div>
                )
            )}
            <CustomDialog
                isOpen={isOpenRegenerate}
                onClose={handleCloseRegenerate} 
                title={t("REGENERATE_BILL")} 
                content={t("REGENERATE_BILL_TEXT")}
                onAccept={handleRegenerateBill}
            />
        </>
    )
}

export default ListaPdfPlus;