import React, { useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
//import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { getAssistantDetailNoEvent } from '../../services/eventsServices';
import { useQuery } from 'react-query';

const AssistantDetail = (props) => {
    const { t } = useTranslation();
    const ticketId = props.ticketId
    const [openCheck, setOpenCheck] = useState(false);
    // const [openDelete, setOpenDelete] = useState(false);
    const [assistantDetail, setAssistantDetail] = useState([])

    const handleQuerySuccess = (data) => {
        setAssistantDetail(data)
    }
    const { isLoading } = useQuery('assistant', () => getAssistantDetailNoEvent(ticketId), {
        onSuccess: handleQuerySuccess
    })

    const formatDate = (isoDate) => {
        if (!isoDate) return '';
        const date = new Date(isoDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const handleClickOpenCheck = (e) => {
        e.stopPropagation();
        setOpenCheck(true);
    };

    const handleCloseCheck = (e) => {
        e.stopPropagation();
        setOpenCheck(false);
    };

    const handleRegisterButtonClick = async (e) => {
        e.stopPropagation();
        //await postRegisterAssistant(id);
        setOpenCheck(false)
        //props.refetch()
    }
    //ToDo

    // const handleClickOpenDelete = (e) => {
    //     e.stopPropagation();
    //     setOpenDelete(true);
    // };

    // const handleCloseDelete = (e) => {
    //     e.stopPropagation();
    //     setOpenDelete(false);
    // };

    // const handleDeleteButtonClick = async (e) => {
    //     e.stopPropagation();
    //     //await deleteAssistant(id);
    //     setOpenDelete(false)
    //     //props.refetch()
    // }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress sx={{ color: "var(--secondary-color)" }} />
            </Box>
        );
    }
    return (
        <>
            <Grid container sx={{ display: "flex", flexDirection: "row", mb: 3, mt: 2 }}>
                <Link className='oniria-colorText' underline="none" onClick={props.onBack} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <ChevronLeftIcon style={{ marginRight: '4px' }} />
                    <span style={{ verticalAlign: 'middle' }}>{t("LIST_ATTENDEES")}</span>
                </Link>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "row-reverse" }}>
                {/* <IconButton aria-label="delete" className='oniria-btn' onClick={handleClickOpenDelete} >
                    <DeleteIcon sx={{ color: "white" }} />
                </IconButton>
                <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className='oniria-colorText' sx={{ fontWeight: "bolder" }}>
                        {t("DELETE_ATTENDANT")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color: "black" }} >
                            {t("CONFIRM_DELETE_ATTENDANT")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                        <Button type="submit"
                            className='oniria-btn-cancel'
                            variant="contained"
                            size='medium'
                            sx={{ fontSize: "12px", borderRadius: 3 }}
                            onClick={handleCloseDelete}>
                            {t("CANCEL")}
                        </Button>
                        <Button type="submit"
                            className='oniria-btn'
                            variant="contained"
                            size='medium'
                            sx={{ fontSize: "12px", borderRadius: 3 }}
                            onClick={handleDeleteButtonClick}>
                            {t("ACCEPT")}
                        </Button>
                    </DialogActions>
                </Dialog> */}
                {/* <IconButton aria-label="check" className='oniria-btn-check' sx={{ mr: 2 }} onClick={handleClickOpenCheck}>
                    <CheckIcon />
                </IconButton>
                <Dialog
                    open={openCheck}
                    onClose={handleCloseCheck}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className='oniria-colorText' sx={{ fontWeight: "bolder" }}>
                        {t("REGISTER_ATTENDANT")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color: "black" }} >
                            {t("CONFIRM_REGISTER_ATTENDANT")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                        <Button type="submit"
                            className='oniria-btn-cancel'
                            variant="contained"
                            size='medium'
                            sx={{ fontSize: "12px", borderRadius: 3 }}
                            onClick={handleCloseCheck}>
                            {t("CANCEL")}
                        </Button>
                        <Button type="submit"
                            className='oniria-btn'
                            variant="contained"
                            size='medium'
                            sx={{ fontSize: "12px", borderRadius: 3 }}
                            onClick={handleRegisterButtonClick}>
                            {t("ACCEPT")}
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </Grid>
            {assistantDetail.map(assistant => (
                <Grid container spacing={2} >
                    <Grid item xs={12} mt={2}>
                        <Grid container>
                            <Typography variant="h6">{t("ATTENDEE_DATA")}:</Typography>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary={t("NAME_SURNAME")} secondary={(assistant.client_name ?? '') + " " + (assistant.client_surname ?? '')} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary={t("DATE_BIRTH")} secondary={formatDate(assistant.client_birth_date)} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary={t("NATIONALITY")} secondary={assistant.client_nationality} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary={t("GENRE")}
                                                secondary={assistant.client_gender === "woman" ? (t("WOMAN")) :
                                                    assistant.client_gender === "man" ? (t("MAN")) :
                                                        assistant.client_gender === "other" ? (t("OTHER")) : ""} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary="Tipo de Documento" secondary={assistant.client_document_type} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <ListItem>
                                            <ListItemText primary={t("EXPIRY_DATE")} secondary={formatDate(assistant.client_document_expiry_date)} />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Typography variant="h6">{t("BUYER_DETAILS")}:</Typography>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={4} lg={4}>
                                        <ListItem >
                                            <ListItemText primary={t("NAME_SURNAME")} secondary={assistant.buyer_name + " " + assistant.buyer_surname} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={4}>
                                        <ListItem >
                                            <ListItemText primary={t("EMAIL")} secondary={assistant.buyer_email} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={4}>
                                        <ListItem >
                                            <ListItemText primary={t("PHONE_NUMBER")} secondary={assistant.buyer_phone_code + " " + assistant.buyer_phone} />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} mb={25}>
                        <Grid container>
                            <Typography variant="h6">{t("TICKET_DATA")}:</Typography>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={4} lg={3}>
                                        <ListItem>
                                            <ListItemText primary={t("TICKET_NAME")} secondary={assistant.ticket_name} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={3}>
                                        <ListItem>
                                            <ListItemText primary={t("TICKET_PRICE")} secondary={assistant.ticket_price + "€"} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={3}>
                                        <ListItem>
                                            <ListItemText primary={t("EXTRA")} secondary={assistant.extras.map(extra => (
                                                <React.Fragment key={extra.extra_id}>
                                                    {extra.extra_name}
                                                    <br />
                                                </React.Fragment>
                                            ))} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={3}>
                                        <ListItem>
                                            <ListItemText primary={t("EXTRAS_PRICE")} secondary={assistant.extras.map(extra => (
                                                <React.Fragment key={extra.extra_id}>
                                                    {extra.extra_price + "€"}
                                                    <br />
                                                </React.Fragment>
                                            ))} />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            ))}
        </>
    )
}

export default AssistantDetail