//const BASE_API_URL = "https://kwelxy60tj.execute-api.eu-west-1.amazonaws.com/test";
const BASE_API_URL = "https://kwelxy60tj.execute-api.eu-west-1.amazonaws.com/prod";

const APP_URL = "/v1";

export const API_URL = `${BASE_API_URL}`;

export const SERVICE = {
  BASE: APP_URL,
  LOGIN: `${APP_URL}/users/login`,
  CREATE_ACCOUNT: `${APP_URL}/users/sign-up-dashboard`,
  USER: `${APP_URL}/users/`,
  ORGANIZATIONS: `${APP_URL}/organizations/`,
  PREMISES: `${APP_URL}/premises/`,
  EVENTS: `${APP_URL}/events/`,
  PARTYS: `${APP_URL}/events?type=event`,
  ACTIVITIES: `${APP_URL}/events?type=activity`,
  TRIPS: `${APP_URL}/events?type=trip`,
  IMAGE: `${APP_URL}/images`,
  TICKETS: `${APP_URL}/tickets/`,
  GROUP_DISCOUNTS: `${APP_URL}/tickets/group-discount/`,
  TICKETS_AND_EXTRAS: `${APP_URL}/tickets/event/ticket-and-extras/`,
  EXTRAS: `${APP_URL}/extras/`,
  ASSISTANTS: `${APP_URL}/tickets/sold/`,
  ASSISTANT_DETAIL: `${APP_URL}/orders/order-ticket/`,
  ORDERS_EVENT: `${APP_URL}/orders/event/`,
  ORDERS: `${APP_URL}/orders/`,
  ORDERS_CARD: `${APP_URL}/orders/card/`,
  CONSUME_TICKET: `${APP_URL}/orders/consume-ticket/`,
  CONSUME_EXTRA: `${APP_URL}/orders/consume-extra/`,
  CARDS: `${APP_URL}/cards/`,
  PENDING_USERS: `${APP_URL}/users/pending-users/`,
  ADMINS: `${APP_URL}/users/users-dashboard`,
  //CREATE_USER:`${APP_URL}/users/sign-up-app`,
  CREATE_USER: `${APP_URL}/users/add-user`,
  EDIT_USER: `${APP_URL}/users/`,
  USERS_AFFILIATE: `${APP_URL}/users/users-affiliate/`,
  VALIDATE_USER: `${APP_URL}/cards/validate-card/`,
  REJECT_USER: `${APP_URL}/cards/reject-card/`,
  USERS_PREMISE: `${APP_URL}/users/premise/`,
  CNAE: `${APP_URL}/utils/cnaes`,
  COUNTRIES: `${APP_URL}/utils/countries`,
  REGIONS: `${APP_URL}/utils/regions/`,
  HOME: `${APP_URL}/utils/home`,
  ORGANIZATIONS_VALIDATION: `${APP_URL}/users/pending-uops/`,
  ORGANIZATION_VALID: `${APP_URL}/users/validate-uop/`,
  PREMISES_VALIDATION: `${APP_URL}/premises/pending/`,
  PREMISE_VALID: `${APP_URL}/premises/validate-premise/`,
  CREATE_ADMIN: `${APP_URL}/users/add-user`,
  CITIES: `${APP_URL}/utils/cities-of-premises`,
  BILLING: `${APP_URL}/billing`,
  PAYMENTS: `${APP_URL}/payments`,
  ALLEVENTS: `${APP_URL}/events`,
  STATISTICS: `${APP_URL}/utils/statistics`,
  SEPAS: `${APP_URL}/sepas`,
  REFUNDS: `${APP_URL}/refunds`,
  PREMISES_LIST: `${APP_URL}/premises/list`,
  CATEGORIES: `${APP_URL}/categories`,

  RRPP: `${APP_URL}/users/rrpp`,
};
