import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePremise } from "../../services/premisesServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const CardPremise = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const isAffiliated = roles.find((element) => element === "affiliated");
  const isDelegate = roles.find((element) => element === "delegate");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const id = props.premise.premise_id;

  const handleEventClick = (id) => {
    navigate(`/premise/${id}`);
  };
  const handlePendingUsers = (id) => {
    navigate(`/premise/${id}/pending_users`);
  };
  const handleMemberCard = (id) => {
    navigate(`/premise/${id}/member_card`);
  };
  const handleDelegates = (id) => {
    navigate(`/premise/${id}/delegates`);
  };

  const handleShowCategories = () => {
    navigate(`/premise/${id}/categories`);
  };
  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const handleDeleteButtonClick = async (e) => {
    e.stopPropagation();
    try {
      await deletePremise(id);
      setOpen(false);
      toastMessageSuccess(t("PREMISE_DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error.response.data.error);
    }
    props.refetch();
  };
  /* popover */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePop = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditButtonClick = (e, id) => {
    e.stopPropagation();
    navigate(`/premise/${id}?edit=true`);
  };

  const openPopover = Boolean(anchorEl);
  const popperId = openPopover ? "simple-popover" : undefined;
  return (
    <Grid item xs={11} sm={12}>
      <Card sx={{ border: "1px solid #E4E4E4", borderRadius: "15px" }}>
        <Box>
          <CardContent sx={{ p: 3 }}>
            <Grid item container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle1"
                  sx={{ fontWeight: "bolder" }}
                >
                  {props.premise.premise_name}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={2}>
                  {t("ORGANIZATION")}: {props.premise.organization_name}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {props.premise.address}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  {props.premise.postal_code}, {props.premise.city}, {props.premise.country}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="subtitle2" mb={2}>
                  {t("AFFILIATE")}: {props.premise.is_international ? "Si" : "No"}
                </Typography>
                <Typography variant="subtitle2" mb={2}>
                  {t("NUMBER_AFFILIATE")}: {props.premise.number_of_affiliates}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  CIF: {props.premise.cif}
                </Typography>
                <Typography variant="subtitle2" mb={1}>
                  IBAN: **** **** **** {props.premise.iban}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={1.8}>
                {/*  <Typography variant="subtitle2" mb={1}>
                                        {t("TOTAL_SALES")}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bolder" }} mb={1}>
                                        100000€
                                    </Typography> */}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={4.2}
                container
                alignItems="center"
                columnGap={0.7}
                justifyContent={{ md: "flex-end" }}
              >
                {isDelegate !== "delegate" && (
                  <Button
                    sx={{ borderRadius: 3 }}
                    aria-label="edit"
                    className="oniria-btn"
                    onClick={(e) => handleEditButtonClick(e, props.premise.premise_id)}
                  >
                    <EditIcon sx={{ color: "white" }} />
                  </Button>
                )}
                <Button
                  sx={{ borderRadius: 3 }}
                  aria-label="visibility"
                  className="oniria-btn"
                  onClick={() => handleEventClick(props.premise.premise_id)}
                >
                  <VisibilityIcon sx={{ color: "white" }} />
                </Button>
                {(isSuperAdmin || isManager || isAffiliated) && (
                  <Button
                    sx={{ borderRadius: 3 }}
                    className="oniria-btn"
                    aria-label="statistics"
                    onClick={() => handlePendingUsers(props.premise.premise_id)}
                  >
                    <GroupAddIcon sx={{ color: "white" }} />
                  </Button>
                )}
                <Button
                  sx={{ borderRadius: 3 }}
                  aria-label="show more"
                  className="oniria-btn"
                  onClick={handleClick}
                  aria-describedby={popperId}
                >
                  <MoreHorizIcon sx={{ color: "white" }} fontSize="medium" />
                </Button>
                <Popover
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePop}
                  id={popperId}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ButtonGroup
                    variant="contained"
                    orientation="vertical"
                    aria-label="vertical button group"
                  >
                    {/* <Button className="popover-btn" onClick={() => handleDelegates(props.premise.premise_id)}>
                                            {t("DELEGATES")}
                                        </Button> */}
                    {(isSuperAdmin || isManager || isAffiliated) && (
                      <Button
                        className="popover-btn"
                        onClick={() => handleMemberCard(props.premise.premise_id)}
                      >
                        {t("MEMBER_CARD")}
                      </Button>
                    )}
                    {/* <Button className="popover-btn">
                                            {t("STATISTICS")}
                                        </Button> */}
                    <Button className="popover-btn" onClick={handleShowCategories}>
                      {t("EVENT_CATEGORIES")}
                    </Button>
                    {(isSuperAdmin || isManager) && (
                      <Button className="popover-btn" onClick={handleClickOpen}>
                        {t("REMOVE")}
                      </Button>
                    )}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
                        {t("CANCEL_PREMISE")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ color: "black" }}>
                          {t("CONFIRM_CANCEL_PREMISE")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          type="submit"
                          className="oniria-btn-cancel"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleClose}
                        >
                          {t("CANCEL")}
                        </Button>
                        <Button
                          type="submit"
                          className="oniria-btn"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleDeleteButtonClick}
                        >
                          {t("ACCEPT")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ButtonGroup>
                </Popover>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default CardPremise;
