
export const optionsValue = [
    { value: '1', name: 'Autobus', img: 'https://img.icons8.com/small/20/bus.png' },
    { value: '2', name: 'Barco', img: 'https://img.icons8.com/small/20/water-transportation.png' },
    { value: '3', name: 'Coche', img: 'https://img.icons8.com/small/20/car.png' },
    { value: '4', name: 'Tren', img: 'https://img.icons8.com/small/20/subway.png' },
    { value: '5', name: 'Alojamiento', img: 'https://img.icons8.com/small/20/bedroom.png' },
    { value: '6', name: 'Camping', img: 'https://img.icons8.com/small/20/camping-tent.png' },
    { value: '7', name: 'Casa', img: 'https://img.icons8.com/small/20/exterior.png' },
    { value: '8', name: 'Hotel', img: 'https://img.icons8.com/small/20/booking.png' },
    { value: '11', name: 'Aperitivos', img: 'https://img.icons8.com/small/20/cookie.png' },
    { value: '12', name: 'Barbacoa', img: 'https://img.icons8.com/small/20/weber.png' },
    { value: '13', name: 'Bocadillo', img: 'https://img.icons8.com/small/20/bread.png' },
    { value: '14', name: 'Café', img: 'https://img.icons8.com/small/20/cafe.png' },
    { value: '15', name: 'Carne', img: 'https://img.icons8.com/small/20/steak-rare.png' },
    { value: '16', name: 'Cena', img: 'https://img.icons8.com/small/20/meal.png' },
    { value: '17', name: 'Chupitos', img: 'https://img.icons8.com/small/20/vodka-shot.png' },
    { value: '18', name: 'Comida', img: 'https://img.icons8.com/small/20/food.png' },
    { value: '19', name: 'Desayuno', img: 'https://img.icons8.com/small/20/toaster.png' },
    { value: '20', name: 'Helado', img: 'https://img.icons8.com/small/20/ice-cream-cone.png' },
    { value: '21', name: 'Paella', img: 'https://img.icons8.com/small/20/merry-pie.png' },
    { value: '22', name: 'Pescado', img: 'https://img.icons8.com/small/20/fish-food.png' },
    { value: '23', name: 'Pizza', img: 'https://img.icons8.com/small/20/pizza.png' },
    { value: '24', name: 'Postre', img: 'https://img.icons8.com/small/20/confectionery.png' },
    { value: '25', name: 'Restaurante', img: 'https://img.icons8.com/small/20/restaurant.png' },
    { value: '26', name: 'Tapas', img: 'https://img.icons8.com/small/20/tapas.png' },
    { value: '27', name: 'Tarta', img: 'https://img.icons8.com/small/20/cheesecake.png' },
    { value: '28', name: 'Alcohol', img: 'https://img.icons8.com/small/20/wine-bottle.png' },
    { value: '29', name: 'Bebida', img: 'https://img.icons8.com/small/20/soda-cup.png' },
    { value: '30', name: 'Cerveza', img: 'https://img.icons8.com/small/20/beer.png' },
    { value: '31', name: 'Cocktail', img: 'https://img.icons8.com/small/20/cocktail.png' },
    { value: '32', name: 'Refresco', img: 'https://img.icons8.com/small/20/soda-bottle.png' },
    { value: '33', name: 'Sangria', img: 'https://img.icons8.com/small/20/orange-juice.png' },
    { value: '34', name: 'Vino', img: 'https://img.icons8.com/small/20/wine-glass.png' },
    { value: '35', name: 'Castillo', img: 'https://img.icons8.com/small/20/palace.png' },
    { value: '36', name: 'Catedral', img: 'https://img.icons8.com/small/20/cathedral.png' },
    { value: '37', name: 'Concierto', img: 'https://img.icons8.com/small/20/park-concert-shell.png' },
    { value: '38', name: 'Cueva', img: 'https://img.icons8.com/small/20/cave.png' },
    { value: '39', name: 'Desierto', img: 'https://img.icons8.com/small/20/dust.png' },
    { value: '39', name: 'Discoteca', img: 'https://img.icons8.com/small/20/dancing-party.png' },
    { value: '40', name: 'DJ', img: 'https://img.icons8.com/small/20/hang-10.png' },
    { value: '41', name: 'Duna', img: 'https://img.icons8.com/small/20/waypoint-map.png' },
    { value: '42', name: 'Excursion', img: 'https://img.icons8.com/small/20/signpost.png' },
    { value: '43', name: 'Fiesta', img: 'https://img.icons8.com/small/20/confetti.png' },
    { value: '44', name: 'Gruta', img: 'https://img.icons8.com/small/20/stones.png' },
    { value: '45', name: 'Happy Erasmus Staff', img: 'https://img.icons8.com/small/20/employee-card.png' },
    { value: '46', name: 'Iglesia', img: 'https://img.icons8.com/small/20/city-church.png' },
    { value: '47', name: 'Isla', img: 'https://img.icons8.com/small/20/--pier.png' },
    { value: '48', name: 'Lago', img: 'https://img.icons8.com/small/20/sea-waves.png' },
    { value: '49', name: 'Montaña', img: 'https://img.icons8.com/small/20/alps.png' },
    { value: '50', name: 'Museo', img: 'https://img.icons8.com/small/20/university.png' },
    { value: '51', name: 'Palacio', img: 'https://img.icons8.com/small/20/basilica.png' },
    { value: '52', name: 'Pelicula', img: 'https://img.icons8.com/small/20/movie.png' },
    { value: '53', name: 'Piscina', img: 'https://img.icons8.com/small/20/outdoor-swimming-pool.png' },
    { value: '54', name: 'Playa', img: 'https://img.icons8.com/small/20/beach-umbrella.png' },
    { value: '55', name: 'Rio', img: 'https://img.icons8.com/small/20/creek.png' },
    { value: '56', name: 'Ticket', img: 'https://img.icons8.com/small/20/pnr-code.png' },
    { value: '57', name: 'Torre', img: 'https://img.icons8.com/small/20/monument.png' },
    { value: '58', name: 'Visita guiada', img: 'https://img.icons8.com/small/20/visit.png' },
    { value: '59', name: 'Surf', img: 'https://img.icons8.com/fluency-systems-regular/20/surf.png' },
    { value: '60', name: 'Tiempo libre', img: 'https://img.icons8.com/small/20/sunbathe.png' },
    { value: '61', name: 'Natación', img: 'https://img.icons8.com/small/20/swimming.png' },
    { value: '62', name: 'Baloncesto', img: 'https://img.icons8.com/small/20/basketball.png' },
    { value: '63', name: 'Deporte', img: 'https://img.icons8.com/small/20/dumbbell.png' },
    { value: '64', name: 'Guarda equipaje', img: 'https://img.icons8.com/small/20/suitcase.png' },
    { value: '65', name: 'Senderismo', img: 'https://img.icons8.com/small/20/trekking.png' },
    { value: '66', name: 'Bucear', img: 'https://img.icons8.com/small/20/scuba-diving.png' },
    { value: '67', name: 'Bicicleta', img: 'https://img.icons8.com/small/20/cycling-road.png' },
    { value: '68', name: 'Traje de buzo', img: 'https://img.icons8.com/small/20/flippers.png' },
    { value: '69', name: 'Camiseta', img: 'https://img.icons8.com/small/20/t-shirt.png' },
    { value: '70', name: 'Voleibol', img: 'https://img.icons8.com/small/20/volleyball.png' },
    { value: '71', name: 'Futbol', img: 'https://img.icons8.com/small/20/football2.png' },
    { value: '72', name: 'Equipo de esquiar', img: 'https://img.icons8.com/small/20/skiing.png' },
    { value: '73', name: 'Patines de hielo', img: 'https://img.icons8.com/small/20/ice-skate.png' },
    { value: '74', name: 'Patines', img: 'https://img.icons8.com/small/20/rollerblade.png' },
    { value: '75', name: 'Montar camello', img: 'https://img.icons8.com/wired/20/--camel.png' },
    { value: '76', name: 'Karaoke', img: 'https://img.icons8.com/small/20/micro.png' },
    { value: '77', name: 'Flamenco', img: 'https://img.icons8.com/small/20/fan-2.png' },
    { value: '78', name: 'Monasterio', img: 'https://img.icons8.com/small/20/monastery.png' },
    { value: '79', name: 'Teatro', img: 'https://img.icons8.com/small/20/comedy--v3.png' },
    { value: '80', name: 'Ducha', img: 'https://img.icons8.com/small/20/shower.png' }
]