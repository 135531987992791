export function formatDate(date) {
  const dateObj = new Date(date);
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const year = dateObj.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function formatHours(date) {
  const dateObj = new Date(date);
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}

export function formatMuiDate(date) {
  if (!date) return "";
  var fecha = new Date(date);
  var año = fecha.getFullYear();
  var mes = fecha.getMonth() + 1;
  var dia = fecha.getDate();

  mes = mes < 10 ? "0" + mes : mes;
  dia = dia < 10 ? "0" + dia : dia;
  return `${año}-${mes}-${dia}`;
}

export function compareDates(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getTime() === d2.getTime();
}

export function getAgeByBirth(date) {
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if ( monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate()) ) {
    age--;
  }
  return age;
}

export const EURO = new Intl.NumberFormat("es", {
  style: "currency",
  currency: "EUR",
});

//check if a erasmus card is expired
export const isValidCardDate = (date) => {
  if (date === null) return true;
  const today = new Date();
  const cardDate = new Date(date);
  return today < cardDate;
};

