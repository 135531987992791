import { Visibility } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteEvent } from "../../services/eventsServices";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const CardEvents = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const event = props.event;
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const isAdmin = roles.find((element) => element === "admin");
  const [openDelete, setOpenDelete] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");

  const formatDate = (date) => {
    const dateObj = new Date(date);
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const year = dateObj.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatHours = (date) => {
    const dateObj = new Date(date);
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  };
  const soldTicketsPercent = (soldTickets, totalStock) => {
    let percentage = (soldTickets * 100) / totalStock;
    return parseFloat(percentage.toFixed(2));
  };

  const handleEventClick = (id) => {
    navigate(`/event/${id}`);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const handleCopyToClipboard = () => {
    const url = "www.example.com";
    navigator.clipboard.writeText(url);
  };

  const handleDuplicateEvent = () => {
    if (event.event_type == "trip") {
      navigate(`/create_trip/${props.event.id}`);
    } else {
      navigate(`/create_event/${props.event.id}`);
    }
  };

  const handleDeleteButtonClick = async (e) => {
    e.stopPropagation();
    try {
      await deleteEvent(props.event.id);
      toastMessageSuccess(t("EVENT_DELETED"));
    } catch (error) {
      toastMessageError(error.response.data.message);
    }
    setOpenDelete(false);
    props.refetch();
  };

  /* popover */
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePop = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popperId = openPopover ? "simple-popover" : undefined;
  return (
    <Grid item xs={11} sm={12}>
      <Card
        sx={{
          border: event.is_active === 0 ? "1px solid #d5d3d3" : "1px solid #E4E4E4",
          borderRadius: "15px",
          position: "relative",
          backgroundColor: event.is_active === 0 ? "var(--grey-cancelled)" : "inherit",
        }}
      >
        {event.is_active === 0 && (
          <Typography
            style={{
              position: "absolute",
              top: "5px",
              right: "30px",
              color: "#ff0000",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {t("CANCELED")}
          </Typography>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ p: event.is_active === 0 ? 4 : 3 }}>
            <Grid container columnSpacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2.5}
                sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                <CardMedia
                  component="img"
                  sx={{ height: 150, maxWidth: 200, objectFit: "fill" }}
                  image={
                    event.principal_url === null ? "/media/events_null.svg" : event.principal_url
                  }
                  alt="Image"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={isSuperAdmin || isManager ? 3.25 : 4}>
                <Grid container item xs={11.5} justifyContent="space-between">
                  <Typography
                    className="oniria-colorText"
                    variant="subtitle1"
                    sx={{ fontWeight: "bolder", cursor: "pointer" }}
                    onClick={() => handleEventClick(props.event.id)}
                  >
                    {event.name}
                  </Typography>
                  {event.is_visible == 1 && <Visibility sx={{ color: "var(--oniria-gold)" }} />}
                </Grid>
                <Typography variant="subtitle2" mb={2}>
                  {event.premise_name}, {event.premise_city}
                </Typography>
                <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="subtitle2" mr={2}>
                    {formatDate(event.start_date)}
                  </Typography>
                  <Typography variant="subtitle2">
                    {formatHours(event.start_date)}-{formatHours(event.end_date)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={11.5}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Typography variant="subtitle2">
                    {path === "events" ? t("TICKETS_SOLD") : t("PLACES_SOLD")}: {event.sold_tickets}
                    /{event.total_capacity}
                  </Typography>
                  <Typography variant="subtitle2">
                    {soldTicketsPercent(event.sold_tickets, event.total_capacity)}%
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <LinearProgress
                    variant="determinate"
                    value={soldTicketsPercent(event.sold_tickets, event.total_capacity)}
                    sx={{
                      width: "100%",
                      height: 8,
                      mb: 1,
                      backgroundColor: "var(--oniria-sky)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "var(--secondary-color)",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {isSuperAdmin || isManager ? (
                <Grid item xs={12} sm={12} lg={3.2} container>
                  <Grid item xs={6} sm={4} lg={5}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("TOTAL_SALES")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {event.amount ? event.amount + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={5}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("REFUNDS")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {event.total_refunded ? event.total_refunded + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} lg={5}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("TOTAL_COMMISSIONS")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {event.commission ? event.commission + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} lg={2.5} container>
                  <Grid item xs={6} sm={4} lg={6}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("TOTAL_SALES")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {event.amount ? event.amount + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={6}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("REFUNDS")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {event.total_refunded ? event.total_refunded + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={3}
                container
                columnGap={3}
                alignItems={"center"}
                mt={{ xs: 2, lg: 0 }}
              >
                <Grid item xs={8}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/event/${props.event.id}?newValueTab=1`);
                    }}
                    type="submit"
                    aria-label="edit"
                    className="oniria-btn"
                    fullWidth
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3, mb: 1 }}
                  >
                    {t("LIST_ATTENDEES")}
                  </Button>
                </Grid>
                <Grid item xs={2.5}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: "33px", borderRadius: 3, mb: 1 }}
                    aria-label="show more"
                    className="oniria-btn"
                    onClick={handleClick}
                    aria-describedby={popperId}
                  >
                    <MoreHorizIcon sx={{ fontSize: "2rem" }} />
                  </Button>
                </Grid>
                <Popover
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePop}
                  id={popperId}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ButtonGroup
                    variant="contained"
                    orientation="vertical"
                    aria-label="vertical button group"
                  >
                    <Button className="popover-btn" aria-label="share" onClick={handleOpenShare}>
                      {t("SHARE")}
                    </Button>
                    <Dialog open={openShare} onClose={handleCloseShare}>
                      <DialogTitle
                        className="oniria-colorText"
                        sx={{
                          fontWeight: "bolder",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("SHARE_URL")}
                        <IconButton onClick={handleCloseShare}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ color: "black", mb: 1 }}>
                          {t("COPY_LINK")}
                        </DialogContentText>
                        <DialogActions sx={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            value="www.example.com"
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <IconButton onClick={handleCopyToClipboard} edge="start">
                                  <Box
                                    component="img"
                                    src={"/media/iconlink.svg"}
                                    sx={{ height: 20, width: 20 }}
                                  />
                                </IconButton>
                              ),
                            }}
                            sx={[focusColor, { mr: 2 }]}
                          />

                          <Button
                            type="submit"
                            className="oniria-btn"
                            variant="contained"
                            size="large"
                            sx={{ fontSize: "12px", borderRadius: 3 }}
                            onClick={handleCopyToClipboard}
                          >
                            {t("COPY")}
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Dialog>
                    <Button
                      className="popover-btn"
                      type="submit"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/event/${props.event.id}?newValueTab=2`);
                      }}
                    >
                      {t("ORDERS")}
                    </Button>
                    {/*
                                        <Button 
                                            className="popover-btn" 
                                            type="submit"
                                            onClick={(e) => { e.stopPropagation(); navigate(`/event/${props.event.id}?newValueTab=3`); }}>
                                            {t("STATISTICS")}
                                        </Button> */}
                    <Button className="popover-btn" type="submit" onClick={handleDuplicateEvent}>
                      {t("DUPLICATE")}
                    </Button>
                    {(isSuperAdmin || isManager || isAdmin) && (
                      <Button className="popover-btn" onClick={handleClickOpenDelete}>
                        {t("REMOVE")}
                      </Button>
                    )}
                    <Dialog
                      open={openDelete}
                      onClose={handleCloseDelete}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
                        {path === "events"
                          ? t("CANCEL_EVENT")
                          : path === "activities"
                          ? t("CANCEL_ACTIVITY")
                          : t("CANCEL_TRIP")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ color: "black" }}>
                          {path === "events"
                            ? t("CONFIRM_CANCEL_EVENT")
                            : path == "activities"
                            ? t("CONFIRM_CANCEL_ACTIVITY")
                            : t("CONFIRM_CANCEL_TRIP")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          type="submit"
                          className="oniria-btn-cancel"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleCloseDelete}
                        >
                          {t("CANCEL")}
                        </Button>
                        <Button
                          type="submit"
                          className="oniria-btn"
                          variant="contained"
                          size="medium"
                          sx={{ fontSize: "12px", borderRadius: 3 }}
                          onClick={handleDeleteButtonClick}
                        >
                          {t("ACCEPT")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ButtonGroup>
                </Popover>
                <Grid item xs={8}>
                  <Button
                    onClick={() => handleEventClick(props.event.id)}
                    aria-label="edit"
                    className="oniria-btn"
                    fullWidth
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3, mb: 1 }}
                  >
                    {t("MANAGE")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default CardEvents;
