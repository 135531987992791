import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cleanObjectOfVoidFields, transformObjectWithUrls } from "../shared/FormsValidator";
import {
  createExtra,
  createTicket,
  editEvent,
  updateExtra,
  updateTicket,
} from "../../services/eventsServices";
import Details from "./Details";
import MoreInformation from "./MoreInformation";
import Tickets from "./Tickets";
import Extras from "./Extras";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { createTripErrorsAllOk } from "../../classes/tripClass";
import { useNavigate } from "react-router";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const EditEvent = (props) => {
  const { t } = useTranslation();

  const event = props.formData;
  const steps =
    event.event_type === "trip"
      ? [t("DETAILS"), t("MORE_INFO"), t("TICKETS"), t("EXTRAS")]
      : [t("DETAILS"), t("TICKETS"), t("EXTRAS")];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(event);
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createTripErrorsAllOk))
  );
  const navigate = useNavigate();
  //console.log('errores del form ', createTripErrorsAllOk)

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    //console.log("Setting form errors: ", formParamsErrors, objectName, field, value);
    let object = JSON.parse(JSON.stringify(formParamsErrors[objectName]));
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrorsTicketsAndExtras = (objectName, value) => {
    //console.log("Setting form errors: ", formParamsErrors, objectName, value);
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: value,
    }));
  };

  const setFormParamsValue = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const { id, details, moreInformation, tickets, extras } = formData;
    //
    try {
      //
      const tripComplete = JSON.parse(JSON.stringify({ ...details, ...moreInformation }));
      tripComplete.min_age = parseInt(tripComplete.min_age);
      tripComplete.latitude = parseFloat(tripComplete.latitude);
      tripComplete.longitude = parseFloat(tripComplete.longitude);
      tripComplete.limit_tickets_order = parseInt(tripComplete.limit_tickets_order);
      //Double payment
      tripComplete.has_double_payment = tripComplete.has_double_payment == 1;
      tripComplete.first_payment_percentage = parseFloat(tripComplete.first_payment_percentage); // ? parseFloat(tripComplete.first_payment_percentage) : 0
      if (
        typeof tripComplete.slider_urls === "string" ||
        (typeof tripComplete.slider_urls === "object" && tripComplete.slider_urls.mime)
      ) {
        tripComplete.slider_urls = [tripComplete.slider_urls];
      }
      //
      tripComplete.total_capacity = 0;
      for (let i = 0; i < tickets.length; i++) {
        tripComplete.total_capacity += parseInt(tickets[i].initial_stock);
      }
      //
      const optionValues = [];
      for (let i = 0; i < tripComplete.included_options.length; i++) {
        optionValues.push(tripComplete.included_options[i].value);
      }
      tripComplete.included_options = JSON.stringify(optionValues);
      //
      for (let i = 0; i < tripComplete.blocks.length; i++) {
        if (!tripComplete.blocks[i].event_id) {
          tripComplete.blocks[i].event_id = id;
        }
        if (typeof tripComplete.blocks[i].images_urls === "string") {
          tripComplete.blocks[i].images_urls = [tripComplete.blocks[i].images_urls];
        }
      }
      //
      const tripToSend = await transformObjectWithUrls(tripComplete);
      //console.log("Trip to send edit:", tripToSend);
      const tripResponse = await editEvent(id, tripToSend);
      //console.log("Trip response: ", tripResponse);

      //
      for (let i = 0; i < tickets.length; i++) {
        const ticketCopy = await JSON.parse(JSON.stringify(tickets[i]));
        const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);

        // TODO: Revisar como gestionamos estos atributos
        ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock);
        ticketToSend.price = parseFloat(ticketToSend.price);
        ticketToSend.only_international = false;
        ticketToSend.iva = parseInt(ticketToSend.iva);
        ticketToSend.only_in_app = ticketToSend.only_in_app == 1;
        delete ticketToSend.stock;
        // PROMO CODE DISCOUNTS
        for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
          ticketToSend.promotional_code_discounts[k].discount = parseFloat(
            ticketToSend.promotional_code_discounts[k].discount
          );
          ticketToSend.promotional_code_discounts[k].is_active =
            ticketToSend.promotional_code_discounts[k].is_active == 1;
          ticketToSend.promotional_code_discounts[k].ticket_id = ticketToSend.id;
        }
        //
        if (ticketToSend.international_discount)
          ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
        if (ticketToSend.early_payment_discount)
          ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
        if (ticketToSend.promotional_code_discount)
          ticketToSend.promotional_code_discount = parseFloat(
            ticketToSend.promotional_code_discount
          );

        //
        for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
          ticketToSend.group_discounts[j].min_size = parseInt(
            ticketToSend.group_discounts[j].min_size
          );
          ticketToSend.group_discounts[j].max_size = parseInt(
            ticketToSend.group_discounts[j].max_size
          );
          ticketToSend.group_discounts[j].discount = parseFloat(
            ticketToSend.group_discounts[j].discount
          );
          ticketToSend.group_discounts[j].ticket_id = tickets[i].id;
        }
        //
        //
        if (ticketToSend.id) {
          const ticketResponse = await updateTicket(ticketToSend.id, ticketToSend);
          //console.log("Ticket response: ", ticketResponse);
        } else {
          ticketToSend.event_id = id;
          const ticketResponse = await createTicket(ticketToSend);
          //console.log("Ticket response: ", ticketResponse);
        }
      }
      //
      for (let i = 0; i < extras.length; i++) {
        const extraCopy = await JSON.parse(JSON.stringify(extras[i]));
        const extraToSend = await cleanObjectOfVoidFields(extraCopy);

        extraToSend.is_visible = extraToSend.is_visible == 1;
        extraToSend.initial_stock = parseInt(extraToSend.initial_stock);
        extraToSend.price = parseFloat(extraToSend.price);
        extraToSend.stock_by_ticket_max = parseInt(extraToSend.stock_by_ticket_max);
        extraToSend.times_can_be_consumed = parseInt(extraToSend.times_can_be_consumed ?? 1);
        //console.log("Extra to send: ", extraToSend);
        //
        if (extraToSend.id) {
          const extraResponse = await updateExtra(extraToSend.id, extraToSend);
          //console.log("Extra response: ", extraResponse);
        } else {
          extraToSend.event_id = id;
          const extraResponse = await createExtra(extraToSend);
          //console.log("Extra response: ", extraResponse);
        }
      }
      //
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        navigate(event.event_type === "trip" ? "/trips" : "/events");
      }, 1500);
    } catch (error) {
      console.error("Submit error: ", error);
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    if (event.event_type === "trip" && activeStep === 3) {
      return true;
    } else if (event.event_type !== "trip" && activeStep === 2) {
      return true;
    }
    return false;
  };

  const formController = {
    formData,
    setFormData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    setFormErrors,
    setFormErrorsTicketsAndExtras,
    setFormParamsValue,
  };

  const addComponent = (activeStep) => {
    //log("Showing component: ", event.event_type, activeStep, event);
    if (event.event_type === "trip") {
      return activeStep === 0 ? (
        <Details formController={formController} editEvent={true} typeTrip={true} />
      ) : activeStep === 1 ? (
        <MoreInformation formController={formController} editEvent={true} />
      ) : activeStep === 2 ? (
        <Tickets formController={formController} eventType={"trip"} />
      ) : (
        <Extras formController={formController} />
      );
    } else {
      return activeStep === 0 ? (
        <Details formController={formController} editEvent={true} isEvent={true} />
      ) : activeStep === 1 ? (
        <Tickets formController={formController} eventType={"event"} />
      ) : (
        <Extras formController={formController} />
      );
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{ mt: 5, mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Grid item xs={12} sm={5} md={9}>
            {!isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { color: "var(--secondary-color)" },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root": { color: "var(--secondary-color)" },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 2,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {/* {activeStep !== 3 && <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}><ArrowForwardIosIcon /></Button>} */}
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper connector activeStep={activeStep} sx={{ display: "block" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                          "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 4,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {isLastStep() && (
                  <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/*Descomentar esto*/}
      {addComponent(activeStep)}
    </>
  );
};

export default EditEvent;
