import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import EventHomeCard from "../components/home/EventHomeCard";
import HomeStatistics from "../components/home/HomeStatistics";
import { getHome } from "../services/utilsServices";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [greet, setGreet] = useState("");
  const actualDate = new Date();
  const actualHour = actualDate.getHours();
  const userInfo = useSelector((state) => state.userInfo);
  const allUserData = userInfo.userData;
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const isAffiliate = roles.find((element) => element === "affiliated");
  const isAdmin = roles.find((element) => element === "admin");
  const [affiliationData, setAffiliationData] = useState([]);
  const [nextEvents, setNextEvents] = useState([]);
  const [statistics, setStatistics] = useState("");
  const breadcrumbs = [
    {
      name: `${greet}, ${allUserData.name} ${allUserData.surname}`,
      link: "/home",
    },
  ];

  useEffect(() => {
    if (actualHour >= 5 && actualHour < 14) {
      setGreet(t("GOOD_MORNING"));
    } else if (actualHour >= 14 && actualHour < 21) {
      setGreet(t("GOOD_AFTERNOON"));
    } else {
      setGreet(t("GOOD_EVENING"));
    }
  }, [actualHour]);
  const handleQuerySuccess = (data) => {
    setAffiliationData(data.affiliation_requests);
    const sortedEvents = data.next_events.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateA - dateB;
    });
    setNextEvents(sortedEvents);
    setStatistics(data.statistics);
  };
  const { isLoading } = useQuery("home", () => getHome(), {
    onSuccess: handleQuerySuccess,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mt: 2,
        }}
      >
        {(isSuperAdmin || isManager || isAdmin) && (
          <Grid item xs={5.5} md={isSuperAdmin || isManager ? 3.5 : 2.4}>
            <Card
              onClick={() => navigate("/organizations")}
              elevation={4}
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                height: "100%",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <Box
                component="img"
                alt={t("ENTITIES")}
                src={"/media/homeIcons/organization.svg"}
                sx={{ width: 60, margin: "0 auto" }}
              />
              <Typography
                variant="h4"
                mt={2}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                {statistics.num_organizations}
              </Typography>
              <Typography
                variant="subtitle"
                sx={{
                  fontWeight: "bolder",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                }}
              >
                {t("ENTITIES")}
              </Typography>
            </Card>
          </Grid>
        )}

        <Grid item xs={5.5} md={isSuperAdmin || isManager ? 3.5 : 2.4}>
          <Card
            onClick={() => navigate("/premises")}
            elevation={4}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              height: "100%",
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              alt={t("ENTITIES")}
              src={"/media/homeIcons/premise.svg"}
              sx={{ width: 60, margin: "0 auto" }}
            />
            <Typography
              variant="h4"
              mt={2}
              sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
            >
              {statistics.num_premises}
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontWeight: "bolder",
                fontSize: { xs: "0.875rem", md: "1rem" },
              }}
            >
              {t("SITES")}
            </Typography>
          </Card>
        </Grid>
        {(isSuperAdmin || isAffiliate) && (
          <>
            <Grid item xs={5.5} md={isSuperAdmin || isManager ? 3.5 : 2.4}>
              <Card
                onClick={() => navigate(`/users?status=affiliated`)}
                elevation={4}
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  height: "100%",
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
              >
                <Box
                  component="img"
                  alt={t("ENTITIES")}
                  src={"/media/homeIcons/users.svg"}
                  sx={{ width: 60, margin: "0 auto" }}
                />
                <Typography
                  variant="h4"
                  mt={2}
                  sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
                >
                  {statistics.num_users_affiliated}
                </Typography>
                <Typography
                  variant="subtitle"
                  sx={{
                    fontWeight: "bolder",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                  }}
                >
                  {t("USERS_NUMBER_CARD")}
                </Typography>
              </Card>
            </Grid>
            {/* <Grid item xs={5.5} md={isSuperAdmin || isManager ? 3.5 : 2.4}>
              <Card
                onClick={() => navigate("/users?status=pending_payment")}
                elevation={4}
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  height: "100%",
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
              >
                <Box
                  component="img"
                  alt={t("USERS")}
                  src={"/media/homeIcons/users.svg"}
                  sx={{ width: 60, margin: "0 auto" }}
                />
                <Typography
                  variant="h4"
                  mt={2}
                  sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
                >
                  {statistics.num_users_pending_payment}
                </Typography>
                <Typography
                  variant="subtitle"
                  sx={{
                    fontWeight: "bolder",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                  }}
                >
                  {t("USERS_NUMBER_NO_CARD")}
                </Typography>
              </Card>
            </Grid> */}
          </>
        )}
        <Grid item xs={5.5} md={isSuperAdmin || isManager ? 3.5 : 2.4}>
          <Card
            elevation={4}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              height: "100%",
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box
              component="img"
              alt={t("TOTAL_SALES")}
              src={"/media/homeIcons/money.svg"}
              sx={{ width: 60, margin: "0 auto" }}
            />
            <Typography
              variant="h4"
              mt={2}
              sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
            >
              {statistics.total_sells}
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontWeight: "bolder",
                fontSize: { xs: "0.875rem", md: "1rem" },
              }}
            >
              {t("TOTAL_SALES")}
            </Typography>
          </Card>
        </Grid>
        {(isSuperAdmin || isManager) && (
          <Grid item xs={5.5} md={3.5}>
            <Card
              elevation={4}
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                height: "100%",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
              }}
            >
              <Box
                component="img"
                alt={t("TOTAL_COMMISSIONS")}
                src={"/media/homeIcons/money.svg"}
                sx={{ width: 60, margin: "0 auto" }}
              />
              <Typography
                variant="h4"
                mt={2}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                {statistics.total_commissions}
              </Typography>
              <Typography
                variant="subtitle"
                sx={{
                  fontWeight: "bolder",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                }}
              >
                {t("TOTAL_COMMISSIONS")}
              </Typography>
            </Card>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "stretch",
          mt: 4,
          mb: 10,
        }}
      >
        {(isSuperAdmin || isAffiliate) && affiliationData?.length > 0 && (
          <Grid
            item
            xs={11}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Typography
              className="oniria-colorText"
              sx={{ fontWeight: "bold", mb: 2 }}
              variant="h5"
            >
              {t("MEMBERSHIP_APPLICATIONS")}
            </Typography>
            <Card
              elevation={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
                height: "100%",
                maxHeight: "100%",
              }}
            >
              <Typography
                sx={{ fontWeight: "bolder", mt: 2 }}
                variant="subtitle1"
              >
                {t("YOU_HAVE")} {affiliationData ? affiliationData.length : 0}{" "}
                {t("PENDING_REQUEST")}
              </Typography>
              <Grid
                item
                sx={{
                  maxHeight: "calc(60vh - 85px)",
                  overflowY: "auto",
                  mr: 1,
                }}
              >
                <List sx={{ width: "100%", mt: 1 }}>
                  {affiliationData &&
                    affiliationData.map((affiliate, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={affiliate.avatar_url} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={affiliate.name + " " + affiliate.surname}
                          secondary={affiliate.premise_name}
                        />
                      </ListItem>
                    ))}
                </List>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                onClick={() => navigate("/users/pending_users")}
                variant="contained"
                className="oniria-btn"
                sx={{
                  width: 220,
                  margin: "0 auto",
                  mb: 4,
                  borderRadius: 3,
                  fontSize: "16px",
                }}
              >
                {t("USERS_VALIDATION")}
              </Button>
            </Card>
          </Grid>
        )}
        <Grid
          item
          // xs={11}
          // md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            className="oniria-colorText"
            sx={{ fontWeight: "bold", mb: 2 }}
            variant="h5"
          >
            {t("UPCOMING_EVENTS")}
          </Typography>
          <Paper
            elevation={4}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Grid
              item
              sx={{ maxHeight: "calc(60vh - 85px)", overflowY: "auto" }}
            >
              {nextEvents?.map((event, index) => (
                <EventHomeCard event={event} key={index} />
              ))}
            </Grid>
            <Button
              onClick={() => navigate("/events")}
              variant="contained"
              className="oniria-btn"
              sx={{
                width: 220,
                margin: "0 auto",
                my: 2,
                borderRadius: 3,
                fontSize: "16px",
              }}
            >
              {t("GO_EVENTS")}
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <HomeStatistics />
    </>
  );
};

export default Home;
