import { Breadcrumbs, Typography, Link, Grid } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UserAvatar from './UserAvatar';
import Notifications from './Notifications'
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
  const navigate = useNavigate();
  const breadcrumbs = props.breadcrumbs
  const finalBreadcrumb = breadcrumbs.slice(-1)[0];

  return (
    <>
      <Grid item xs={12} sx={{ mt: 5, mb: 8 }}>
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Grid item xs={11} md={9} sx={{ mt: 3 }}>
            {breadcrumbs.length > 0 &&
              <Breadcrumbs separator={<NavigateNextIcon fontSize="large" sx={{ cursor: "pointer", color: 'var(--secondary-color)' }} />} aria-label='breadcrumb'>
                {(breadcrumbs.length > 1) &&
                  breadcrumbs.slice(0, -1).map((breadcrumb, i) =>
                    <Link
                      key={i}
                      underline="none"
                      onClick={breadcrumb.link.includes('/event/') ? () => window.location.reload() : () => navigate(breadcrumb.link)}
                      className='oniria-colorText' sx={{ cursor: "pointer", fontSize: "20px" }}>
                      {breadcrumb.name}
                    </Link>
                  )
                }
                <Typography key="2" className='oniria-colorText' variant="h5" sx={{ fontWeight: "bolder" }}>
                  {finalBreadcrumb.name}
                </Typography>
              </Breadcrumbs>
            }
          </Grid>
          <Grid item xs={11} md={3} >
            <UserAvatar />
            {/* <Notifications /> */}
          </Grid>
          <Grid item xs={11} md={12}>
            <Typography variant='subtitle2' sx={{ color: "var(--secondary-color)" }}>{props.description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Header