import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getCategories() {
  const { data } = await http.get(SERVICE.CATEGORIES);
  return data;
}

export async function createCategory(body) {
  const { data } = await http.post(SERVICE.CATEGORIES, body);
  return data;
}

export async function updateCategory(id, name) {
  const { data } = await http.put(`${SERVICE.CATEGORIES}/${id}`, name);
  return data;
}

export async function getCategoryPremise(id) {
  const { data } = await http.get(`${SERVICE.CATEGORIES}/premise/${id}`);
  return data;
}

export async function updateCategoriesPremise(id, body) {
  const { data } = await http.put(`${SERVICE.CATEGORIES}/premise/${id}`, body);
  return data;
}
