import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getAllRrpp(premise, search) {
  const param = new URLSearchParams();
  if (premise && premise !== "all") param.append("premiseid", premise);
  if (search && search !== "") param.append("search", search);

  const { data } = await http.get(`${SERVICE.RRPP}?${param}`);

  return data;
}

export async function getAllRrppbyEvent(id, page, search) {
  const param = new URLSearchParams();
  if (search && search !== "") param.append("search", search);
  if (page) param.append("page", page);
  if (id) param.append("eventid", id);
  const { data } = await http.get(`${SERVICE.RRPP}?${param}`);
  return data;
}

export async function SetRrpps(eventId, userIds) {
  const requestData = {
    users: userIds,
  };
  await http.post(`${SERVICE.RRPP}/event/${eventId}`, requestData);
  // No es necesario retornar nada, ya que no hay datos específicos para retornar en este caso
}
