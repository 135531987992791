import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  getDownloadSepa,
  getSepaMarkPayed,
  getSepas,
  putSepasRegenerate,
} from '../../services/billingServices'
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { handleDownloadSepa, handleDownloadPdf } from './functions/fileDownloads'
import { toastMessageError, toastMessageSuccess } from '../shared/toastMessage'
import { ExpandLess, ExpandMore, FileDownload } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import { focusColor } from '../shared/textFieldStyle'
import { getPremises } from '../../services/premisesServices'
import CustomDialog from '../shared/CustomDialog'
import { formatDate } from '../../constants/utils'

let sepasAmount
let perPage = 10

const ListSepasPlus = ({ isSuperAdmin }) => {
  const [sepas, setSepas] = useState([])
  const [premises, setPremises] = useState([])
  const [selectedPremise, setSelectedPremise] = useState('')
  const [date, setDate] = useState({ from: '', to: '' })
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState({ asc: [], desc: ['from_date'] })
  const [isOpenPaid, setOpenPaid] = useState(false)
  const [isOpenRegenerate, setOpenRegenerate] = useState(false)
  const [selectedBill, setSelectedBill] = useState('')
  const { t } = useTranslation()

  //Get de los Sepas
  const handleSepaOk = data => {
    sepasAmount = data.amount
    setSepas(data.data)
  }
  const { isLoading, isRefetching, refetch } = useQuery(
    ['sepa', selectedPremise, date, page, order],
    () => getSepas(selectedPremise, date, page, perPage, order),
    {
      onSuccess: handleSepaOk,
      refetchOnWindowFocus: false,
    },
  )

  //descarga de sepas xml
  const handleDownload = id => {
    //
    const sepaEntry = sepas.find(item => item.id === id)
    const fileName =
      sepaEntry.premise_name +
      '-' +
      new Date(sepaEntry.from_date).toLocaleDateString() +
      '-' +
      new Date(sepaEntry.to_date).toLocaleDateString()
    //
    getDownloadSepa(id)
      .then(data => {
        if (isSuperAdmin) {
          handleDownloadSepa(data?.sepa_xml, 'sepa-' + fileName)
        }
        handleDownloadPdf(data?.pdf_invoice, 'pdf-' + fileName)
        toastMessageSuccess(t('DOWNLOAD_START'))
        refetch()
      })
      .catch(error => toastMessageError(error.response.data.error ?? 'Error'))
  }

  //llamada de sedes
  const handlePremiseSuccess = data => {
    setPremises(data)
  }
  const {} = useQuery('allPremises', () => getPremises(), {
    onSuccess: handlePremiseSuccess,
    refetchOnWindowFocus: false,
  })
  const handleChangePremise = (e, value) => {
    setPage(1)
    setSelectedPremise(value?.premise_id)
  }
  //manejo de filtro fecha
  const handleChangeDate = (field, value) => {
    setPage(1)
    setDate(prevDate => {
      if (field === 'from' && prevDate.to && new Date(value) > new Date(prevDate.to)) {
        return {
          ...prevDate,
          [field]: value,
          to: value,
        }
      } else {
        return {
          ...prevDate,
          [field]: value,
        }
      }
    })
  }
  const today = new Date().toISOString().split('T')[0]

  //filtros sepas
  const handleSortBills = (id, orderType) => {
    setPage(1)
    setOrder(prevOrder => {
      const oppositeOrderType = orderType === 'asc' ? 'desc' : 'asc'
      return {
        ...prevOrder,
        [orderType]: prevOrder[orderType].includes(id)
          ? prevOrder[orderType].filter(columnId => columnId !== id)
          : [...prevOrder[orderType], id],
        [oppositeOrderType]: prevOrder[oppositeOrderType].filter(columnId => columnId !== id),
      }
    })
  }

  //BUTTONS TO SORT THE sepas
  const renderArrowButtons = id => {
    return (
      <ButtonGroup sx={{ fontSize: '5px' }}>
        <IconButton
          aria-label="sort"
          size="small"
          name={'asc'}
          onClick={() => handleSortBills(id, 'asc')}
          className={order['asc'].includes(id) ? 'oniria-btn-check-validated' : 'oniria-btn-check'}
          sx={{ height: '20px', width: '20px' }}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          aria-label="sort"
          size="small"
          name={'desc'}
          onClick={() => handleSortBills(id, 'desc')}
          className={order['desc'].includes(id) ? 'oniria-btn-check-validated' : 'oniria-btn-check'}
          sx={{ height: '20px', width: '20px' }}
        >
          <ExpandMore />
        </IconButton>
      </ButtonGroup>
    )
  }

  //Change page
  const handleChangePage = (e, value) => {
    setPage(value)
  }
  const numberPages = Math.ceil(sepasAmount / perPage)

  //MARK BILL PAYED
  const handleOpenPaid = id => {
    setSelectedBill(id)
    setOpenPaid(true)
  }

  const handlePaidBill = () => {
    getSepaMarkPayed(selectedBill)
      .then(data => {
        if (data == 'success') {
          toastMessageSuccess('Factura pagada con éxito')
          refetch()
        } else {
          toastMessageError(data ?? 'Error')
        }
      })
      .catch(e => toastMessageError(e.response.data.error ?? 'Error'))
    setOpenPaid(false)
  }
  const handleCloseBill = () => {
    setOpenPaid(false)
  }

  //REGENERATE SEPA
  const handleOpenRegenerate = id => {
    setSelectedBill(id)
    setOpenRegenerate(true)
  }
  const handleRegenerateSepa = async () => {
    try {
      await putSepasRegenerate(selectedBill)
      toastMessageSuccess('Factura regenerada correctamente')
      await refetch()
      setOpenRegenerate(false)
    } catch (e) {
      toastMessageError(e.response.data.error ?? 'Error')
    }
  }
  const handleCloseRegenerate = () => {
    setOpenRegenerate(false)
  }

  return (
    <>
      <Grid container component={Paper} sx={{ padding: 2, gap: 2, marginBottom: 2 }}>
        <Typography variant="h6">{t('LIST_SEPAS_PLUS')}</Typography>
        <FormControl sx={[focusColor, { paddingTop: 2 }]} fullWidth>
          <Autocomplete
            id="auto-complete"
            options={premises}
            getOptionLabel={option => option.premise_name || ''}
            renderOption={(props, option) => (
              <li {...props} key={option.premise_id}>
                {option.premise_name}
              </li>
            )}
            renderInput={params => (
              <TextField {...params} label={t('PREMISES')} variant="outlined" />
            )}
            onChange={handleChangePremise}
            value={premises?.find(premise => premise.premise_id === selectedPremise) || null}
            isOptionEqualToValue={(option, value) => option.premise_id === value.premise_id}
          />
        </FormControl>
        {/* DATE FILTER */}
        <FormControl sx={[focusColor, { gap: 2 }]} fullWidth>
          <Grid container alignItems="center" justifyContent="space-between" rowGap={2}>
            <Grid item container alignItems="center" justifyContent="space-between" xs={12} md={5}>
              <Typography>{t('FROM')}</Typography>
              <TextField
                type="date"
                value={date.from}
                onChange={e => handleChangeDate('from', e.target.value)}
                inputProps={{ max: today }}
              />
            </Grid>
            <Grid item container alignItems="center" justifyContent="space-between" xs={12} md={5}>
              <Typography>{t('UNTIL')}</Typography>
              <TextField
                type="date"
                value={date.to}
                onChange={e => handleChangeDate('to', e.target.value)}
                inputProps={{ min: date.from, max: today }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>

      {isLoading || isRefetching ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
          <CircularProgress sx={{ color: 'var(--secondary-color)' }} />
        </Grid>
      ) : sepas.length > 0 ? (
        <>
          {/* PAGINATION */}
          <Grid
            item
            xs={12}
            container
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            alignItems="center"
            rowGap={2}
            paddingY={2}
          >
            <Typography>
              {sepasAmount} {t('LAST_MOVEMENTS')}
            </Typography>
            {numberPages > 1 && (
              <Pagination
                count={numberPages}
                page={page}
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 'bolder',
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      background: '#DC4451',
                      color: '#fff',
                      fontWeight: 'bolder',
                    },
                    fontWeight: '600',
                  },
                }}
              />
            )}
          </Grid>
          <TableContainer sx={{ marginBottom: 20 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: '2px solid var(--secondary-color)' }}>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('SITE_NAME')}
                      {renderArrowButtons('premise_name')}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('START_DATE')}
                      {renderArrowButtons('from_date')}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('END_DATE')}
                      {renderArrowButtons('to_date')}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('AMOUNT')}
                      {renderArrowButtons('total_amount')}
                    </Grid>
                  </TableCell>
                  {isSuperAdmin && (
                    <TableCell>
                      <Grid
                        container
                        flexDirection="row"
                        wrap="nowrap"
                        alignItems="center"
                        columnGap={1}
                      >
                        {t('COMMISSION')}
                        {renderArrowButtons('total_commission')}
                      </Grid>
                    </TableCell>
                  )}
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('PAID')}
                      {renderArrowButtons('payed_at')}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      flexDirection="row"
                      wrap="nowrap"
                      alignItems="center"
                      columnGap={1}
                    >
                      {t('DOWNLOAD_SEPA_XML')}
                    </Grid>
                  </TableCell>
                  {isSuperAdmin && <TableCell>{t('REGENERATE_BILL')}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sepas.map((sepa, index) => (
                  <TableRow key={index}>
                    <TableCell>{sepa.premise_name}</TableCell>
                    <TableCell>{sepa.from_date ? formatDate(sepa.from_date) : ''}</TableCell>
                    <TableCell>{sepa.from_date ? formatDate(sepa.to_date) : ''}</TableCell>
                    <TableCell>{sepa.total_amount} €</TableCell>
                    {isSuperAdmin && <TableCell>{sepa.total_commission} €</TableCell>}
                    <TableCell>
                      {sepa.payed_at
                        ? formatDate(sepa.payed_at)
                        : isSuperAdmin && (
                            <Button
                              className="oniria-btn"
                              sx={{ color: '#fff' }}
                              size="small"
                              onClick={() => handleOpenPaid(sepa.id)}
                            >
                              {t('PAY')}
                            </Button>
                          )}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Button onClick={() => handleDownload(sepa?.id)}>
                          <FileDownload />
                        </Button>
                        {sepa.downloaded_at && <CheckIcon color="success" />}
                      </Box>
                    </TableCell>
                    {isSuperAdmin && (
                      <TableCell>
                        <Button
                          className="oniria-btn"
                          sx={{ color: '#fff' }}
                          size="small"
                          onClick={() => handleOpenRegenerate(sepa.id)}
                        >
                          {t('REGENERATE')}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {numberPages > 1 && (
            <Grid item xs={12} sx={{ mt: 10 }}>
              <Pagination
                count={numberPages}
                sx={{
                  mb: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 'bolder',
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      background: '#DC4451',
                      color: '#fff',
                      fontWeight: 'bolder',
                    },
                    fontWeight: '600',
                  },
                }}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </>
      ) : (
        <div>{t('EMPTY_SEPAS')}</div>
      )}
      <CustomDialog
        isOpen={isOpenPaid}
        onClose={handleCloseBill}
        title={t('PAY_BILL')}
        content={t('PAY_BILL_TEXT')}
        onAccept={handlePaidBill}
      />
      <CustomDialog
        isOpen={isOpenRegenerate}
        onClose={handleCloseRegenerate}
        title={t('REGENERATE_BILL')}
        content={t('REGENERATE_BILL_TEXT')}
        onAccept={handleRegenerateSepa}
      />
    </>
  )
}

export default ListSepasPlus
