import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { editRrppErrors } from "../../classes/rrppClass";
import { getPremisesList } from "../../services/premisesServices";
import { editRrpp } from "../../services/rrppsServices";
import { allFieldsOk, handleImageSelect, transformObjectWithUrls } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

const EditRrppForm = (props) => {
  const { t } = useTranslation();
  const { data, rrppId, imageDisable } = props;
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(data)));
  const [userError, setUserError] = useState(editRrppErrors);
  const [premises, setPremises] = useState([]);
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const url = window.location.href;
  const path = url.split("/").slice(4).join("/");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      avatar_url: data.avatar,
    }));
  }, []);

  const [selectedPremises, setSelectedPremises] = useState(
    data.sedes.map((sede) => sede.premise_id)
  );

  const setFormErrors = (objectName, field, value) => {
    let object = userError;
    object[field] = value;
  };
  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData;
    object[field] = value;
    setFormData((prev) => ({ ...object }));
  };
  const handleFieldChange = (event, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    switch (fieldName) {
      case "name":
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: fieldValue,
        }));
        break;
      case "surname":
        setFormData((prevFormData) => ({
          ...prevFormData,
          surname: fieldValue,
        }));
        break;
      case "phone":
        setFormData((prevFormData) => ({
          ...prevFormData,
          phone: fieldValue,
        }));
        break;
      default:
    }
    if (regex) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: regex.test(fieldValue) ? "" : helperText,
      }));
    }
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  useEffect(() => {
    const fetchPremises = async () => {
      try {
        const data = await getPremisesList();
        setPremises(data);
      } catch (error) {
        console.error("Error fetching premises:", error);
      }
    };
    fetchPremises();
  }, []);

  const handleSelectPremise = (event) => {
    const selectedPremisesIds = event.target.value;
    setSelectedPremises(selectedPremisesIds);
    setFormData((prevFormData) => ({
      ...prevFormData,
      premises_ids: selectedPremisesIds,
    }));
    setUserError((prevUserError) => ({ ...prevUserError, premises_ids: "" }));
  };

  const renderTextField = (
    label,
    fieldName,
    value,
    errorProperty,
    helperText,
    regex,
    disabled,
    type
  ) => (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, regex, helperText)}
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && buttonClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : helperText}
        disabled={path !== `edit_rrpp/${rrppId}` ? true : disabled}
      />
    </>
  );
  const handleEditRrppSubmit = async (e) => {
    e.preventDefault();
    setButtonClicked(true);

    if (selectedPremises.length === 0) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        premises_ids: t("EDIT_ERROR"),
      }));
      return;
    }
    if (allFieldsOk(userError)) {
      const formDataToSend = await transformObjectWithUrls(formData);
      const bodyToSend = {
        name: formDataToSend.name,
        surname: formDataToSend.surname,
        phone: formDataToSend.phone,
        premises_ids: selectedPremises,
        avatar_url: formDataToSend.avatar_url,
      };
      try {
        setIsSending(true);
        const response = await editRrpp(rrppId, bodyToSend);
        if (response === "success" && response !== undefined) {
          toastMessageSuccess(t("EDIT_SUCCESS"));
          setTimeout(() => {
            setFormData("");
            navigate("/rrpps");
          }, 1500);
        }
      } catch (error) {
        setIsSending(false);
        toastMessageError(t("EDIT_ERROR") + error.response.data.error);
      }
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          mt: 5,
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleEditRrppSubmit}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("NAME")}</InputLabel>
                  {renderTextField(
                    t("NAME"),
                    "name",
                    formData.name,
                    userError.name,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("SURNAME")}</InputLabel>
                  {renderTextField(
                    t("SURNAME"),
                    "surname",
                    formData.surname,
                    userError.surname,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("ALIAS")}</InputLabel>
                  <TextField
                    disabled={path === `edit_rrpp/${rrppId}` && true}
                    sx={focusColor}
                    margin="normal"
                    fullWidth
                    id="alias"
                    name="alias"
                    value={data.alias}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("BORN_AT")}</InputLabel>
                  <TextField
                    disabled={path === `edit_rrpp/${rrppId}` && true}
                    sx={focusColor}
                    margin="normal"
                    fullWidth
                    id="born_at"
                    name="born_at"
                    value={formatDate(data.born_at)}
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("PHONE")}</InputLabel>
                  {renderTextField(
                    t("PHONE"),
                    "phone",
                    formData.phone,
                    userError.phone,
                    t("INCORRECT"),
                    /^\d{7,10}$/
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("EMAIL")}</InputLabel>
                  <TextField
                    disabled={path === `edit_rrpp/${rrppId}` && true}
                    sx={focusColor}
                    margin="normal"
                    fullWidth
                    id={"email"}
                    name={"email"}
                    autoComplete={"email"}
                    value={data.email}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("AVATAR")}
                  </InputLabel>
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <ImageUpload
                      error={formData.avatar_url === "" && buttonClicked}
                      url={formData.avatar_url}
                      selectedImagesInForm={formData.avatar_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          formData,
                          url,
                          "avatar_url",
                          setFormParamsNotEvent,
                          setFormErrors,
                          true
                        )
                      }
                      multi={false}
                      disabled={imageDisable}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("ENTITY")}</InputLabel>
                  <TextField
                    disabled={path === `edit_rrpp/${rrppId}` && true}
                    sx={focusColor}
                    margin="normal"
                    fullWidth
                    id={"organization"}
                    name={"organization"}
                    autoComplete={"organization"}
                    value={data.sedes.map((value) => value.organization_name).join(", ")}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("PREMISES")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={formData.premises_ids === ""}
                  >
                    <InputLabel>{t("PREMISES")}</InputLabel>
                    <Select
                      disabled={path !== `edit_rrpp/${rrppId}` && true}
                      multiple
                      error={userError.premises_ids}
                      sx={{ mb: 5 }}
                      name="premises_ids"
                      id="premises"
                      label={t("PREMISES")}
                      value={selectedPremises}
                      onChange={handleSelectPremise}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (value) =>
                              premises.find((premise) => premise.premise_id === value)?.premise_name
                          )
                          .join(", ")
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        sx: {
                          "&& .Mui-selected": { backgroundColor: " #cceceb" },
                          top: "-50px",
                          bottom: "50px",
                        },
                      }}
                    >
                      {premises.map((premise) => (
                        <MenuItem key={premise.premise_id} value={premise.premise_id}>
                          <ListItemText primary={premise.premise_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {!allFieldsOk(userError) && buttonClicked && (
                <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
              )}
              {path === `edit_rrpp/${rrppId}` && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    pt: 2,
                  }}
                >
                  {isSending ? (
                    <Grid container justifyContent="center">
                      <CircularProgress sx={{ color: "var(--secondary-color)" }} />
                    </Grid>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setFormData("");
                          navigate("/rrpps");
                        }}
                        className="oniria-btn-cancel"
                        fullWidth
                        variant="contained"
                        sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                      >
                        {" "}
                        {t("CANCEL")}{" "}
                      </Button>
                      <Button
                        type="submit"
                        className="oniria-btn"
                        fullWidth
                        variant="contained"
                        sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                      >
                        {t("SAVE")}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EditRrppForm;
