import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputLabel, Paper, TextField, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import ImageUpload from '../shared/ImageUpload';
import Address from './forms/Address';
import BillingData from './forms/BillingData';
import Contact from './forms/Contact';
import FiscalAddress from './forms/FiscalAddress';
import { allFieldsOk, checkField, handleImageSelect } from '../shared/FormsValidator';
import ButtonsForms from './forms/ButtonsForms';
import HeaderForms from './forms/HeaderForms';
import { focusColor } from '../shared/textFieldStyle';

const Organization = (props) => {

    const { t } = useTranslation();
    const formController = props.formController
    const { organization } = formController.formData
    const { organization: organizationErrors } = formController.validation
    const { formParams, setFormErrors, setFormParamsNotEvent } = formController
    const [buttonClicked, setButtonClicked] = useState(false)
    const [sameAddress, setSameAddress] = useState(false)

    const url = window.location.href;
    const path = url.split("/").slice(3).join("/");

    const isDisabled = () => {
        return props.isDisabled ? props.isDisabled : false
    }
    // to set next button form clicked 
    const catchButtonClicked = (value) => {
        setButtonClicked(value)
    }

    //Change fiscal address = address
    const handleCheckAddress = () => {
        setSameAddress(!sameAddress)
    }

    const handleChangeAddress = () => {
        if (sameAddress) {
            organization.fiscal_address = organization.address;
            organization.fiscal_postal_code = organization.postal_code;
            organization.fiscal_country = organization.country;
            organization.fiscal_state = organization.state;
            organization.fiscal_city = organization.city;
            setFormErrors('organization', 'fiscal_address', "");
            setFormErrors('organization', 'fiscal_city', "");
            setFormErrors('organization', 'fiscal_postal_code', "");
            setFormErrors('organization', 'fiscal_country', "");
            setFormErrors('organization', 'fiscal_state', "");
        }
    }

    useEffect(() => {
        handleChangeAddress();
    }, [sameAddress, organization])

    return (
        <>
            <HeaderForms title={props.title} description={props.description} />
            <Grid container sx={{ mt: 5, mb: 20, px: 1, color: "black", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Grid item xs={11} md={9} lg={7.5} >
                    <Box component={Paper} elevation={3}
                        sx={{
                            py: 2,
                            px: 3,
                            border: "1px solid #E4E4E4",
                            borderRadius: "15px"
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 2 }}>
                            <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Grid item xs={11} md={12}>
                                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                                        {t("ENTITY_NAME")}
                                    </InputLabel>
                                    <TextField
                                        onChange={e => checkField('organization', 'legal_name', e, /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_-]).{1,50}$/, t("MAX_50"), false, formParams, setFormErrors)}
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={organization.legal_name}
                                        id="legal_name"
                                        name="legal_name"
                                        autoComplete="legal_name"
                                        autoFocus
                                        label={t("ENTITY_NAME")}
                                        error={(organizationErrors.legal_name !== "" && organizationErrors.legal_name !== "empty") || (organizationErrors.legal_name === "empty" && buttonClicked)}
                                        helperText={organizationErrors.legal_name !== 'empty' ? organizationErrors.legal_name : ''}
                                        disabled={isDisabled()}
                                        sx={focusColor}
                                    />
                                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                                        {t("ENTITY_DESCRIPTION")}
                                    </InputLabel>
                                    <TextField
                                        onChange={e => checkField('organization', 'description', e, /^(?!.*[|\\#·$~%&¬/()^[\]+*{}_])[\s\S]{1,1000}$/, t("MAX_1000"), false, formParams, setFormErrors)}
                                        value={organization.description}
                                        margin="normal"
                                        required
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id="description"
                                        name="description"
                                        label={t("ENTITY_DESCRIPTION")}
                                        autoComplete="description"
                                        error={(organizationErrors.description !== "" && organizationErrors.description !== "empty") || (organizationErrors.description === "empty" && buttonClicked)}
                                        helperText={organizationErrors.description !== 'empty' ? organizationErrors.description : ''}
                                        disabled={isDisabled()}
                                        sx={focusColor}
                                    />
                                    <Grid item xs={12} sx={{mb:2}}>
                                        <Address classForm={'organization'} formParams={formParams} formData={organization} checkField={checkField} errors={organizationErrors} setErrors={setFormErrors} isDisabled={isDisabled()} buttonClicked={buttonClicked} handleChangeAddress={handleChangeAddress} />
                                        <FormControlLabel
                                            control={<Checkbox name="sameAddress"
                                                checked={sameAddress}
                                                onChange={handleCheckAddress}
                                                value={sameAddress}
                                                sx={{
                                                    color: "var(--secondary-color)", '&.Mui-checked': {
                                                        color: "var(--secondary-color)"
                                                    },
                                                }} />}
                                            label={t("SAME_ADDRESS")}
                                        />
                                    </Grid>
                                    <Contact
                                        classForm={'organization'}
                                        formParams={formParams}
                                        formData={organization}
                                        checkField={checkField}
                                        errors={organizationErrors}
                                        setErrors={setFormErrors}
                                        isDisabled={isDisabled()}
                                        buttonClicked={buttonClicked}
                                        hasToCheckMail={false}
                                    />
                                    {path !== "createaccount" && <>
                                        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                                            {t("LOGO")}
                                        </InputLabel>
                                        <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                                            <Grid item xs={12}>
                                                <ImageUpload url={organization.logo_url} selectedImagesInForm={organization.logo_url} onImageSelect={(url) => handleImageSelect('organization', url, "logo_url", setFormParamsNotEvent, setFormErrors)} multi={false} disabled={isDisabled()}/>
                                            </Grid>
                                        </Grid>
                                    </>}
                                    <BillingData
                                        classForm={'organization'}
                                        formController={formController}
                                        formParams={formParams}
                                        formData={organization}
                                        checkField={checkField}
                                        errors={organizationErrors}
                                        setErrors={setFormErrors}
                                        isDisabled={isDisabled()}
                                        buttonClicked={buttonClicked}
                                    />
                                    {path !== "createaccount" && <>
                                        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                                            {t("BANK_ACCOUNT_OWNERSHIP")}
                                            <Tooltip title={t("BANK_ACCOUNT_INFO")} sx={{ color: 'black' }}>
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputLabel>
                                        <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                                            <Grid item xs={12} >
                                                <ImageUpload url={organization.bank_account_url} selectedImagesInForm={organization.bank_account_url} onImageSelect={(url) => handleImageSelect('organization', url, "bank_account_url", setFormParamsNotEvent, setFormErrors)} multi={false} disabled={isDisabled()}/>
                                            </Grid>
                                        </Grid>
                                        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2, mt: 2 }}>
                                            {t("NIF")}
                                            <Tooltip title={t("NIF_INFO")} sx={{ color: 'black' }}>
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputLabel>
                                        <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                                            <Grid item xs={12} >
                                                <ImageUpload url={organization.nif_certificate_url} selectedImagesInForm={organization.nif_certificate_url} onImageSelect={(url) => handleImageSelect('organization', url, "nif_certificate_url", setFormParamsNotEvent, setFormErrors)} multi={false} disabled={isDisabled()}/>
                                            </Grid>
                                        </Grid>
                                    </>}
                                    <FiscalAddress classForm={'organization'} formParams={formParams} formData={organization} checkField={checkField} errors={organizationErrors} setErrors={setFormErrors} isDisabled={isDisabled() || sameAddress} buttonClicked={buttonClicked} />
                                </Grid>
                            </Grid>
                        </Box>
                        {(!allFieldsOk(organizationErrors) && buttonClicked) && <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>}
                        {!isDisabled() ? <ButtonsForms formController={formController} allFieldsOk={allFieldsOk} errors={organizationErrors} sendButtonClicked={catchButtonClicked} /> : ""}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Organization