import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import CardPremise from "../../components/premises/CardPremise";
import { focusColor } from "../../components/shared/textFieldStyle";
import { getPremises } from "../../services/premisesServices";

const Premises = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [premises, setPremises] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredPremises, setFilteredPremises] = useState([]);
  const [cityFilter, setCityFilter] = useState("all");
  const [entityFilter, setEntityFilter] = useState("all");
  const breadcrumbs = [
    {
      name: t("VENUES"),
      link: "/premises",
    },
  ];
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isDelegate = roles.find((element) => element == "delegate");

  const handleQuerySuccess = (data) => {
    setPremises(data);
  };
  const { isLoading, refetch } = useQuery("premises", () => getPremises(), {
    onSuccess: handleQuerySuccess,
  });

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    const filteredPremises = premises.filter((premise) =>
      premise.premise_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredPremises(filteredPremises);
  };

  // Obtener ciudades
  const cities = [...new Set(premises.map((premise) => premise.city))];

  const menuItems = cities.map((city) => (
    <MenuItem key={city} value={city}>
      {city}
    </MenuItem>
  ));
  //Obtener entidades
  const entities = [...new Set(premises.map((premise) => premise.organization_name))];

  const entitiesItems = entities.map((entity) => (
    <MenuItem key={entity} value={entity}>
      {entity}
    </MenuItem>
  ));

  // Filtrar sedes por entidad, ciudades y búsqueda
  const allFiltered = premises.filter((premise) => {
    const isEntityMatch = entityFilter === "all" || premise.organization_name === entityFilter;
    const isCityMatch = cityFilter === "all" || premise.city === cityFilter;
    const isSearchMatch = search === "" || filteredPremises.includes(premise);
    return isEntityMatch && isCityMatch && isSearchMatch;
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_PREMISE")} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          mb: 5,
          ml: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={12} md={5}>
            <SearchIcon
              sx={{
                height: 35,
                width: 35,
                mr: 1,
                color: "var(--secondary-color)",
              }}
            />
            <TextField
              label={t("SEARCH")}
              value={search}
              onChange={handleSearchChange}
              size="small"
              sx={[focusColor, { width: "75%" }]}
            />
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item>
                <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                  <Select
                    value={entityFilter}
                    onChange={(e) => setEntityFilter(e.target.value)}
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    <MenuItem value="all">{t("ALL_ENTITIES")}</MenuItem>
                    {entitiesItems}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                  <Select
                    value={cityFilter}
                    onChange={(e) => setCityFilter(e.target.value)}
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    <MenuItem value="all">{t("ALLS_CITIES")}</MenuItem>
                    {menuItems}
                  </Select>
                </FormControl>
              </Grid>
              {isSuperAdmin && (
                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => navigate("/premises/validation")}
                    className="oniria-btn"
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    {t("PREMISES_VALIDATION")}
                  </Button>
                </Grid>
              )}
              <Grid item>
                {isDelegate !== "delegate" && (
                  <Button
                    type="submit"
                    onClick={() => navigate("/create_premise")}
                    className="oniria-btn"
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    {t("ADD_PREMISE")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mb: 20,
        }}
      >
        {search === "" && cityFilter === "all" && entityFilter === "all"
          ? premises &&
            premises.map((premise, i) => (
              <CardPremise refetch={refetch} premise={premise} key={i} />
            ))
          : allFiltered.map((premise, i) => (
              <CardPremise refetch={refetch} premise={premise} key={i} />
            ))}
      </Grid>
    </>
  );
};

export default Premises;
