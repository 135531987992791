import { Badge, Grid, IconButton, List, ListItem, ListItemText, Popover, styled } from '@mui/material';
import React, { useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 32,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const Notifications = () => {

  const [anchorEl, setAnchorEl] = useState(null);

  function handleNotificationsOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleNotificationsClose() {
    setAnchorEl(null);
  }


  return (
    <>
      <Grid item xs={11} sm={11} sx={{ mr: 2, mt: 2, float: "right" }}>
        <IconButton color="inherit" onClick={handleNotificationsOpen}>
          <StyledBadge badgeContent={3} color="warning">
            <NotificationsIcon fontSize='large' sx={{ color: "var(--secondary-color)" }} />
          </StyledBadge>
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary="Notificación 1" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Notificación 2" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Notificación 3" />
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </>
  )
}

export default Notifications