import { Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AddCategoryDialog from "./AddCategoryDialog";

export const AddCategory = ({ allCategories, categories, handleAddCategory }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openAddCategory, setOpenAddCategory] = useState(false);

  const firstLetterToUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleOpenAddCategory = (item) => {
    setName(firstLetterToUpperCase(item.name));
    setSelectedCategory(item);
    setOpenAddCategory(true);
  };

  const handleAcceptDialog = () => {
    setOpenAddCategory(false);
    handleAddCategory(selectedCategory.id, selectedCategory.name, name);
  };

  return (
    <Grid container>
      <Typography variant="h5" mb={2} sx={{ color: "var(--secondary-color)", width: "100%" }}>
        {t("ADD_CATEGORY")}
      </Typography>
      <Typography variant="h7" mb={2} sx={{ color: "var(--secondary-color)" }}>
        {t("ADD_CATEGORY_EXPLANATION")}
      </Typography>
      <Grid container alignItems="center" gap={2}>
        {allCategories.length > 0 &&
          allCategories
            .filter((item) => !categories.some((category) => category.category_id === item.id))
            .map((item, index) => (
              <Grid key={index} container alignItems="center" gap={2}>
                {item.name}{" "}
                <IconButton
                  onClick={() => handleOpenAddCategory(item)}
                  sx={{
                    color: "var(--secondary-color)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            ))}
      </Grid>
      <AddCategoryDialog
        name={name}
        setName={setName}
        isOpen={openAddCategory}
        onClose={() => setOpenAddCategory(false)}
        onAccept={handleAcceptDialog}
        title={t("ADD_CATEGORY")}
        text={t("ADD_CATEGORY_NEW_NAME")}
      />
    </Grid>
  );
};
