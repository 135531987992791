import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import ButtonsForms from "../account/forms/ButtonsForms";
import { allFieldsOk, checkField, handleImageSelect } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { focusColor } from "../shared/textFieldStyle";
import { getPremises } from "../../services/premisesServices";
import DoublePaymentInfo from "./DoublePaymentInfo";
import { getCategoryPremise } from "../../services/categoriesServices";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Font from '@ckeditor/ckeditor5-font/src/font';

const Details = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;
  const { details: trip, event_type: eventType } = formController.formData;
  const { formParams, setFormErrors } = formController;
  const { details: detailsErrors } = formController.validation;
  const setFormParamsNotEvent = formController.setFormParamsNotEvent;
  const editEvent = props.editEvent;
  const [premises, setPremises] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [premiseFilter, setPremiseFilter] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [eventTypeFilter, setEventTypeFilter] = useState(trip.event_type);

  const typeTrip = props.typeTrip;
  // to set actual date and time when create event
  var offset = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.now() - offset);

  const now = localISOTime;
  const [hours, minutes] = now.toISOString().split("T")[1].split(":");
  const time = `${hours}:${minutes}`;
  if (trip.start_sale_date == "") {
    trip.start_sale_date = now.toISOString().split("T")[0];
    trip.start_sale_date_time = time;
    trip.publication_date = now.toISOString().split("T")[0];
    trip.publication_date_time = time;
    setFormErrors("details", "start_sale_date", "");
    setFormErrors("details", "start_sale_date_time", "");
    setFormErrors("details", "publication_date", "");
    setFormErrors("details", "publication_date_time", "");
  }

  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  // console.log("DATA de trip", form)
  const handleQuerySuccess = (data) => {
    setPremises(data);
  };

  useQuery("premises", () => getPremises(), { onSuccess: handleQuerySuccess });
  const { refetch: refetchCategories } = useQuery(
    ["eventTypes", premiseFilter],
    () => getCategoryPremise(premiseFilter),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        setEventTypes(data);
      },
    }
  );

  useEffect(() => {
    if (premiseFilter !== "") {
      refetchCategories();
    }
  }, [premiseFilter]);

  const menuItems = premises.map((premise) => (
    <MenuItem key={premise.premise_id} value={premise.premise_id}>
      {premise.premise_name}
    </MenuItem>
  ));
  const handleChangeFilterPremise = (event) => {
    setPremiseFilter(event.target.value);
    checkField(
      "details",
      "premise_id",
      event,
      /.*/,
      t("CHOOSE_OPTION"),
      false,
      formParams,
      setFormErrors
    );
  };

  const handleChangeEventType = (event) => {
    setEventTypeFilter(event.target.value);
    checkField(
      "details",
      "event_type",
      event,
      /.*/,
      t("CHOOSE_OPTION"),
      false,
      formParams,
      setFormErrors
    );
  };
  const handleChangeSubcategory = (event) => {
    checkField(
      "details",
      "event_tag",
      event,
      /.*/,
      t("CHOOSE_OPTION"),
      true,
      formParams,
      setFormErrors
    );
  };

  const haveSubcategory = () => {
    if (eventTypeFilter !== "") {
      const eventOption = eventTypes.find((option) => option.category_name === eventTypeFilter);
      if (eventOption && eventOption.subcategories && eventOption.subcategories.length > 0) {
        return eventOption.subcategories;
      }
    }
    return null;
  };

  const checkDateField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors
  ) => {
    //
    let errorValue = "";
    //
    const now = new Date();
    const dateToCheck = new Date(e.target.value);
    //console.log("Checking date field: ", key, now, dateToCheck)
    //
    if (dateToCheck.getDay < now.getDay) {
      errorValue = t("PREVIOUS_DATE_ERROR");
    }
    // Checkeos propios de fechas
    switch (key) {
      case "end_date":
        const startDate = new Date(trip.start_date);
        if (dateToCheck < startDate) errorValue = t("DATE_START_ERROR");
        if (trip.end_sale_date == "") {
          trip.end_sale_date = dateToCheck.toISOString().split("T")[0];
          trip.end_of_publication_date = dateToCheck.toISOString().split("T")[0];
          setErrors(classForm, "end_of_publication_date", errorValue);
          setErrors(classForm, "end_sale_date", errorValue);
        }
        break;
      case "end_sale_date":
        const endDate1 = new Date(trip.end_date);
        const startSaleDate = new Date(trip.start_sale_date);
        if (dateToCheck > endDate1) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startSaleDate) errorValue = t("DATE_SALE_ERROR");
        break;
      case "end_of_publication_date":
        const endDate2 = new Date(trip.end_date);
        const startPublicationDate = new Date(trip.publication_date);
        if (dateToCheck > endDate2) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startPublicationDate) errorValue = t("PUBLICATION_DATE_ERROR");
        break;
      case "second_payment_start_date":
        const startSaleDates = new Date(trip.start_sale_date);
        if (dateToCheck < startSaleDates) errorValue = t("SECOND_DATE_START_ERROR");
        break;
      case "second_payment_end_date":
        const startSecondDate = new Date(trip.second_payment_start_date);
        if (dateToCheck < startSecondDate) errorValue = t("SECOND_DATE_END_ERROR");
        break;
    }
    // Checkeos normales de campo
    //checkField(classForm, key, e, regex, errorMessage, canBeEmpty, formParams, setErrors);
    setErrors(classForm, key, errorValue);
    formParams(classForm, key, e);
  };

  //check hour and put in end_sale_date_time and end_of_publication_date_time automatically
  const checkHourField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors,
    parameter = "value"
  ) => {
    let errorValue = "";
    const newValue = e.target[parameter];
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    setErrors(classForm, key, errorValue);
    if (trip.end_sale_date_time == "") {
      trip.end_sale_date_time = newValue;
      trip.end_of_publication_date_time = newValue;
      setErrors(classForm, "end_of_publication_date_time", errorValue);
      setErrors(classForm, "end_sale_date_time", errorValue);
    }
    formParams(classForm, key, e, parameter);
  };

  const handleIncrement = () => {
    trip.min_age = trip.min_age + 1;
  };

  const handleDecrement = () => {
    trip.min_age > 0 ? (trip.min_age = trip.min_age - 1) : (trip.min_age = 0);
  };

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };

  //Set premise_name when edit event
  useEffect(() => {
    if (trip.premise_id !== "" && premises.length > 0) {
      setPremiseFilter(trip.premise_id);
    }
  }, [trip.premise_id, premises]);

  //check si la sede seleccionada es internacional
  const isInternational = () => {
    const premiseFound = premises.find((premise) => premise.premise_id === premiseFilter);
    return premiseFound ? premiseFound.is_active === 1 : false;
  };

  const handleChangeDescription = (data) => {
    if (!/^.[\s\S]{0,5000}$/.test(data)) {
      setFormErrors("details", "description", t("MAX_5000"));
    } else {
      formController.formData.details.description = data;
      setFormErrors("details", "description", "");
    }
    //const e = { target: { value: data } };
    // checkField(
    //   "details",
    //   "description",
    //   e,
    //   /^.[\s\S]{0,1000}$/,
    //   t("MAX_1000"),
    //   false,
    //   formParams,
    //   setFormErrors
    // );
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item xs={11}>
                <TextField
                  onChange={(e) =>
                    checkField(
                      "details",
                      "name",
                      e,
                      /^.{1,50}$/,
                      t("MAX_50"),
                      false,
                      formParams,
                      setFormErrors
                    )
                  }
                  margin="normal"
                  required
                  fullWidth
                  value={trip.name}
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  label={typeTrip ? t("TRIP_NAME") : t("EVENT_NAME")}
                  error={
                    (detailsErrors.name !== "" && detailsErrors.name !== "empty") ||
                    (detailsErrors.name === "empty" && buttonClicked)
                  }
                  helperText={detailsErrors.name !== "empty" ? detailsErrors.name : ""}
                  disabled={isDisabled()}
                  sx={[focusColor, { mb: 2 }]}
                />
                <FormControl
                  fullWidth
                  sx={[focusColor, { mb: 2 }]}
                  error={
                    (detailsErrors.premise_id === "empty" && buttonClicked) ||
                    (detailsErrors.premise_id !== "" && detailsErrors.premise_id !== "empty")
                  }
                >
                  <InputLabel id="demo-select-small-label">{t("SITE")}</InputLabel>
                  <Select
                    value={premiseFilter}
                    onChange={handleChangeFilterPremise}
                    sx={{ fontSize: "16px" }}
                    label={t("SITE")}
                  >
                    {menuItems}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={focusColor}
                  error={
                    (detailsErrors.event_type === "empty" && buttonClicked) ||
                    (detailsErrors.event_type !== "" && detailsErrors.event_type !== "empty")
                  }
                >
                  <InputLabel id="demo-select-small-label">{t("EVENT_TYPE")}</InputLabel>
                  <Select
                    value={trip.event_type}
                    onChange={handleChangeEventType}
                    sx={{ fontSize: "16px" }}
                    label={t("EVENT_TYPE")}
                  >
                    {eventTypes?.map((option) => (
                      <MenuItem key={option.category_id} value={option.category_name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {haveSubcategory() && (
                  <FormControl
                    fullWidth
                    sx={[focusColor, { mt: 2 }]}
                    error={
                      (detailsErrors.event_tag === "empty" && buttonClicked) ||
                      (detailsErrors.event_tag !== "" && detailsErrors.event_tag !== "empty")
                    }
                  >
                    <InputLabel id="demo-select-small-label">{t("SUBCATEGORIES")}</InputLabel>
                    <Select
                      value={trip.event_tag}
                      onChange={handleChangeSubcategory}
                      sx={{ fontSize: "16px" }}
                      label={t("SUBCATEGORIES")}
                    >
                      <MenuItem value="">{t("SUBCATEGORIES")}</MenuItem>
                      {haveSubcategory()?.map((subcategory, index) => (
                        <MenuItem key={index} value={subcategory.name}>
                          {subcategory.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <TextField
                  onChange={(e) =>
                    checkField(
                      "details",
                      "subtitle",
                      e,
                      /^.{1,50}$/,
                      t("MAX_50"),
                      false,
                      formParams,
                      setFormErrors
                    )
                  }
                  value={trip.subtitle}
                  margin="normal"
                  fullWidth
                  id="subtitle"
                  name="subtitle"
                  label={t("SUBTITLE")}
                  autoComplete="subtitle"
                  error={detailsErrors.subtitle !== ""}
                  helperText={detailsErrors.subtitle}
                  disabled={isDisabled()}
                  sx={focusColor}
                />
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {typeTrip ? t("DESCRIPTION_TRIP") : t("DESCRIPTION_EVENT")}
                  </InputLabel>
                  {/* EDITOR */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={trip.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChangeDescription(data);
                    }}
                    config={{
                      removePlugins: [
                        "CKFinderUploadAdapter",
                        "CKFinder",
                        "EasyImage",
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                  />
                  {trip.description.length > 5000 ? (
                    <p style={{ color: "red" }}>{t("MAX_5000")}</p>
                  ) : (
                    ""
                  )}
                  {/* <TextField
                    onChange={(e) =>{
                      console.log('EVENTO 2º TEXTF=>',e)
                      checkField(
                        "details",
                        "description",
                        e,
                        /^.[\s\S]{0,1000}$/,
                        t("MAX_1000"),
                        false,
                        formParams,
                        setFormErrors
                      )}
                    }
                    value={trip.description}
                    defaultValue={trip.description}
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    fullWidth
                    id="description"
                    name="description"
                    autoComplete="description"
                    error={
                      (detailsErrors.description !== "" &&
                        detailsErrors.description !== "empty") ||
                      (detailsErrors.description === "empty" && buttonClicked)
                    }
                    helperText={
                      detailsErrors.description !== "empty"
                        ? detailsErrors.description
                        : ""
                    }
                    disabled={isDisabled()}
                    sx={focusColor}
                  />  */}
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1, pb: 1 }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {typeTrip ? t("TRIP_PRINCIPAL_IMAGE") : t("EVENT_PRINCIPAL_IMAGE")}
                        <Tooltip
                          title={typeTrip ? t("MAIN_TRIP_IMAGE") : t("MAIN_EVENT_IMAGE")}
                          sx={{ color: "black" }}
                        >
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <ImageUpload
                      url={trip.principal_url}
                      selectedImagesInForm={trip.principal_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "principal_url",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      error={detailsErrors.principal_url !== "" && buttonClicked}
                      editEvent={editEvent}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        paddingBottom: 2,
                        ml: 2,
                      }}
                    >
                      {typeTrip ? t("TRIP_IMAGES") : t("EVENT_IMAGES")}
                    </InputLabel>
                    <ImageUpload
                      url={trip.slider_urls}
                      selectedImagesInForm={trip.slider_urls}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "slider_urls",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      multi={typeTrip ? true : false}
                      editEvent={editEvent}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("LINK_VIDEO")}
                    <Tooltip title={t("INFO_VIDEO")} sx={{ color: "black" }}>
                      <IconButton sx={{ height: 25, width: 35 }}>
                        <InfoIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "video_url",
                        e,
                        /^(https?):\/\/[^\s/$.?#].[^\s]*$/,
                        t("VALID_URL"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.video_url}
                    margin="normal"
                    fullWidth
                    id="video_url"
                    name="video_url"
                    label={t("LINK_VIDEO")}
                    autoComplete="video_url"
                    error={detailsErrors.video_url !== ""}
                    helperText={detailsErrors.video_url !== "empty" ? detailsErrors.video_url : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("LOCATION_ADDRESS")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "location",
                        e,
                        /^(?!.*[|\\#·$~%&¬()^[\]+*{}_]).{1,250}$/,
                        t("MAX_250"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.location}
                    margin="normal"
                    required
                    fullWidth
                    id="location"
                    name="location"
                    label={t("LOCATION")}
                    autoComplete="location"
                    error={
                      (detailsErrors.location !== "" && detailsErrors.location !== "empty") ||
                      (detailsErrors.location === "empty" && buttonClicked)
                    }
                    helperText={detailsErrors.location !== "empty" ? detailsErrors.location : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                <Grid container columnSpacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "latitude",
                          e,
                          /^\d+(?:\.\d+)?$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="number"
                      value={trip.latitude}
                      margin="normal"
                      fullWidth
                      id="latitude"
                      name="latitude"
                      label={t("LATITUDE")}
                      autoComplete="latitude"
                      error={detailsErrors.latitude !== "" && detailsErrors.latitude !== "empty"}
                      helperText={detailsErrors.latitude !== "empty" ? detailsErrors.latitude : ""}
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "longitude",
                          e,
                          /^\d+(?:\.\d+)?$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="number"
                      value={trip.longitude}
                      margin="normal"
                      fullWidth
                      id="longitude"
                      name="longitude"
                      label={t("LONGITUDE")}
                      autoComplete="longitude"
                      error={detailsErrors.longitude !== "" && detailsErrors.longitude !== "empty"}
                      helperText={
                        detailsErrors.longitude !== "empty" ? detailsErrors.longitude : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("START_DATE")}
                        <Tooltip title={t("INFO_START_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "start_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="start_date"
                      name="start_date"
                      value={trip.start_date}
                      autoComplete="start_date"
                      error={
                        (detailsErrors.start_date !== "" && detailsErrors.start_date !== "empty") ||
                        (detailsErrors.start_date === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.start_date !== "empty" ? detailsErrors.start_date : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("START_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "start_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="start_date_time"
                      name="start_date_time"
                      value={trip.start_date_time}
                      autoComplete="start_date_time"
                      error={
                        (detailsErrors.start_date_time !== "" &&
                          detailsErrors.start_date_time !== "empty") ||
                        (detailsErrors.start_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.start_date_time !== "empty"
                          ? detailsErrors.start_date_time
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("END_DATE")}
                        <Tooltip title={t("INFO_END_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "end_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="end_date"
                      name="end_date"
                      value={trip.end_date}
                      autoComplete="end_date"
                      error={
                        (detailsErrors.end_date !== "" && detailsErrors.end_date !== "empty") ||
                        (detailsErrors.end_date === "empty" && buttonClicked)
                      }
                      helperText={detailsErrors.end_date !== "empty" ? detailsErrors.end_date : ""}
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("END_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkHourField(
                          "details",
                          "end_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="end_date_time"
                      name="end_date_time"
                      value={trip.end_date_time}
                      autoComplete="end_date_time"
                      error={
                        (detailsErrors.end_date_time !== "" &&
                          detailsErrors.end_date_time !== "empty") ||
                        (detailsErrors.end_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.end_date_time !== "empty" ? detailsErrors.end_date_time : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("START_SALE_DATE")}
                        <Tooltip title={t("INFO_START_SALE_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "start_sale_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="start_sale_date"
                      name="start_sale_date"
                      value={trip.start_sale_date}
                      autoComplete="start_sale_date"
                      error={
                        (detailsErrors.start_sale_date !== "" &&
                          detailsErrors.start_sale_date !== "empty") ||
                        (detailsErrors.start_sale_date === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.start_sale_date !== "empty"
                          ? detailsErrors.start_sale_date
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("START_SALE_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "start_sale_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="start_sale_date_time"
                      name="start_sale_date_time"
                      value={trip.start_sale_date_time}
                      autoComplete="start_sale_date_time"
                      error={
                        (detailsErrors.start_sale_date_time !== "" &&
                          detailsErrors.start_sale_date_time !== "empty") ||
                        (detailsErrors.start_sale_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.start_sale_date_time !== "empty"
                          ? detailsErrors.start_sale_date_time
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("END_SALE_DATE")}
                        <Tooltip title={t("INFO_END_SALE_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "end_sale_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="end_sale_date"
                      name="end_sale_date"
                      value={trip.end_sale_date}
                      autoComplete="end_sale_date"
                      error={
                        (detailsErrors.end_sale_date !== "" &&
                          detailsErrors.end_sale_date !== "empty") ||
                        (detailsErrors.end_sale_date === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.end_sale_date !== "empty" ? detailsErrors.end_sale_date : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("END_SALE_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "end_sale_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="end_sale_date_time"
                      name="end_sale_date_time"
                      value={trip.end_sale_date_time}
                      autoComplete="end_sale_date_time"
                      error={
                        (detailsErrors.end_sale_date_time !== "" &&
                          detailsErrors.end_sale_date_time !== "empty") ||
                        (detailsErrors.end_sale_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.end_sale_date_time !== "empty"
                          ? detailsErrors.end_sale_date_time
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("PUBLICATION_DATE")}
                        <Tooltip title={t("INFO_PUBLICATION_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "publication_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="publication_date"
                      name="publication_date"
                      value={trip.publication_date}
                      autoComplete="publication_date"
                      error={
                        (detailsErrors.publication_date !== "" &&
                          detailsErrors.publication_date !== "empty") ||
                        (detailsErrors.publication_date === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.publication_date !== "empty"
                          ? detailsErrors.publication_date
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("PUBLICATION_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "publication_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="publication_date_time"
                      name="publication_date_time"
                      value={trip.publication_date_time}
                      autoComplete="publication_date_time"
                      error={
                        (detailsErrors.publication_date_time !== "" &&
                          detailsErrors.publication_date_time !== "empty") ||
                        (detailsErrors.publication_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.publication_date_time !== "empty"
                          ? detailsErrors.publication_date_time
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {t("END_OF_PUBLICATION_DATE")}
                        <Tooltip title={t("END_OF_INFO_PUBLICATION_DATE")} sx={{ color: "black" }}>
                          <IconButton sx={{ height: 25, width: 35 }}>
                            <InfoIcon sx={{ color: "black" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkDateField(
                          "details",
                          "end_of_publication_date",
                          e,
                          /^(\d{4})-(\d{2})-(\d{2})$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="date"
                      margin="normal"
                      required
                      fullWidth
                      id="end_of_publication_date"
                      name="end_of_publication_date"
                      value={trip.end_of_publication_date}
                      autoComplete="end_of_publication_date"
                      error={
                        (detailsErrors.end_of_publication_date !== "" &&
                          detailsErrors.end_of_publication_date !== "empty") ||
                        (detailsErrors.end_of_publication_date === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.end_of_publication_date !== "empty"
                          ? detailsErrors.end_of_publication_date
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "2100-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                      {t("END_OF_PUBLICATION_TIME")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "end_of_publication_date_time",
                          e,
                          /^([01]\d|2[0-3]):([0-5]\d)$/,
                          t("CHOOSE_OPTION"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      type="time"
                      margin="normal"
                      required
                      fullWidth
                      id="end_of_publication_date_time"
                      name="end_of_publication_date_time"
                      value={trip.end_of_publication_date_time}
                      autoComplete="end_of_publication_date_time"
                      error={
                        (detailsErrors.end_of_publication_date_time !== "" &&
                          detailsErrors.end_of_publication_date_time !== "empty") ||
                        (detailsErrors.end_of_publication_date_time === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.end_of_publication_date_time !== "empty"
                          ? detailsErrors.end_of_publication_date_time
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2, mt: 1.8 }}>
                      {t("MIN_AGE")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "min_age",
                          e,
                          /^(?:100|[1-9][0-9]?|0)$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.min_age}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="min_age"
                      name="min_age"
                      label={t("AGE")}
                      autoComplete="min_age"
                      error={
                        (detailsErrors.min_age !== "" && detailsErrors.min_age !== "empty") ||
                        (detailsErrors.min_age === "empty" && buttonClicked)
                      }
                      helperText={detailsErrors.min_age !== "empty" ? detailsErrors.min_age : ""}
                      disabled={isDisabled()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleIncrement} edge="end" />
                            <IconButton onClick={handleDecrement} edge="end" />
                          </InputAdornment>
                        ),
                      }}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} >
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2, mt: 1.8 }}>
                      {t("TOTAL_CAPACITY_TRIP")}
                    </InputLabel>
                    <TextField
                      onChange={e => checkField('details', 'total_capacity', e, /^[1-9]\d*$/, t("INCORRECT"), false, formParams, setFormErrors)}
                      value={trip.total_capacity}
                      margin="normal"
                      type='number'
                      required
                      fullWidth
                      id="total_capacity"
                      name="total_capacity"
                      label={t("TOTAL_CAPACITY")}
                      autoComplete="total_capacity"
                      error={detailsErrors.total_capacity !== "" && detailsErrors.total_capacity !== "empty"}
                      helperText={detailsErrors.total_capacity !== 'empty' ? detailsErrors.total_capacity : ''}
                      disabled={isDisabled()}
                      sx={focusColor}
                    />
                  </Grid> */}
                  {/* <Grid item container columnSpacing={2} sx={{ mt: 2 }}> */}
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        mb: 2,
                        mt: 1.8,
                        whiteSpace: "unset",
                      }}
                    >
                      {t("LIMIT_TICKETS_ORDER")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "limit_tickets_order",
                          e,
                          /^[1-9]\d*$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.limit_tickets_order}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="limit_tickets_order"
                      name="limit_tickets_order"
                      label={t("LIMIT_TICKETS_ORDER")}
                      autoComplete="limit_tickets_order"
                      error={
                        (detailsErrors.limit_tickets_order !== "" &&
                          detailsErrors.limit_tickets_order !== "empty") ||
                        (detailsErrors.limit_tickets_order === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.limit_tickets_order !== "empty"
                          ? detailsErrors.limit_tickets_order
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    sx={{
                      fontSize: "18px",
                      color: "black",
                      mb: 2,
                      mt: 1.8,
                      whiteSpace: "unset",
                    }}
                  >
                    {t("OUTFIT")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "outfit",
                        e,
                        /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{0,250}$/s,
                        t("MAX_250"),
                        true,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.outfit}
                    margin="normal"
                    multiline
                    rows={2}
                    fullWidth
                    id="outfit"
                    name="outfit"
                    label={t("OUTFIT")}
                    autoComplete="outfit"
                    error={
                      (detailsErrors.outfit !== "" && detailsErrors.outfit !== "empty") ||
                      (detailsErrors.outfit === "empty" && buttonClicked)
                    }
                    helperText={detailsErrors.outfit !== "empty" ? detailsErrors.outfit : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_visible"
                        checked={trip.is_visible}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "is_visible",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.is_visible}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={typeTrip ? t("VISIBLE_TRIP") : t("VISIBLE_EVENT")}
                  />
                  <Tooltip title={t("VISIBLE_EVENT_INFO")} sx={{ color: "black" }}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="autovalidation"
                        checked={trip.autovalidation}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "autovalidation",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.autovalidation}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={t("SELFT_VALIDATION")}
                  />
                  <Tooltip title={t("SELFT_VALIDATION_INFO")} sx={{ color: "black" }}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {isInternational() && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_international"
                          checked={trip.is_international}
                          onChange={(e) =>
                            checkField(
                              "details",
                              "is_international",
                              e,
                              /^(true|false)$/,
                              t("CHOOSE_OPTION"),
                              true,
                              formParams,
                              setFormErrors,
                              "checked"
                            )
                          }
                          disabled={isDisabled()}
                          value={trip.is_international}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("IS_INTERNATIONAL")}
                    />
                    <Tooltip title={t("IS_INTERNATIONAL_INFO")} sx={{ color: "black" }}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="has_double_payment"
                        checked={trip.has_double_payment}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "has_double_payment",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.has_double_payment}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": { color: "var(--secondary-color)" },
                        }}
                      />
                    }
                    label={t("IS_DOUBLE_PAYMENT")}
                  />
                  <Typography>*{t("IS_DOUBLE_PAYMENT_INFO")}</Typography>
                </Grid>
                {trip.has_double_payment && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <DoublePaymentInfo
                      isDisabled={isDisabled}
                      data={trip}
                      checkField={checkField}
                      checkDateField={checkDateField}
                      formParams={formParams}
                      setFormErrors={setFormErrors}
                      detailsErrors={detailsErrors}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          {!allFieldsOk(detailsErrors) && buttonClicked && (
            <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
          {!isDisabled() && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allFieldsOk}
              errors={detailsErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Details;
