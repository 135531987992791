export const USER_STATUS = {
  PENDING_VALIDATE: "pending_validate",
  VALIDATE: "affiliated",
  NO_AFFILIATED: "no_affiliated",
  PENDING_PAYMENT: "pending_payment",
};

export const ADMIN_ROLE = {
  admin: "ead0b44b-4e96-4292-ae4d-ad68bc61f10b",
  delegate: "5abec0dd-3694-43fd-bfad-f9984e0fe0e1",
  affiliated: "e4a0c79d-b4f9-4948-b338-959e0abb2f88",
};

export const PAYMENT_OPTIONS = [
  "app_redsys",
  "app_paypal",
  "app_free",
  "transaction",
  "app_redsys_timeout",
  "app_paypal_timeout",
  "app_free_timeout",
];

export const EVENTS_TYPE = {
  EVENT: "event",
  TRIP: "trip",
  ACTIVITY: "activity",
};

export const getEventConfigurations = (t) => ({
  activity: {
    breadcrumbs: [{ name: t("ACTIVITIES"), link: "/events" }],
    description: t("HEADER_TEXT_ACTIVITIES"),
  },
  event: {
    breadcrumbs: [{ name: t("EVENTS"), link: "/events" }],
    description: t("Descripción para eventos"),
  },
});

export const ROLES = {
  ADMIN: "admin",
  DELEGATE: "delegate",
  AFFILIATED: "affiliated",
  SUPER_ADMIN: "super-admin",
  MANAGER_ADMIN: "manager-admin",
};
export const ZGZ_CENTER = [41.647451005201354, -0.8874286183474662];
