import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function uploadImage(body) {
  const { data } = await http.post(`${SERVICE.IMAGE}`, body);
  return data;
}
export async function getCnae() {
  const { data } = await http.get(`${SERVICE.CNAE}`);
  return data;
}
export async function getCountries() {
  const { data } = await http.get(`${SERVICE.COUNTRIES}`);
  return data;
}
export async function getRegions(code) {
  const { data } = await http.get(`${SERVICE.REGIONS}${code}`);
  return data;
}
export async function getHome() {
  const { data } = await http.get(`${SERVICE.HOME}`);
  return data;
}
export async function getBicFromIban(iban) {
  const { data } = await http.get(`https://openiban.com/validate/${iban}?getBIC=true`);
  return data;
}
export async function deleteUser(id) {
  const { data } = await http.delete(`${SERVICE.USER}${id}`);
  return data;
}

export async function getMonthStatistics(month) {
  const { data } = await http.get(`${SERVICE.STATISTICS}?month=${month}`);
  return data;
}
