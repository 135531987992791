import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { validateUserApp } from "../services/authenticationServices";
import { useSearchParams } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import { toastMessageError } from "../components/shared/toastMessage";

const ValidateApp = () => {
  const [searchParam] = useSearchParams();
  const [responseMessage, setResponseMessage] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const { t } = useTranslation();
  const id = searchParam.get("token");

  const handleValidateUser = async (e) => {
    e.preventDefault();
    try {
      let response = await validateUserApp(id);
      if (response == "success") {
        setResponseMessage(t("USER_VALIDATED"));
        setIsDisabled(true);
        setIsOk(true);
      } else {
        setResponseMessage(t("USER_NOT_VALIDATED"));
      }
    } catch (error) {
      setIsOk(false);
      console.log("Error handleValidateUser", error);
      toastMessageError(error.response?.data?.error ?? t("ERROR_VALIDATION"));
    }
  };

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: 9 }}
    >
      <Box sx={{ height: 300, mb: 2 }} component="img" alt="Logo" src={"/media/tardeo_logo.svg"} />
      <h1 style={{ textAlign: "center" }}>{responseMessage}</h1>
      {isOk && <CheckCircle sx={{ fontSize: 100 }} />}
      <Button
        className="oniria-btn"
        variant="contained"
        onClick={handleValidateUser}
        size="small"
        sx={{ mt: 5, mb: 25, fontSize: "18px", borderRadius: 3 }}
        disabled={isDisabled}
      >
        {t("VERIFY_USER")}
      </Button>
    </Grid>
  );
};
export default ValidateApp;
