import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, formatHours } from "../../constants/utils";
import { useTranslation } from "react-i18next";

const EventHomeCard = ({ event }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
      elevation={2}
      sx={{
        border: "1px solid #E4E4E4",
        width: "98%",
        borderRadius: "15px",
        mb: 2,
        flexShrink: 0,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent onClick={() => navigate(`/event/${event.id}`)} sx={{ cursor: "pointer" }}>
          <Grid container>
            <Grid item xs={4} container alignItems="center">
              <CardMedia
                component="img"
                sx={{ height: 130, maxWidth: 210, objectFit: "fill" }}
                image={event.principal_url}
                alt="Image"
              />
            </Grid>
            <Grid item xs={8} container justifyContent="center">
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                paddingX="1rem"
                alignItems="center"
              >
                <Typography
                  className="oniria-colorText"
                  variant="subtitle1"
                  sx={{ fontWeight: "bolder" }}
                >
                  {event.name}
                </Typography>
                <Typography variant="subtitle2">
                  {formatDate(event.start_date)} - {formatHours(event.start_date)}
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder" }}>
                  {t("TICKETS_SOLD")}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  {event.total_tickets_sold ? event.total_tickets_sold : 0}
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder" }}>
                  {t("TOTAL_SALES")}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  {event.total_sells ? event.total_sells : 0}€
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder" }}>
                  {t("TOTAL_CAPACITY")}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  {event.total_capacity ? event.total_capacity : 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
};
export default EventHomeCard;
