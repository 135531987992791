import { Box, Button, Grid, InputLabel, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { allFieldsOk, checkField } from '../shared/FormsValidator';
import ButtonsForms from '../account/forms/ButtonsForms';
import MultiSelect from '../shared/MultiSelect';
import Block from './trip/Block';
import { block, blockErrors } from '../../classes/tripClass';
import { focusColor } from '../shared/textFieldStyle';


const MoreInformation = (props) => {

  const { t } = useTranslation();
  const formController = props.formController;
  const { formData, formParams, setFormErrors } = formController;
  const { moreInformation: trip } = formController.formData;
  const { moreInformation: moreInformationErrors } = formController.validation
  const setFormParamsValue = formController.setFormParamsValue;
  const [buttonClicked, setButtonClicked] = useState(false)
  const  editEvent  = props.editEvent

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  }

  const setBlocksErrors = (updatedBlocksErrors) => {
    setFormErrors("moreInformation", "blocks", updatedBlocksErrors);
  };

  const addBlock = () => {
    // Función para agregar un nuevo bloque al estado
    const newBlock = JSON.parse(JSON.stringify(block));
    const newBlockErrors = JSON.parse(JSON.stringify(blockErrors));
    const blocksNewState = [...trip.blocks, newBlock];
    setBlocksErrors([...moreInformationErrors.blocks, newBlockErrors]);
    //Cambiado a setFormErrors y peta
    // setFormErrors(prev => ({
    //   ...prev,
    //   blocks: allBlocksOk() ? "" : "error"
    // }));

    setFormParamsValue('moreInformation', 'blocks', blocksNewState);

  };

  const removeBlock = (index) => {
    // Función para eliminar un bloque del estado
    const updatedBlocks = trip.blocks.filter((block, blockIndex) => blockIndex !== index);
    setFormParamsValue('moreInformation', 'blocks', updatedBlocks);
    //
    const updatedBlocksErrors = moreInformationErrors.blocks.filter((block, blockIndex) => blockIndex !== index);
    setBlocksErrors(updatedBlocksErrors);
    //
    // setFormErrors(prev => ({
    //   ...prev,
    //   blocks: allBlocksOk() ? "" : "error"
    // }));
  };

  const handleBlockChange = (index, key, e) => {
    const blocksUpdated = formData.moreInformation.blocks
    blocksUpdated[index][key] = e.target.value
    setFormParamsValue('moreInformation', 'blocks', blocksUpdated)
    //

    // setFormErrors(prev => ({
    //   ...prev,
    //   blocks: allBlocksOk() ? "" : "error"
    // }));
  };

  const handleBlockImageChange = (index, key, url) => {
    //
    let images;
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
          const urlParams = url[i].split(";")
          const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] }
          imageArray.push(imageObject)
      }
      images = imageArray;
    } else {
      const urlParams = url.split(";")
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] }
      images = [imageObject];
    }
    //
    const blocksUpdated = formData.moreInformation.blocks;
    blocksUpdated[index][key] = images;
    setFormParamsValue('moreInformation', 'blocks', blocksUpdated);
  };

  const allBlocksOk = () => {
    let allBlocksOk = true;
    moreInformationErrors.blocks.map((block) => {
      if (block.title !== "") allBlocksOk = false;
      if (block.subtitle !== "") allBlocksOk = false;
      if (block.description !== "") allBlocksOk = false;
    })
    //
    return allBlocksOk;
  };
  // to set next button form clicked 
  const catchButtonClicked = (value) =>{
    setButtonClicked(value)
  }

  return (
    <Grid container sx={{ mt: 5, mb: 20, px: 1, color: "black", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Grid item xs={11} md={8} >
        <Box component={Paper} elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px"
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("INCLUDING")}
                </InputLabel>
                <MultiSelect formController={formController} />
              </Grid>
              <Grid item xs={11} sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("NOT_INCLUDING")}
                </InputLabel>
                <TextField
                  //onChange={e => handleChangeFormParamsAndErrors('not_included_options', e)}
                  onChange={e => checkField('moreInformation', 'not_included_options', e, /^(?!.*[|\\#·$~¬=^[\]{}_]).{0,1000}$/s, t("MAX_1000"), true, formParams, setFormErrors)}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  value={trip.not_included_options}
                  id="not_included_options"
                  name="not_included_options"
                  autoComplete="not_included_options"
                  label={t("NOT_INCLUDING")}
                  disabled={isDisabled()}
                  error={moreInformationErrors.not_included_options !== ""}
                  helperText={moreInformationErrors.not_included_options}
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={11} sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("ADDITIONAL_INFO")}
                </InputLabel>
                <TextField
                  // onChange={e => handleChangeFormParamsAndErrors('additional_info', e)}
                  onChange={e => checkField('moreInformation', 'additional_info', e, /^(?!.*[|\\#·$~¬=^[\]{}_]).{0,1000}$/s, t("MAX_1000"), true, formParams, setFormErrors)}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  value={trip.additional_info}
                  id="additional_info"
                  name="additional_info"
                  autoComplete="additional_info"
                  label={t("ADDITIONAL_INFO")}
                  disabled={isDisabled()}
                  error={moreInformationErrors.additional_info !== ""}
                  helperText={moreInformationErrors.additional_info}
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("MORE_INFO")}
                </InputLabel>
                {trip.blocks.map((block, i) => (
                  block &&
                  <Block key={i} index={i} removeBlock={removeBlock} formController={formController} blocksErrors={moreInformationErrors.blocks}
                    setBlocksErrors={setBlocksErrors} handleBlockChange={handleBlockChange} buttonClicked={buttonClicked}
                    handleBlockImageChange={handleBlockImageChange} editEvent={editEvent}/>
                ))}
                <Button fullWidth variant='outlined' size='large' sx={{
                  color: "var(--secondary-color)",
                  borderColor: "var(--secondary-color)",
                  "&:hover": {
                    color: "var(--secondary-color)",
                    borderColor: "var(--secondary-color)",
                    backgroundColor: "white",
                  },
                }}
                  onClick={addBlock}>
                  {t("ADD_BLOCK")}
                </Button>
              </Grid>
            </Grid>

          </Box>
          {!isDisabled() ? <ButtonsForms formController={formController} allFieldsOk={allFieldsOk} errors={moreInformationErrors} sendButtonClicked={catchButtonClicked}/> : ""}
        </Box>
      </Grid>
    </Grid>
  )
}

export default MoreInformation