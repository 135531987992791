import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import ListBills from "../../components/bills/ListBills";
import {
  handleDownloadPdf,
  handleDownloadPdfBlob,
  handleDownloadSepa,
  mergePdfs,
} from "../../components/bills/functions/fileDownloads";
import OrderDetail from "../../components/events/OrderDetail";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { formatMuiDate } from "../../constants/utils";
import { EVENTS_TYPE, PAYMENT_OPTIONS } from "../../constants/variables";
import {
  getBillingPdfId,
  getBillingPdfInvoices,
  getBillingSepa,
  getBills,
  getManualOk,
} from "../../services/billingServices";
import { getAllEvents } from "../../services/eventsServices";
import { getPremises } from "../../services/premisesServices";

let perPage = "10";
let billsAmount = "";

const Billing = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const isAdmin = roles.find((element) => element == "admin");
  const breadcrumbs = [
    {
      name: t("SALES"),
      link: "/sales",
    },
  ];
  const [bills, setBills] = useState([]);
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([""]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [page, setPage] = useState(1);
  const [date, setDate] = useState({ from: "", to: "" });
  const [premises, setPremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState("");
  const [order, setOrder] = useState({ asc: [], desc: ["fechaEmision"] });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentManualOk, setCurrentManualOk] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [showDetailOrder, setShowDetailOrder] = useState(false);
  const [method, setMethod] = useState("");
  const [isSepaOpen, setSepaOpen] = useState(false);
  const [isPdfOpen, setPdfOpen] = useState(false);
  const [pdfType, setPdfType] = useState("");
  const [billType, setBillType] = useState("");

  //ver detalle del pedido
  const handleShowOrder = (id) => {
    setSelectedOrder(id);
    setShowDetailOrder(true);
  };

  //manejo de filtro fecha
  const handleChangeDate = (field, value) => {
    setPage(1);
    setDate((prevDate) => ({
      ...prevDate,
      [field]: value,
    }));
  };
  const today = new Date().toISOString().split("T")[0];
  //máximo 3 meses entre from y to
  const calculateMaxDate = (fromDate) => {
    const date = new Date(fromDate);
    date.setMonth(date.getMonth() + 3);
    return date.toISOString().split("T")[0];
  };
  //filtros bills
  const handleSortBills = (id, orderType) => {
    setOrder((prevOrder) => {
      const oppositeOrderType = orderType === "asc" ? "desc" : "asc";
      return {
        ...prevOrder,
        [orderType]: prevOrder[orderType].includes(id)
          ? prevOrder[orderType].filter((columnId) => columnId !== id)
          : [...prevOrder[orderType], id],
        [oppositeOrderType]: prevOrder[oppositeOrderType].filter((columnId) => columnId !== id),
      };
    });
  };

  //GET de facturas
  const handleQuerySuccess = (data) => {
    setBills(data.data);
    billsAmount = data.amount;
  };
  const {
    isLoading,
    refetch: refetchBills,
    isRefetching: refetchingBills,
  } = useQuery(
    ["bills", search, page, perPage, selectedPremise, selectedEvent, date, order, method, billType],
    () =>
      getBills(
        search,
        page,
        perPage,
        selectedPremise,
        selectedEvent,
        date,
        order,
        method,
        billType
      ),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  //llamada de eventos
  const handleQueryEvents = (data) => {
    setEvents(data);
  };
  const {} = useQuery(["allEvents", selectedPremise], () => getAllEvents(selectedPremise), {
    onSuccess: handleQueryEvents,
  });
  const handleChangeEvent = (e, value) => {
    setPage(1);
    setSelectedEvent(value?.id);
  };
  //FIN EVENTOS

  //llamada de sedes
  const handlePremiseSuccess = (data) => {
    setPremises(data);
    if (data.length == 1) {
      setSelectedPremise(data[0]?.premise_id);
    }
  };
  const {} = useQuery("allPremises", () => getPremises(), {
    onSuccess: handlePremiseSuccess,
  });
  const handleChangePremise = (e) => {
    setPage(1);
    setSelectedPremise(e.target.value);
    setSelectedEvent("");
  };
  const renderPremisesOptions = () => {
    return premises.map((premise, index) => (
      <MenuItem key={index} value={premise.premise_id}>
        {premise.premise_name}
      </MenuItem>
    ));
  };
  //FIN SEDES

  //manejar la búsqueda de facturas
  const handleSearchChange = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //descarga de facturas en xml por fecha, por evento y fecha
  const handleCloseSepa = () => {
    setSepaOpen(false);
  };
  const downloadXml = useMutation(
    () => getBillingSepa(selectedEvent, selectedPremise, date, billType),
    {
      onSuccess: (data) => {
        handleDownloadSepa(data);
        toastMessageSuccess(t("DOWNLOAD_START"));
      },
      onError: (error) => {
        toastMessageError(error.response.data.error ?? "Error");
      },
    }
  );
  const handleDownloadSepaId = () => {
    const hasSepaDownloaded = (bill) =>
      (bill.normal && !!bill.normal.sepa_download) ||
      (bill.commission && !!bill.commission.sepa_download);
    const found = bills.some(hasSepaDownloaded);
    if (found) {
      setSepaOpen(true);
    } else {
      downloadXml.mutate();
    }
  };
  const handleConfirmSepa = () => {
    downloadXml.mutate();
    setSepaOpen(false);
  };
  //Descarga lista de facturas en pdf
  const downloadBills = useMutation(
    (type) => getBillingPdfInvoices(selectedEvent, selectedPremise, date, type, order, billType),
    {
      onSuccess: async (data) => {
        const base64Pdfs = data.data.map((item) => item.pdfInvoice);
        const mergedBlob = await mergePdfs(base64Pdfs);
        let name = "facturas";
        name += date && date.from ? "_from_" + date.from : "_";
        name += date && date.to ? "_to_" + date.to : "_";
        name += ".pdf";
        handleDownloadPdfBlob(mergedBlob, name);
        toastMessageSuccess(t("DOWNLOAD_START"));
      },
      onError: (error) => {
        console.log("Error download bills", error);
        toastMessageError(error.response?.data?.error ?? "Error");
      },
    }
  );
  const handleDownloadListPdf = (type) => {
    setPdfType(type);
    //verificar si se ha descargado
    const hasPdfDownload = (bill) =>
      (bill.normal && !!bill.normal.pdf_download) ||
      (bill.commission && !!bill.commission.pdf_download);
    const found = bills.some(hasPdfDownload);
    if (found) {
      setPdfOpen(true);
    } else {
      downloadBills.mutate(type);
    }
  };
  const handleConfirmPdf = () => {
    downloadBills.mutate(pdfType);
    setPdfOpen(false);
  };

  const handleClosePdf = () => {
    setPdfOpen(false);
  };
  //FIN lista pdf

  //descarga de facturas individuales de la lista
  const handleDownload = (id) => {
    getBillingPdfId(id)
      .then((data) => {
        handleDownloadPdf(data);
        toastMessageSuccess(t("DOWNLOAD_START"));
        refetchBills();
      })
      .catch((error) => {
        toastMessageError(error.response.data.error ?? "Error");
      });
  };

  //START pago manual ok
  const manualOk = useMutation((id) => getManualOk(id), {
    onSuccess: (data) => {
      toastMessageSuccess(data.message ?? "All ok");
      refetchBills();
      handleCloseConfirm();
    },
    onError: (error) => {
      toastMessageError(error.response.data.error ?? "Error");
    },
  });
  const handleManualOk = (id) => {
    setCurrentManualOk(id);
    setOpenConfirm(true);
  };
  //POP UP confirm manual
  const handleCloseConfirm = () => {
    setCurrentManualOk("");
    setOpenConfirm(false);
  };
  const handleConfirmManualOk = () => {
    if (currentManualOk) {
      manualOk.mutate(currentManualOk);
    }
  };
  //FIN pago manual ok

  //Change page
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const numberPages = Math.ceil(billsAmount / perPage);

  //Ponemos la fecha de from 1 mes desde hoy
  useEffect(() => {
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    const fromDate = formatMuiDate(oneWeekAgo);
    setDate({ from: fromDate, to: "" });
  }, []);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_BILLING")} />
      {showDetailOrder ? (
        <OrderDetail
          order={selectedOrder.order_id}
          transactionId={selectedOrder?.transaction_id}
          billing={true}
          onBack={() => setShowDetailOrder(false)}
        />
      ) : (
        <Grid container justifyContent="space-between" rowGap={3} padding={3}>
          <Grid
            item
            xs={12}
            sx={{ borderRadius: 3 }}
            container
            component={Paper}
            padding={2}
            gap={2}
          >
            <Typography variant="h6">{t("BILLING_DOWNLOAD_OPTIONS")}</Typography>
            <Grid container justifyContent="space-between" rowGap={2}>
              <Grid item xs={12} sm={7} rowGap={2} container>
                {/* PREMISE OR PREMISES */}
                <FormControl sx={focusColor} fullWidth>
                  <InputLabel id="demo-simple-select-label">{t("PREMISES")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPremise}
                    label={t("PREMISE")}
                    onChange={handleChangePremise}
                  >
                    <MenuItem value="">{t("PREMISES")}</MenuItem>
                    {premises.length > 0 && renderPremisesOptions()}
                  </Select>
                </FormControl>
                {/* EVENTS */}
                <FormControl sx={focusColor} fullWidth>
                  <Autocomplete
                    id="auto-complete"
                    options={events}
                    getOptionLabel={(option) => option.name || ""}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={t("EVENTS")} variant="outlined" />
                    )}
                    onChange={handleChangeEvent}
                    value={events.find((event) => event.id === selectedEvent) || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </FormControl>
                {/* {t('PAYMENT_METHOD')} */}
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel id="demo-simple-select-label">{t("PAYMENT_METHOD")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("PAYMENT_METHOD")}
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                  >
                    <MenuItem value={t("")}>{t("ALL")}</MenuItem>
                    {PAYMENT_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {t(option.toUpperCase())}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* BILL TYPE FILTER */}
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel id="demo-simple-select-label">{t("BILLING_TYPE")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("BILLING_TYPE")}
                    value={billType}
                    onChange={(e) => setBillType(e.target.value)}
                  >
                    <MenuItem value={t("")}>{t("ALL")}</MenuItem>
                    <MenuItem value={EVENTS_TYPE.EVENT}>{t("EVENTS")}</MenuItem>
                    <MenuItem value={EVENTS_TYPE.TRIP}>{t("TRAVELS")}</MenuItem>
                    <MenuItem value={EVENTS_TYPE.ACTIVITY}>{t("ACTIVITIES")}</MenuItem>
                    <MenuItem value={"card"}>{t("MEMBER_CARD")}</MenuItem>
                  </Select>
                </FormControl>
                {/* DATE FILTER */}
                <FormControl sx={[focusColor, { gap: 2 }]} fullWidth>
                  <Grid container alignItems="center" justifyContent="space-between" rowGap={2}>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      xs={12}
                      lg={5}
                    >
                      <Typography>{t("FROM")}</Typography>
                      <TextField
                        type="date"
                        value={date.from}
                        onChange={(e) => handleChangeDate("from", e.target.value)}
                        sx={[focusColor, {}]}
                        inputProps={{
                          max: date.to ? calculateMaxDate(date.to) : today,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      xs={12}
                      lg={5}
                    >
                      <Typography>{t("UNTIL")}</Typography>
                      <TextField
                        type="date"
                        value={date.to}
                        onChange={(e) => handleChangeDate("to", e.target.value)}
                        sx={[focusColor, {}]}
                        inputProps={{
                          min: date.from,
                          max: date.from ? calculateMaxDate(date.from) : today,
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                container
                justifyContent="start"
                rowGap={2}
                height="fit-content"
                alignSelf="center"
              >
                {(isSuperAdmin || isManager || isAdmin) && (
                  <>
                    <Grid item xs={12} sx={{ height: "fit-content" }} container gap={2}>
                      <Button
                        onClick={handleDownloadSepaId}
                        className="oniria-btn"
                        variant="contained"
                        size="medium"
                        fullWidth
                        sx={{
                          fontSize: "12px",
                          borderRadius: 3,
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <CloudDownloadIcon />
                        {t("DOWNLOAD_SEPA_XML")}
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sx={{ height: "fit-content" }}>
                  <Button
                    className="oniria-btn"
                    variant="contained"
                    size="medium"
                    fullWidth
                    sx={{
                      fontSize: "12px",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={() => handleDownloadListPdf("normal")}
                  >
                    <CloudDownloadIcon />
                    {t("DOWNLOAD_BILLS")}
                  </Button>
                </Grid>
                {(isSuperAdmin || isManager) && (
                  <Grid item xs={12} sx={{ height: "fit-content" }}>
                    <Button
                      className="oniria-btn"
                      variant="contained"
                      size="medium"
                      fullWidth
                      sx={{
                        fontSize: "12px",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                      onClick={() => handleDownloadListPdf("comision")}
                    >
                      <CloudDownloadIcon />
                      {t("DOWNLOAD_COMMISSION")}
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  sx={{ height: "fit-content", flexWrap: "nowrap" }}
                >
                  <SearchIcon
                    sx={{
                      height: 35,
                      width: 35,
                      mr: 1,
                      color: "var(--secondary-color)",
                    }}
                  />
                  <TextField
                    label={t("SEARCH")}
                    value={search}
                    onChange={handleSearchChange}
                    size="small"
                    sx={[focusColor, { flexGrow: "1" }]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            {/* LISTA DE PEDIDOS */}
            {isLoading ? (
              <Loading />
            ) : bills?.length > 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={{ xs: "center", sm: "space-between" }}
                  alignItems={"center"}
                  rowGap={2}
                >
                  <Typography>
                    {billsAmount} {t("LAST_MOVEMENTS")}
                  </Typography>
                  {numberPages > 1 && (
                    <Pagination
                      count={numberPages}
                      mr={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bolder",
                        "& .MuiPaginationItem-root": {
                          "&.Mui-selected": {
                            background: "#169a89",
                            color: "#fff",
                            fontWeight: "bolder",
                          },
                          fontWeight: "600",
                        },
                      }}
                      page={page}
                      onChange={handleChangePage}
                    />
                  )}
                </Grid>
                <ListBills
                  bills={bills}
                  handleDownload={handleDownload}
                  handleManualOk={handleManualOk}
                  handleSortBills={handleSortBills}
                  order={order}
                  isRefetching={refetchingBills}
                  isSuperAdmin={isSuperAdmin}
                  isManager={isManager}
                  handleShowOrder={handleShowOrder}
                  setMethod={setMethod}
                  method={method}
                />
              </>
            ) : (
              !isLoading &&
              !refetchingBills && (
                <Grid container justifyContent="center">
                  <Typography>{t("NO_RESULTS")}</Typography>
                </Grid>
              )
            )}
          </Grid>
          {numberPages > 1 && (
            <Grid item xs={12}>
              <Pagination
                count={numberPages}
                sx={{
                  mb: 30,
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#169a89",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </Grid>
      )}
      {/* popover confirmar manual ok */}
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("MANUAL_OK_TITTLE")}
        content={t("MANUAL_OK_CONTENT")}
        onAccept={handleConfirmManualOk}
      />
      {/* popover sepa download confirm */}
      <CustomDialog
        isOpen={isSepaOpen}
        onClose={handleCloseSepa}
        title={t("SEPA_TITTLE")}
        content={t("SEPA_CONTENT")}
        onAccept={handleConfirmSepa}
      />
      {/* popover pdf download confirm */}
      <CustomDialog
        isOpen={isPdfOpen}
        onClose={handleClosePdf}
        title={t("PDF_TITTLE")}
        content={t("PDF_CONTENT")}
        onAccept={handleConfirmPdf}
      />
    </>
  );
};
export default Billing;
