import React, { useState } from "react"
import TimePicker from "../../shared/TimePicker"
import { Box, Button, IconButton, Grid, Typography, InputLabel } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';

const WeekSchedule = (props) => {

    const { formController, isDisabled } = props;
    const { formData, setFormParamsNotEvent } = formController;

    const { t } = useTranslation();
    const time = { value: '', hour_start: '', hour_end: '' };

    const dayKeys = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]

    const DayInitial = (day) => {
        const dayInLanguage = t(day.toUpperCase());
        if (dayInLanguage == 'MIÉRCOLES') return 'X'
        return dayInLanguage[0];
    }
    //week days translate
    const TranslateDay = (day) => {
        let dayTranslate = t(day.toUpperCase());
        dayTranslate = dayTranslate.charAt(0) + dayTranslate.slice(1).toLowerCase();
        return dayTranslate;
    }
    const defaultSchedule = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: []
    }

    const weekSchedule = formData.premise.week_schedule ?? defaultSchedule
    const handleDaysClick = (dayKey) => {
        const updatedSchedule = JSON.parse(JSON.stringify(weekSchedule));
        //
        if (weekSchedule[dayKey]?.length == 0) {
            const newTime = time;
            newTime.value = weekSchedule[dayKey].length;
            updatedSchedule[dayKey].push(time);
        }
        else {
            updatedSchedule[dayKey] = [];
        }
        //
        setFormParamsNotEvent('premise', 'week_schedule', updatedSchedule);
    }

    const handleAddTime = (e, dayKey) => {
        e.preventDefault()
        const newTime = time;
        newTime.value = weekSchedule[dayKey].length;
        const updatedSchedule = JSON.parse(JSON.stringify(weekSchedule));
        updatedSchedule[dayKey].push(time);
        setFormParamsNotEvent('premise', 'week_schedule', updatedSchedule);
    }

    const handleRemoveTime = (e, dayKey) => {
        e.preventDefault()
        const newTime = time;
        newTime.value = weekSchedule[dayKey].length;
        const updatedSchedule = JSON.parse(JSON.stringify(weekSchedule));
        updatedSchedule[dayKey].pop();

        setFormParamsNotEvent('premise', 'week_schedule', updatedSchedule);
    }

    const handleTimeChange = (dayKey, attribute, index, value) => {
        const updatedSchedule = JSON.parse(JSON.stringify(weekSchedule));
        updatedSchedule[dayKey][index][attribute] = value;
        setFormParamsNotEvent('premise', 'week_schedule', updatedSchedule);
    }

    return (
        <>
            <Grid item xs={12} md={12} >
                <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("TIMETABLE")}
                </InputLabel>
            </Grid >
            <Grid item xs={12} container columnGap={"5px"} rowGap={"5px"}>
                {dayKeys.map((day) => (
                    <Button
                        disabled={isDisabled}
                        variant="outlined"
                        key={day}
                        onClick={() => { handleDaysClick(day); }}
                        sx={{
                            minWidth: "36px",
                            borderColor: "var(--secondary-color)",
                            paddingInline: "10px",
                            color: weekSchedule[day]?.length > 0 ? "#fff" : "var(--secondary-color)",
                            backgroundColor: weekSchedule[day]?.length > 0 ? "var(--secondary-color)" : "",
                            ":hover": {
                                borderColor: "var(--secondary-color)",
                                backgroundColor: weekSchedule[day]?.length > 0 ? "var(--secondary-color)" : ""
                            }
                        }}
                    >{DayInitial(day)}</Button>
                ))}
            </Grid>
            <Grid item container>
                {dayKeys.map((day) => (
                    <Grid container alignItems="center" key={day}>
                        {
                            weekSchedule[day]?.length > 0 ?
                                <Grid item xs={12} alignSelf="flex-start" sx={{ paddingTop: "15px" }}>
                                    <Typography>{TranslateDay(day)}</Typography>
                                </Grid>
                                : ''
                        }
                        {
                            weekSchedule[day]?.map((timeTable, index) => {
                                return (
                                    <Grid key={index} item container xs={12} sm={9} alignItems="center" columnGap="10px">
                                        <TimePicker
                                            initialTime={timeTable.hour_start}
                                            value={timeTable.hour_start}
                                            onTimeChange={(value) => handleTimeChange(day, 'hour_start', index, value)}
                                            disabled={isDisabled}
                                        />
                                        <Typography>to</Typography>
                                        <TimePicker
                                            initialTime={timeTable.hour_end}
                                            value={timeTable.hour_end}
                                            onTimeChange={(value) => handleTimeChange(day, 'hour_end', index, value)}
                                            disabled={isDisabled}
                                            error={timeTable.hour_end < timeTable.hour_start && timeTable.hour_end !== ''}
                                            helperText={t("HOUR_END_ERROR")}
                                        />
                                    </Grid>
                                )
                            })
                        }
                        {
                            weekSchedule[day]?.length > 0 ?
                            <Grid item container xs={3} flexWrap={"nowrap"}>
                                <IconButton onClick={(e) => handleAddTime(e, day)} disabled={isDisabled}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton onClick={(e) => handleRemoveTime(e, day)} disabled={isDisabled}>
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                            : ''
                        }
                    </Grid>
                ))}
            </Grid>
        </>

    )
}

export default WeekSchedule