import React, { useState, useEffect } from "react";
import { Button, Grid, Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import "./ImageUpload.css";

// Para que pueda haber más de un imageUploader a la vez
let idCounter = 0;

const ImageUpload = ({ onImageSelect, multi, url, selectedImagesInForm, error, editEvent, disabled }) => {
  //
  //
  const unifyImageData = (imageObject) => {
    return 'data:' + imageObject.mime + ';' + imageObject.image;
  };
  //

  let selectedImagesInFormChecked = [];
  if (Array.isArray(selectedImagesInForm)) {
    for (let i = 0; i < selectedImagesInForm.length; i++) {
      if (selectedImagesInForm[i] && typeof selectedImagesInForm[i] === 'object') {
        selectedImagesInFormChecked[i] = unifyImageData(selectedImagesInForm[i]);
      }
      else {
        selectedImagesInFormChecked[i] = selectedImagesInForm[i];
      }
    }
  }
  else if (selectedImagesInForm && typeof selectedImagesInForm === 'object') {
    selectedImagesInFormChecked = [unifyImageData(selectedImagesInForm)];
  }
  //
  const { t } = useTranslation();
  const [selectedImages, setSelectedImages] = useState(selectedImagesInFormChecked);
  const [inputId, setInputId] = useState("");
  const cloudImage = editEvent ? '../../../media/upload.svg' : '/media/upload.svg'
  const [fileError, setFileError] = useState(null);

  // Se genera un id por ImageUploader para que no haya información cruzada
  useEffect(() => {
    setInputId(`imageInput-${idCounter++}`);
  }, []);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleImageUpload(files);
    }
  };

  const handleImageUpload = (files) => {
    const validExtensions = [".jpg", ".png"];
    const validImages = [];
    const invalidFiles = [];
    let fileExceedsSizeLimit = false;

    Array.from(files).forEach((file) => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      const fileSize = file.size / (1024 * 1024); // Tamaño en MB

      if (validExtensions.includes(fileExtension)) {
        if (fileSize <= 4) {
          const reader = new FileReader();
          reader.onloadend = () => {
            validImages.push(reader.result);
            if (validImages.length === files.length) {
              setSelectedImages(validImages);
              onImageSelect(validImages.length === 1 ? validImages[0] : validImages);
              setFileError(null);
            }
          };
          reader.readAsDataURL(file);
        } else {
          fileExceedsSizeLimit = true;
        }
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      setFileError(t("IMAGE_ERROR") + invalidFiles.join(', '));
    } else if (fileExceedsSizeLimit) {
      setFileError(t("SIZE_ERROR"));
    }
  };

  const handleFileInput = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      handleImageUpload(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
    onImageSelect(newImages); // Actualizamos la lista de imágenes en el componente padre.
  };

  const handleDeleteUrl = () => {
    onImageSelect([]);
  }

  return (
    <Grid container className="imageContainer">
      <div
        className={error ? "dropZoneError" : "dropZone"}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}>
        {selectedImages.length <= 0 &&
          <>
            {disabled ?
              <Box
                component="img"
                alt="Upload"
                src={url && url.length > 0 ? url : cloudImage}
                sx={{ height: 120, width: 170, mt: 2 }}
                className="cloudIcon"
              />
              : <Box
                component="img"
                alt="Upload"
                src={url && url.length > 0 ? url : cloudImage}
                sx={{ height: 50, width: 50, mt: 2 }}
                className="cloudIcon"
              />
            }
            {(url?.length > 0 && !disabled) &&
              <IconButton sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }} onClick={handleDeleteUrl}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          </>
        }
        {selectedImages.length > 0 ? (
          selectedImages.map((image, index) => (
            <Grid item key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
              <img src={image} alt="Uploaded" className="uploadedImage" />
              <IconButton sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }} onClick={() => handleDelete(index)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          ))
        ) : (
          (url && url.length > 0) || disabled ? "" : <p>{t("DRAG_DROP_SELECT")}</p>
        )}
        {fileError === t("SIZE_ERROR") ?
          <p style={{ color: 'red' }}>{t("FILE_SIZE_ERROR")}</p> :
          fileError && <p style={{ color: 'red' }}>{fileError}</p>
        }
        <input
          type="file"
          accept="image/jpg, image/png"
          id={inputId}
          style={{ display: "none" }}
          onChange={handleFileInput}
          multiple={multi}
        />
        <label htmlFor={inputId}>
          {!disabled &&
            <Button
              component="span"
              className="oniria-btn"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, px: 3, fontSize: "15px", borderRadius: 3 }}
            >
              {t("SELECT_FILE")}
            </Button>}
        </label>
      </div>
    </Grid>
  );
};

export default ImageUpload;
