import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DrawerHeaderSideBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const WebSideBar = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isAffiliate = roles.find((element) => element === "affiliated");
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isAdmin = roles.find((element) => element === "admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const canPublishTrips = userInfo.trips;

  return (
    <>
      <DrawerHeaderSideBar>
        <Box
          style={{maxWidth: '100px', margin: 'auto', padding: '5px'}}
          sx={{ color: "white" }}
          component="img"
          alt="Logo"
          src={"/media/tardeo_logo.svg"}
        />
        <IconButton onClick={() => props.setOpen(!props.open)}>
          {props.open ? (
            <ChevronLeftIcon sx={{ color: "white" }} />
          ) : (
            <ChevronRightIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeaderSideBar>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block", color: "white" }}>
          <ListItemButton
            onClick={() => {
              navigate("/home");
            }}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt={t("HOME")}
                src={"/media/sidebarIcons/home.svg"}
                sx={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <ListItemText primary={t("HOME")} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* GESTOR EVENTOS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <EventAvailableIcon />
            {props.open ? <Typography ml={2}>{t("EVENTS")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            <ListItem disablePadding sx={{ display: "block", color: "white" }}>
              <ListItemButton
                onClick={() => {
                  navigate("/events");
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    alt={t("EVENT_MANAGER")}
                    src={"/media/sidebarIcons/events.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("EVENT_MANAGER")} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {(canPublishTrips || isSuperAdmin) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/trips");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("TRAVEL_MANAGER")}
                      src={"/media/sidebarIcons/travels.svg"}
                      sx={{ height: 30, width: 30 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("TRAVEL_MANAGER")}
                    sx={{ opacity: props.open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </AccordionDetails>
        </Accordion>

        {/* TRANSACTIONS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <AccountBalanceIcon />
            {props.open ? <Typography ml={2}>{t("TRANSACTIONS")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isManager || isAdmin) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/sales");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("SALES")}
                      src={"/media/sidebarIcons/bills.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("SALES")} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isManager || isAdmin) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/billing");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("BILLING_PLUS")}
                      src={"/media/sidebarIcons/bill_plus.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("BILLING_PLUS")} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isManager) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/refunds");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("REFUNDS")}
                      src={"/media/sidebarIcons/refund.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("REFUNDS")} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
          </AccordionDetails>
        </Accordion>
        {/* GESTOR ACCESOS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <PersonOutlineIcon />
            {props.open ? <Typography ml={2}>{t("MANAGER")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isAdmin || isManager) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/organizations");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("ENTITIES")}
                      src={"/media/sidebarIcons/organizations.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("ENTITIES")} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding sx={{ display: "block", color: "white" }}>
              <ListItemButton
                onClick={() => {
                  navigate("/premises");
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    alt="Premises"
                    src={"/media/sidebarIcons/premise.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("VENUES")} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/admins");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("ADMINISTRATOR_MANAGER")}
                      src={"/media/sidebarIcons/admin.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("ADMINISTRATOR_MANAGER")}
                    sx={{ opacity: props.open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {(isSuperAdmin || isAffiliate) && (
              <ListItem disablePadding sx={{ display: "block", color: "white" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/users");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={t("USER_MANAGER")}
                      src={"/media/sidebarIcons/users.svg"}
                      sx={{ height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("USER_MANAGER")} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem disablePadding sx={{ display: "block", color: "white" }}>
              <ListItemButton
                onClick={() => {
                  navigate("/rrpps");
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    alt={t("RRPP_MANAGER")}
                    src={"/media/sidebarIcons/rrpp.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("RRPP_MANAGER")} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </List>
    </>
  );
};

export default WebSideBar;
