import React from "react";
import { Toolbar, Typography, Container, Divider, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

function Footer() {
  const { t } = useTranslation();

  return (
    <Box className="oniria-footer">
      <Toolbar
        sx={{
          backgroundColor: "var(--oniria-sky)",
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            direction: "row",
          }}
        >
          <Grid container spacing={1}>
            <Grid item sx={{ display: "flex" }}>
              <Link
                href="https://www.oniriastudios.com"
                color="inherit"
                variant="body2"
                underline="none"
                sx={{ color: "black" }}
              >
                {t("DEVELOPED_BY")}
              </Link>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Typography variant="body2" color="black">
                {t("COPYRIGHT_RESERVED")}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Link
                href="mailto:contact@oniriastuidios.com"
                variant="body2"
                color="inherit"
                sx={{ color: "black" }}
              >
                {t("APP_EMAIL")}
              </Link>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Typography variant="body2" color="black">
                {t("ONIRIA_PHONE_NUMBER")}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Link
                href="https://www.oniriastudios.com/"
                color="inherit"
                variant="body2"
                sx={{ color: "black" }}
              >
                {t("WEB_ADDRESS")}
              </Link>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
            </Grid>
            <Grid item>
              <Link
                href="https://www.oniriastudios.com/dpo"
                color="inherit"
                variant="body2"
                underline="none"
                sx={{ color: "black" }}
              >
                {t("PRIVACY_POLICY")}
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </Box>
  );
}

export default Footer;
