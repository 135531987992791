import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { focusColor } from "../../components/shared/textFieldStyle";
import useDebounce from "../../hooks/useDebouncing";
import { getPremisesList } from "../../services/premisesServices";
import { getAllRrpp } from "../../services/rrppsServices";

let numberRrpps = "1";
let optPages = ["5", "10", "15", "20"];

const RrppsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [premise, setPremise] = useState("all");
  const [rrppsFiltered, setRrppsFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleEventClick = (id) => {
    navigate(`/rrpps/${id}`);
  };

  const handleEditClick = (id) => {
    navigate(`/rrpps/edit_rrpp/${id}`);
  };

  const breadcrumbs = [
    {
      name: t("RRPP_MANAGER"),
      link: "/rrpps",
    },
  ];

  const debounceSearch = useDebounce((value) => handleSearchChange(value), 500);

  const handleSearchChange = (value) => {
    setSearch(value);
    setPage(1);
  };

  const handleQuerySuccess = (data) => {
    numberRrpps = data.total;
    setPage(data.page);
    setRrppsFiltered(data.data);
  };

  const { isLoading, isRefetching } = useQuery(
    ["rrppsFiltered", premise, search, page, perPage],
    () => getAllRrpp(premise, search, page, perPage),

    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
      // retry: false,
    }
  );

  //Change page
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //Sedes
  const { data: premises } = useQuery(["premises"], () => getPremisesList(), {
    refetchOnWindowFocus: false,
  });

  const premiseItems = premises?.map((premise) => (
    <MenuItem key={premise.premise_id} value={premise.premise_id}>
      {premise.premise_name}
    </MenuItem>
  ));

  const handlePremiseFilter = (data) => {
    setPremise(data);
    setPage(1);
  };

  const handlePageFilter = (data) => {
    setPerPage(data);
    setPage(1);
  };

  const numberPages = Math.ceil(parseFloat(numberRrpps) / parseFloat(perPage));

  //download options
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const tableHeaders = [t("NAME"), t("SURNAME"), t("EMAIL"), t("PHONE")];

  const tableData = rrppsFiltered?.map((rrpp) => [rrpp.name, rrpp.surname, rrpp.email, rrpp.phone]);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("rrppList.pdf");
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_RRPPS")} />
      <Grid
        container
        spacing={2}
        mb={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Grid item>
          <Button
            type="submit"
            onClick={() => navigate("/rrpps/create_rrpp")}
            className="oniria-btn"
            variant="contained"
            sx={{ fontSize: "12px", borderRadius: 3 }}
          >
            {t("CREATE_RRPP")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={handleOpenPopover}
          >
            <CloudDownloadIcon fontSize="small" />
            {t("DOWNLOAD_LIST")}
          </Button>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            id={popoverId}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <ButtonGroup
              variant="contained"
              orientation="vertical"
              aria-label="vertical button group"
            >
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download csv"
              >
                <CSVLink
                  data={tableData}
                  filename={"rrpp_assistants.csv"}
                  target="_blank"
                  separator={";"}
                  headers={tableHeaders}
                  className="csv-link"
                >
                  {t("CSV_FORMAT")}
                </CSVLink>
              </Button>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download pdf"
                onClick={generatePDF}
              >
                {t("PDF_FORMAT")}
              </Button>
            </ButtonGroup>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={11} sm={7} md={6} sx={{ mb: 2 }}>
          <SearchIcon
            sx={{
              height: 35,
              width: 35,
              mr: 1,
              color: "var(--secondary-color)",
            }}
          />
          <TextField
            label={t("SEARCH")}
            onChange={(e) => debounceSearch(e.target.value)}
            size="small"
            sx={[focusColor, { width: "75%" }]}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={6}>
          <Grid
            container
            columnSpacing={2}
            mt={1}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
          >
            <Grid item mb={1} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("USER_PERPAGE")}</Typography>
              <FormControl sx={[focusColor, { minWidth: 50 }]} size="small">
                <Select
                  value={perPage}
                  onChange={(e) => handlePageFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {optPages.map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={premise}
                  onChange={(e) => handlePremiseFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem key="all" value="all">
                    {t("ALL_SITES")}
                  </MenuItem>
                  {premiseItems}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
        sm={12}
        container
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={"center"}
        pb={2}
        gap={{ xs: 2, sm: 0 }}
      >
        <Typography>
          {t("TOTAL_USERS")} {numberRrpps}
        </Typography>
        <Pagination
          count={numberPages}
          mr={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bolder",
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: "#169a89",
                color: "#fff",
                fontWeight: "bolder",
              },
              fontWeight: "600",
            },
          }}
          page={page}
          onChange={handleChangePage}
        />
      </Grid>
      <Grid item xs={11} md={12} mb={5}>
        {isLoading || isRefetching ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell>{t("USER_AVATAR")}</TableCell>
                  <TableCell>{t("NAME")}</TableCell>
                  <TableCell>{t("SURNAME")}</TableCell>
                  <TableCell>{t("EMAIL")}</TableCell>
                  <TableCell align="center">{t("EDIT")}</TableCell>
                  <TableCell align="center">{t("SHOW")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rrppsFiltered &&
                  rrppsFiltered.map((rrpp, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Avatar src={rrpp.avatar_url}></Avatar>
                      </TableCell>
                      <TableCell>{rrpp.name}</TableCell>
                      <TableCell>{rrpp.surname}</TableCell>
                      <TableCell>{rrpp.email}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="edit"
                          className="oniria-btn"
                          onClick={() => {
                            handleEditClick(rrpp.id);
                          }}
                        >
                          <EditIcon sx={{ color: "white" }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="show"
                          className="oniria-btn"
                          onClick={() => handleEventClick(rrpp.id)}
                        >
                          <VisibilityIcon sx={{ color: "white" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Pagination
        count={numberPages}
        sx={{
          mb: 14,
          display: "flex",
          justifyContent: "center",
          fontWeight: "bolder",
          "& .MuiPaginationItem-root": {
            "&.Mui-selected": {
              background: "#169a89",
              color: "#fff",
              fontWeight: "bolder",
            },
            fontWeight: "600",
          },
        }}
        page={page}
        onChange={handleChangePage}
      />
    </>
  );
};

export default RrppsList;
