import React, { useState } from "react";
import Header from "../../../components/Header";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Details from "../../../components/events/Details";
import Tickets from "../../../components/events/Tickets";
import Extras from "../../../components/events/Extras";
import { createEvent } from "../../../services/eventsServices";
import { createEventErrors, createEventFromTemplate } from "../../../classes/tripClass";
import {
  cleanObjectOfVoidFields,
  transformObjectWithUrls,
} from "../../../components/shared/FormsValidator";
import { useNavigate, useParams } from "react-router-dom";
import { toastMessageError, toastMessageSuccess } from "../../../components/shared/toastMessage";
import useEventLogic from "../../../hooks/useEventLogic";

const CreateEvent = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("EVENTS"),
      link: "/events",
    },
    {
      name: t("CREATE_EVENT"),
      link: "/create_event",
    },
  ];
  const steps = [t("DETAILS"), t("TICKETS"), t("EXTRAS")];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createEventFromTemplate)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createEventErrors))
  );

  const navigate = useNavigate();
  const { eventId } = useParams();
  useEventLogic(eventId, formData, setFormData, setFormParamsErrors);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrorsTicketsAndExtras = (objectName, value) => {
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: value,
    }));
  };

  const setFormParamsValue = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const { details, tickets, extras } = formData;
    //
    try {
      //
      const tripComplete = JSON.parse(JSON.stringify({ ...details }));
      tripComplete.min_age = parseInt(tripComplete.min_age);
      tripComplete.latitude = parseFloat(tripComplete.latitude);
      tripComplete.longitude = parseFloat(tripComplete.longitude);
      tripComplete.limit_tickets_order = parseInt(tripComplete.limit_tickets_order);
      //Double payment
      tripComplete.has_double_payment = tripComplete.has_double_payment == 1;
      tripComplete.first_payment_percentage = parseFloat(tripComplete.first_payment_percentage);
      if (
        typeof tripComplete.slider_urls === "string" ||
        (typeof tripComplete.slider_urls === "object" && tripComplete.slider_urls.mime)
      ) {
        tripComplete.slider_urls = [tripComplete.slider_urls];
      }
      //
      tripComplete.total_capacity = 0;
      for (let i = 0; i < tickets.length; i++) {
        tripComplete.total_capacity += parseInt(tickets[i].initial_stock);
      }
      //
      const tripToSend = await transformObjectWithUrls(tripComplete);

      //
      // const tripId = tripResponse.event.id;
      //
      tripToSend.tickets = [];
      for (let i = 0; i < tickets.length; i++) {
        const ticketCopy = await JSON.parse(JSON.stringify(tickets[i]));
        const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);
        // ticketToSend.event_id = tripId;
        tripComplete.latitude = parseFloat(tripComplete.latitude);
        tripComplete.longitude = parseFloat(tripComplete.longitude);
        // TODO: Revisar como gestionamos estos atributos
        ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock);
        ticketToSend.price = parseFloat(ticketToSend.price);
        ticketToSend.iva = parseInt(ticketToSend.iva);
        ticketToSend.only_international = false;
        ticketToSend.only_in_app = ticketToSend.only_in_app == 1;
        delete ticketToSend.stock;
        // PROMO CODE DISCOUNTS
        for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
          ticketToSend.promotional_code_discounts[k].discount = parseFloat(
            ticketToSend.promotional_code_discounts[k].discount
          );
          ticketToSend.promotional_code_discounts[k].is_active = ticketToSend
            .promotional_code_discounts[k].is_active
            ? true
            : false;
          delete ticketToSend.promotional_code_discounts[k].id;
        }
        //
        if (ticketToSend.international_discount)
          ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
        if (ticketToSend.early_payment_discount)
          ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
        if (ticketToSend.promotional_code_discount)
          ticketToSend.promotional_code_discount = parseFloat(
            ticketToSend.promotional_code_discount
          );
        //
        for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
          ticketToSend.group_discounts[j].min_size = parseInt(
            ticketToSend.group_discounts[j].min_size
          );
          ticketToSend.group_discounts[j].max_size = parseInt(
            ticketToSend.group_discounts[j].max_size
          );
          ticketToSend.group_discounts[j].discount = parseFloat(
            ticketToSend.group_discounts[j].discount
          );
          ticketToSend.group_discounts[j].ticket_id = tickets[i].id;
        }
        //
        // const ticketResponse = await createTicket(ticketToSend);
        // console.log("Ticket response: ", ticketResponse);
        tripToSend.tickets.push(ticketToSend);
      }

      //
      tripToSend.extras = [];
      for (let i = 0; i < extras.length; i++) {
        const extraCopy = await JSON.parse(JSON.stringify(extras[i]));
        const extraToSend = await cleanObjectOfVoidFields(extraCopy);
        // extraToSend.event_id = tripId;
        extraToSend.is_visible = extraToSend.is_visible == 1;
        extraToSend.initial_stock = parseInt(extraToSend.initial_stock);
        extraToSend.price = parseFloat(extraToSend.price);
        extraToSend.stock_by_ticket_max = parseInt(extraToSend.stock_by_ticket_max);
        extraToSend.times_can_be_consumed = parseInt(extraToSend.times_can_be_consumed ?? 1);
        // const extraResponse = await createExtra(extraToSend);
        // console.log("Extra response: ", extraResponse);
        tripToSend.extras.push(extraToSend);
      }

      //
      const tripResponse = await createEvent(tripToSend);
      //
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        navigate("/events");
      }, 1500);
    } catch (error) {
      console.log("Submit error: ", error);
      toastMessageError(t("EDIT_ERROR") + error.response.data.error);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 2;
  };

  const formController = {
    formData,
    setFormData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    setFormErrors,
    setFormErrorsTicketsAndExtras,
    setFormParamsValue,
  };

  const addComponent = (activeStep) => {
    return activeStep === 0 ? (
      <Details formController={formController} />
    ) : activeStep === 1 ? (
      <Tickets formController={formController} eventType={"event"} />
    ) :  (
      <Extras formController={formController} />
    )
    
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_EVENT")} />
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Grid item xs={12} sm={5} md={9}>
            {!isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                  //   if (isStepSkipped(index)) {
                  //    stepProps.completed = false;
                  //  }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { color: "var(--secondary-color)" },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root": { color: "var(--secondary-color)" },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 2,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper connector activeStep={activeStep} sx={{ display: "block" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                          "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 4,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep !== 3 && (
                  <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {addComponent(activeStep)}
    </>
  );
};

export default CreateEvent;
