import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCategories, getCategoryPremise } from "../../../services/categoriesServices";
import { getCities } from "../../../services/eventsServices";
import { getAllGrouping } from "../../../services/organizationsServices";
import { getPremisesList } from "../../../services/premisesServices";
import ButtonsCreateEvent from "./ButtonsCreateEvent";
import SearchFilter from "./filter/SearchFilter";
import SelectFilter from "./filter/SelectFilter";
import TypesFilter from "./filter/TypesFilter";

const EventFilters = (props) => {
  const { t } = useTranslation();
  const {
    search,
    onSearchChange,
    grouping,
    onGroupingFilter,
    premise,
    onPremiseFilter,
    cityFilter,
    onCityFilter,
    dateFilter,
    onDateFilter,
    eventType,
    onEventFilter,
    eventSubcategory,
    setEventSubcategory,
    organization,
  } = props;
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const [eventTypes, setEventTypes] = useState([]);

  //Sedes
  const { data: premises = [] } = useQuery(
    ["premises", grouping, organization],
    () => getPremisesList(grouping, organization),
    {
      refetchOnWindowFocus: false,
    }
  );

  //Ciudades
  const { data: cities = [] } = useQuery("cities", () => getCities(), {
    refetchOnWindowFocus: false,
  });

  const citiesNames = cities.map((city) => ({ id: city.city, name: city.city }));

  //Agrupaciones
  const { data: groups = [], refetch: refetchGrouping } = useQuery(
    "groupings",
    () => getAllGrouping(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  //La ejecutamos solo si es super admin
  useEffect(() => {
    if (isSuperAdmin) {
      refetchGrouping();
    }
    if (isSuperAdmin && premise == "all") {
      refetchCategories();
    }
  }, [premise]);

  //Event types
  useQuery(["eventTypes", premise], () => getCategoryPremise(premise), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setEventTypes(data);
    },
  });
  const { refetch: refetchCategories } = useQuery("allEventTypes", () => getCategories(), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      setEventTypes(data);
    },
  });

  const haveSubcategory = () => {
    if (eventType !== "") {
      const eventOption = eventTypes.find((option) => option.category_name === eventType);
      if (eventOption && eventOption.subcategories && eventOption.subcategories.length > 0) {
        return eventOption.subcategories;
      }
    }
    return null;
  };

  const premisesFormat = premises.map((premise) => ({
    id: premise.premise_id,
    name: premise.premise_name,
  }));

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter search={search} onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent={{ xs: "flex-start", md: "flex-end" }}
      >
        <TypesFilter
          value={eventType}
          onChange={onEventFilter}
          options={[{ id: "no-trip", name: "no-trip" }, ...eventTypes]}
          isEvent
        />
        {haveSubcategory() && (
          <TypesFilter
            value={eventSubcategory}
            onChange={(e) => setEventSubcategory(e)}
            options={[{ id: "subcategories", name: "subcategories" }, ...haveSubcategory()]}
            isEvent
          />
        )}
        {isSuperAdmin && (
          <SelectFilter
            value={grouping}
            onChange={onGroupingFilter}
            options={[{ id: "all", name: t("ALL_ENTITIES") }, ...groups]}
          />
        )}
        <SelectFilter
          value={premise}
          onChange={onPremiseFilter}
          options={[{ id: "all", name: t("ALL_SITES") }, ...premisesFormat]}
        />
        <SelectFilter
          value={cityFilter}
          onChange={onCityFilter}
          options={[{ id: "all", name: t("ALLS_CITIES") }, ...citiesNames]}
        />
        <SelectFilter
          value={dateFilter}
          onChange={onDateFilter}
          options={[
            { id: "upcoming", name: t("UPCOMING") },
            { id: "pasts", name: t("PAST") },
            { id: "alls", name: t("ALLS") },
          ]}
        />
        <ButtonsCreateEvent />
      </Grid>
    </Grid>
  );
};

export default EventFilters;
