import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import CardOrganization from "../../components/organizations/CardOrganization";
import { focusColor } from "../../components/shared/textFieldStyle";
import { getOrganizations } from "../../services/organizationsServices";

const Organizations = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);

  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const breadcrumbs = [
    {
      name: t("ENTITIES"),
      link: "/organizations",
    },
  ];

  const handleQuerySuccess = (data) => {
    setOrganizations(data);
  };
  const { isLoading, refetch } = useQuery("organizations", () => getOrganizations(), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    const filteredOrganizations = organizations.filter((organization) =>
      organization.legal_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOrganizations(filteredOrganizations);
  };
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_ORGANIZATION")} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{ mb: 5, ml: 3, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={12} md={5}>
            <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
            <TextField
              label={t("SEARCH")}
              value={search}
              onChange={handleSearchChange}
              size="small"
              sx={[focusColor, { width: "75%" }]}
            />
          </Grid>
          {(isSuperAdmin || isManager) && (
            <Grid item sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => navigate("/organizations/validation")}
                    className="oniria-btn"
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    {t("VALIDATION_ORGANIZATIONS")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => navigate("/create_organization")}
                    className="oniria-btn"
                    variant="contained"
                    sx={{ fontSize: "12px", borderRadius: 3 }}
                  >
                    {t("ADD_ORGANIZATION")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
      >
        {search === ""
          ? organizations.map((organization, i) => (
              <CardOrganization refetch={refetch} organization={organization} key={i} />
            ))
          : filteredOrganizations.map((organization, i) => (
              <CardOrganization refetch={refetch} organization={organization} key={i} />
            ))}
      </Grid>
    </>
  );
};

export default Organizations;
