import React, { useState } from "react";
import Header from "../../components/Header";
import LegalPerson from "../../components/account/LegalPerson";
import { useTranslation } from "react-i18next";
import { transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { createAdmin } from "../../services/adminsServices";
import { useNavigate } from "react-router-dom";
import { createAdminErrors, createAdminUser } from "../../classes/userClass";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { ADMIN_ROLE } from "../../constants/variables";

const CreateAdmin = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createAdminUser)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createAdminErrors))
  );
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      name: t("ADMINISTRATOR_MANAGER"),
      link: "/admins",
    },
    {
      name: t("CREATE_ADMIN"),
      link: "/create_admin",
    },
  ];

  const setFormAdminRoles = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    const valueToAdd = e.target[atribute];
    const correspondingValue =
      valueToAdd === "admin"
        ? "delegate"
        : valueToAdd === "delegate"
        ? "admin"
        : valueToAdd === "affiliated"
        ? ""
        : "affiliated";

    if (object[field].includes(valueToAdd)) return;
    object[field] = object[field].filter((role) => role !== correspondingValue);
    object[field] = [...object[field], valueToAdd];

    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleSubmit = async () => {
    let adminToSend = await transformObjectWithUrls(formData.user);
    // Nota: Esto igual lo dejamos asi
    adminToSend.premises_ids = adminToSend.premises_id; // Es premise_ids como lo gestiona
    //adminToSend.premise_id = adminToSend.premises_ids[0];
    // delete adminToSend.premises_id;
    adminToSend.roles = adminToSend.roles
      .map((role) => ADMIN_ROLE[role] || role)
      .filter((role) => role !== "");
    //console.log("Admin to send: ", adminToSend);
    try {
      const response = await createAdmin(adminToSend);
      if (response !== undefined && response === "success") {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate("/admins");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(t("EDIT_ERROR") + error.response?.data?.error);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 0;
  };

  const formController = {
    formData,
    formParams: setFormParams,
    formAddRolesAdminParams: setFormAdminRoles,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
  };
  const addComponent = () => {
    return activeStep === 0 && <LegalPerson formController={formController} />;
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_ADMIN")} />
      {addComponent()}
    </>
  );
};

export default CreateAdmin;
