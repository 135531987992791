import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { focusColor } from "../../shared/textFieldStyle";

function AddCategoryDialog({ name, setName, isOpen, onClose, onAccept, title, text }) {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setName("");
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
        {/* {edit ? t("EDIT_CATEGORY") : t("ADD_CATEGORY")} */}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2} sx={{ color: "black", textAlign: "center" }}>
          {/* {t("ADD_CATEGORY_NEW_NAME")} */}
          {text}
        </DialogContentText>
        <TextField
          id="outlined-basic"
          label={t("NAME")}
          variant="outlined"
          sx={focusColor}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onAccept}
          disabled={name === ""}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCategoryDialog;
