export const user = {
    email: "",
    password: "",
    name: "",
    surname: "",
    phone_code: "",
    phone: "",
    born_at: "",
    avatar_url: "",/*opcional*/
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    genre: "",/*opcional*/
    profession: "",
    type_document_identity: "",
    identity_number: "",
    doc_expiration_date: "",
    //identity_document: "",/*url*/
    nationality: "",
}
export const userErrors = {
    email: "empty",
    //password:"empty",
    name: "empty",
    surname: "empty",
    phone_code: "empty",
    phone: "empty",
    born_at: "empty",
    avatar_url: "",/*opcional*/
    address: "empty",
    city: "empty",
    state: "empty",
    country: "empty",
    postal_code: "empty",
    genre: "",/*opcional*/
    profession: "",
    type_document_identity: "empty",
    identity_number: "empty",
    doc_expiration_date: "empty",
    //identity_document: "empty",/*url*/
    nationality: "empty",
}
export const userClient = {
    name: "",
    surname: "",
    email: "",
    //password: "",
    phone_code: "",
    phone: "",
    genre: "",//Opcional 
    born_at: "",
    nationality: "",
    country_stay: "",
    city_stay: "",
    profession: "", //Opcional 
    type_of_stay: "",
    premise_id: "",
    affiliated_document: "",//Opcional
    affiliated_status: "",
    type_document_identity: "",
    identity_number: "",
    doc_expiration_date: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    grouping_name: ""

}
export const userClientErrors = {
    name: "empty",
    surname: "empty",
    phone_code: "empty",
    email: "empty",
    //password: "",
    phone: "empty",
    genre: "",//Opcional 
    born_at: "empty",
    nationality: "empty",
    country_stay: "empty",
    city_stay: "empty",
    profession: "", //Opcional 
    type_of_stay: "empty",
    premise_id: "empty",
    affiliated_document: "",//Opcional
    affiliated_status: "empty",
    type_document_identity: "empty",
    identity_number: "empty",
    doc_expiration_date: "empty",
    address: "empty",
    country: "empty",
    state: "empty",
    city: "empty",
    postal_code: "empty",
}

export const userAdmin = {
    organization_id: "",
    premises_id: [],
    email: "",
    password: "",
    name: "",
    surname: "",
    phone_code: "",
    phone: "",
    born_at: "",
    avatar_url: "",/*opcional*/
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    roles:[],
    genre: "",/*opcional*/
    profession: "",
    type_document_identity: "",
    identity_number: "",
    doc_expiration_date: "",
    //identity_document: "",/*url*/
    nationality: "",
}
export const userAdminErrors = {
    organization_id: "empty",
    premises_id: "empty",
    email: "empty",
    name: "empty",
    surname: "empty",
    phone_code: "empty",
    phone: "empty",
    born_at: "empty",
    avatar_url: "",/*opcional*/
    address: "empty",
    city: "empty",
    state: "empty",
    country: "empty",
    postal_code: "empty",
    roles:"empty",
    genre: "",/*opcional*/
    profession: "",
    type_document_identity: "empty",
    identity_number: "empty",
    doc_expiration_date: "empty",
    nationality: "empty",
}
export const userAdminErrorsOk = {
    organization_id: "",
    premises_id: "",
    email: "",
    name: "",
    surname: "",
    phone_code: "",
    phone: "",
    born_at: "",
    avatar_url: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    roles:"",
    genre: "",/*opcional*/
    profession: "",
    type_document_identity: "",
    identity_number: "",
    doc_expiration_date: "",
    nationality: "",
}
export const extra = {
    extra_id: "",
    amount: ""
}
export const ticket = {
    ticket_id: "",
    client_name: "", // OPTIONAL
    client_surname: "", // OPTIONAL
    client_email: "", // OPTIONAL
    client_nationality: "", // OPTIONAL
    client_gender: "",// OPTIONAL
    client_document_id: "", // OPTIONAL
    client_document_type: "", // OPTIONAL
    client_birth_date: "", // OPTIONAL
    client_document_expiry_date: "", // OPTIONAL
    promotional_code: "", // OPTIONAL
    extras: [extra]
}
export const userAssistant = {
    user_id: "",
    event_id: "",
    payment_type: "",
    tickets: []
}


export const userAssistantErrors = {
    user_id: "empty",
    event_id: "",
    payment_type: "empty",
    tickets: []
}
export const ticketErrors = {
    ticket_id: "",
    client_name: "empty", 
    client_surname: "empty",
    client_email: "empty", 
    client_nationality: "empty", 
    client_gender: "", // OPTIONAL
    client_document_id: "empty", 
    client_document_type: "empty", 
    client_birth_date: "empty", 
    client_document_expiry_date: "empty", 
    promotional_code: "", // OPTIONAL
    extras: []
}

export const ticketErrorsOk = {
    ticket_id: "",
    client_name: "",
    client_surname: "",
    client_email: "",
    client_nationality: "", 
    client_gender: "", 
    client_document_id: "", 
    client_document_type: "", 
    client_birth_date: "", 
    client_document_expiry_date: "", 
    promotional_code: "", 
    extras: []
}

export const userAssistantErrorsOk = {
    user_id: "",
    event_id: "",
    payment_type: "",
    tickets: []
}

export const createAdminUser = { user: userAdmin }
export const creaateAssistanUser = userAssistant
export const createAssistantUserErrors = userAssistantErrors;
export const createAssistantUserErrorsOk = userAssistantErrorsOk;
export const createAdminErrors = { user: userAdminErrors }
export const createAdminErrorsOK = { user: userAdminErrorsOk }