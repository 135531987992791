import { Button, Grid, TextField, Typography } from "@mui/material";
import { focusColor } from "../../shared/textFieldStyle";
import { useTranslation } from "react-i18next";

export const CreateCategory = ({ newCategory, setNewCategory, handleOpenAddCategory }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" mb={2} sx={{ color: "var(--secondary-color)" }}>
        {t("CREATE_CATEGORY")}
      </Typography>
      <Grid container gap={2} justifyContent="center" alignItems="center" mb={{ xs: 6 }}>
        <TextField
          id="outlined-basic"
          label={t("CATEGORY")}
          variant="outlined"
          sx={focusColor}
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <Button
          className="oniria-btn"
          variant="contained"
          sx={{
            fontSize: "20px",
            borderRadius: 3,
            margin: "auto",
            width: "fit-content",
            margin: 0,
          }}
          onClick={handleOpenAddCategory}
          disabled={newCategory === ""}
        >
          {t("ADD_CATEGORY")}
        </Button>
      </Grid>
    </>
  );
};
