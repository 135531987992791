import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { details, extra, moreInformation, ticket } from "../../classes/tripClass";
import Header from "../../components/Header";
import RrppTable from "../../components/RrppTable";
import EditEvent from "../../components/events/EditEvent";
import ListAssistants from "../../components/events/ListAssistants";
import Orders from "../../components/events/Orders";
import Statistics from "../../components/events/statistics/Statistics";
import { objectWithTemplateKeys } from "../../components/shared/FormsValidator";
import { optionsValue } from "../../components/shared/OptionsMultiSelect";
import QrReader from "../../components/shared/QrReader";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import {
  getAssistantDetail,
  getEventById,
  getExtrasByEventId,
  getTicketsByEventId,
  validateExtra,
  validateTicket,
} from "../../services/eventsServices";

const EventDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { eventId } = useParams();
  const [formData, setFormData] = useState({});
  const [eventName, setEventName] = useState("");
  const [valueTab, setValueTab] = useState(0);
  const [searchParam] = useSearchParams();
  const [qrResponse, setQrResponse] = useState();
  const [openCheck, setOpenCheck] = useState(false);
  const [assistant, setAssistant] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const numValue = searchParam.get("newValueTab");
    if (numValue) {
      setValueTab(Number(numValue));
    }
  }, []);

  // Query successes
  const handleEventQuerySuccess = async (data) => {
    //
    const moreInformationOrganized = objectWithTemplateKeys(data, moreInformation);
    moreInformationOrganized.included_options = includedOptionsComplete(
      moreInformationOrganized.included_options
    );
    data.is_visible = data.is_visible === 1;
    data.autovalidation = data.autovalidation === 1;
    data.is_international = data.is_international === 1;

    //
    const eventToSee = {
      id: data.id,
      event_type: data.event_type,
      details: objectWithTemplateKeys(data, details),
      moreInformation: moreInformationOrganized,
    };

    setFormData({ ...formData, ...eventToSee });
    setEventName(data.name);
  };

  const handleTicketsQuerySuccess = async (data) => {
    const processedTickets = [];
    for (let i = 0; i < data.length; i++) {
      const nextTicket = objectWithTemplateKeys(data[i], ticket, ["id", "event_id"]);
      nextTicket.is_visible = nextTicket.is_visible === 1;
      nextTicket.early_payment_discount_date_time = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[1].split(".")[0]
        : "";
      nextTicket.early_payment_discount_date = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[0]
        : "";
      processedTickets.push(nextTicket);
    }
    //
    const ticketsToSee = {
      tickets: processedTickets,
    };
    setFormData({ ...formData, ...ticketsToSee });
  };

  const handleExtrasQuerySuccess = async (data) => {
    //
    const processedExtras = [];
    for (let i = 0; i < data.length; i++) {
      processedExtras.push(objectWithTemplateKeys(data[i], extra, ["id", "event_id"]));
    }
    //
    const extrasToSee = {
      extras: processedExtras,
    };
    setFormData({ ...formData, ...extrasToSee });
  };

  // Querys
  const {
    isLoading: isLoadingEvents,
    error: eventError,
    refetch: refetchEvent,
  } = useQuery("event" + eventId, () => getEventById(eventId), {
    onSuccess: handleEventQuerySuccess,
    refetchOnWindowFocus: false,
    // staleTime: 0,
  });
  const {
    isLoading: isLoadingTickets,
    error: ticketsError,
    refetch: refetchTickets,
  } = useQuery("tickets" + eventId, () => getTicketsByEventId(eventId), {
    onSuccess: handleTicketsQuerySuccess,
    refetchOnWindowFocus: false,
    // staleTime: 0,
  });
  const {
    isLoading: isLoadingExtras,
    error: extrasError,
    refetch: refetchExtras,
  } = useQuery("extras" + eventId, () => getExtrasByEventId(eventId), {
    onSuccess: handleExtrasQuerySuccess,
    refetchOnWindowFocus: false,
    // staleTime: 0,
  });

  // Breadcrumbs
  const breadcrumbs = [
    {
      name:
        formData.event_type === "trip"
          ? t("TRAVELS")
          : formData.event_type === "activity"
          ? t("ACTIVITIES")
          : t("EVENTS"),
      link: formData.event_type === "trip" ? "/trips" : "/events",
    },
    {
      name: eventName ? eventName : "",
      link: `/event/${formData.id}`,
    },
    {
      name:
        valueTab === 0
          ? t("EDIT")
          : valueTab === 1
          ? t("LIST_ATTENDEES")
          : valueTab === 2
          ? t("ORDERS")
          : valueTab === 3
          ? t("STATISTICS")
          : t("RRPP"),
    },
  ];

  const includedOptionsComplete = (includedOptionsValues) => {
    if (!includedOptionsValues || Array.isArray(includedOptionsValues)) return [];
    const parsedValues = JSON.parse(includedOptionsValues);
    const optionsToReturn = [];
    for (let i = 0; i < parsedValues.length; i++) {
      const optionToGet = optionsValue.find((obj) => obj.value === parsedValues[i]);
      optionsToReturn.push(optionToGet);
    }
    return optionsToReturn;
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const createAssistant = () => {
    navigate(`/event/${formData.id}/add_assistant`);
  };
  //QR scanner functions
  const scanValues = {};
  const handleQrValue = (value) => {
    // Old method - with event id
    if (value.includes(";")) {
      //Separate the Qr values (uuid, type(ticket | extra) and event_id)
      const qrValue = value.split(";");
      for (const segment of qrValue) {
        const [key, segmentValue] = segment.split("=");
        if (key && segmentValue) {
          scanValues[key] = segmentValue;
        }
      }
      // setTicketId(scanValues.order_ticket_id);
      if (scanValues.event_id !== eventId) {
        toastMessageError(t("TICKET_ERROR") + value + "scanValude => ", scanValues);
      } else {
        getAssitantData(scanValues.order_ticket_id);
      }
    }
    // New method - without event id
    else {
      getAssitantData(value);
    }
  };

  async function getAssitantData(ticketId) {
    try {
      const response = await getAssistantDetail(ticketId, eventId);
      if (
        response[0].extras.every((extra) => extra.consumed_at !== null) &&
        response[0].consumed_at !== null
      ) {
        toastMessageError(t("ALREADY_VALIDATED"));
      } else {
        setAssistant(response[0]);
        setOpenCheck(true);
      }
    } catch (error) {
      console.log("Error getAssistantDetail", error);
      toastMessageError(t("TICKET_ERROR") + error);
    }
  }

  //Dialog validations tickets and extras
  const handleCloseCheck = (e) => {
    setOpenCheck(false);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const optionValue = e.target.value;
    if (optionValue === "all") {
      if (selectedOption.includes("all")) {
        setSelectedOption([]);
      } else {
        const allOptions = [
          "all",
          "ticket",
          ...assistant.extras.map((extra, index) => `extra${index}`),
          ...assistant.extras.flatMap((extra, index2) => {
            if (extra.extra_consumitions != null && extra.times_can_be_consumed > 1) {
              return [...Array(extra.times_can_be_consumed)]?.map(
                (c, i) => `extra${index2}-item${i}`
              );
            } else {
              return [];
            }
          }),
        ];
        setSelectedOption(allOptions);
      }
    } else {
      setSelectedOption((prevSelectedOption) => {
        //Para bonos copas
        let relatedItems = [];
        if (prevSelectedOption.includes(optionValue)) {
          return prevSelectedOption.filter(
            (option) => option !== optionValue && !option?.includes(optionValue)
          );
        } else {
          if (optionValue.includes("extra") && !optionValue.includes("-item")) {
            const indexExtra = parseInt(optionValue.replace("extra", ""), 10);
            relatedItems = assistant.extras.flatMap((extra, index) => {
              if (
                extra.extra_consumitions != null &&
                index == indexExtra &&
                extra.times_can_be_consumed > 1
              ) {
                return [...Array(extra.times_can_be_consumed)]?.map(
                  (c, i) => `extra${index}-item${i}`
                );
              } else {
                return [];
              }
            });
          }
          return [...prevSelectedOption, optionValue, ...relatedItems];
        }
      });
    }
  };

  //Submit validations tickets and extras
  const handleRegisterButtonClick = async (e, selectedOptions) => {
    e.stopPropagation();
    try {
      let response = null;
      if (selectedOptions.includes("ticket") && assistant.consumed_at == null) {
        response = await validateTicket(assistant.order_ticket_id);
        setQrResponse(t("TICKET_OK"));
        setOpenCheck(false);
        toastMessageSuccess(t("TICKET_OK"));
      }
      //limpiamos los bonos para que no haya peticiones de mas
      const prefixes = new Set(
        selectedOptions
          .filter((option) => option.includes("-item"))
          .map((option) => option.split("-")[0])
      );
      const filteredOptions = selectedOptions.filter((option) => !prefixes.has(option));

      for (let i = 0; i < filteredOptions.length; i++) {
        if (filteredOptions[i].startsWith("extra")) {
          const index = parseInt(filteredOptions[i].replace("extra", ""), 10);

          const { consumed_at, order_ticket_extra_id } = assistant.extras[index];
          if (consumed_at == null) {
            const extraResponse = await validateExtra(order_ticket_extra_id);
            if (response === null) {
              response = extraResponse;
            }
          }
          toastMessageSuccess(t("EXTRA_OK"));
        }
      }
    } catch (error) {
      toastMessageError(error.response.data.error);
    } finally {
      setSelectedOption("");
      setOpenCheck(false);
    }
  };

  const isDoublePayment = () =>
    formData.details.first_payment_percentage && formData.details.first_payment_percentage != "";

  // useEffect(() => {
  //   if(ticketIdReady()){
  //     refetch();
  //   }
  // }, [ticketId])

  // For when the queries are loading
  if (isLoadingEvents || isLoadingTickets || isLoadingExtras) {
    return <></>;
  }

  // If is not loading, but we have no details
  // Then we have to force data refetch
  if (!formData.details) {
    refetchEvent();
    return <></>;
  }
  if (!formData.tickets) {
    refetchTickets();
    return <></>;
  }
  if (!formData.extras) {
    refetchExtras();
    return <></>;
  }

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={
          valueTab === 1
            ? t("HEADER_LIST_ASSISTANT") +
              (formData.event_type === "trip"
                ? t("TRIP")
                : formData.event_type === "activity"
                ? t("ACTIVITY")
                : t("EVENT"))
            : valueTab === 2
            ? t("HEADER_ORDERS") +
              (formData.event_type === "trip"
                ? t("TRIP")
                : formData.event_type === "activity"
                ? t("ACTIVITY")
                : t("EVENT"))
            : ""
        }
      />
      <Grid container sx={{ display: "flex", flexDirection: "row-reverse", mb: 2, mt: -2 }}>
        <QrReader onResult={handleQrValue} setQrResponse={setQrResponse} />
        {valueTab === 1 && (
          <Button
            onClick={createAssistant}
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{
              width: "fit-content",
              fontSize: "12px",
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              gap: "4px",
              alignSelf: "flex-end",
              marginBottom: "31px",
            }}
          >
            {t("ADD_ASSISTANT")}
          </Button>
        )}
      </Grid>
      <Grid container justifyContent="center">
        {qrResponse}
      </Grid>
      <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Grid item xs={11} md={9} lg={7.5}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={valueTab}
              onChange={handleChange}
              centered={!theme.breakpoints.down("sm")}
              variant={theme.breakpoints.down("sm") ? "scrollable" : "standard"}
              scrollButtons={theme.breakpoints.down("sm") ? "auto" : "off"}
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: { backgroundColor: "var(--secondary-color)" },
              }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
                label={
                  formData.event_type === "trip"
                    ? t("EDIT_TRIP")
                    : formData.event_type === "activity"
                    ? t("EDIT_ACTIVITY")
                    : t("EDIT_EVENT")
                }
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
                label={t("LIST_ATTENDEES")}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
                label={t("ORDERS")}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
                label={t("STATISTICS")}
              />

              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
                label={t("RRPP")}
              />
            </Tabs>
          </Box>
        </Grid>
        {valueTab === 0 && <EditEvent formData={formData} />}
        {valueTab === 1 && (
          <ListAssistants eventId={eventId} qr={qrResponse} isDoublePayment={isDoublePayment} />
        )}
        {valueTab === 2 && <Orders eventId={eventId} />}
        {valueTab === 3 && <Statistics eventId={eventId} />}
        {valueTab === 4 && <RrppTable eventId={eventId}></RrppTable>}
      </Grid>

      <Dialog
        open={openCheck}
        onClose={handleCloseCheck}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
          {t("REGISTER_ATTENDANT")}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "var(--secondary-color)",
                      "&.Mui-checked": {
                        color: "var(--secondary-color)",
                      },
                    }}
                    value="ticket"
                    disabled={assistant?.consumed_at !== null}
                    checked={assistant?.consumed_at !== null || selectedOption.includes("ticket")}
                    onChange={handleCheckboxChange}
                  />
                }
                label={assistant?.ticket_name}
              />
              {assistant?.extras &&
                assistant.extras.map((extra, index) => (
                  <>
                    <FormControlLabel
                      key={`extra${index}`}
                      control={
                        <Checkbox
                          sx={{
                            ml: 2,
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                          key={extra.extra_id}
                          disabled={extra.consumed_at !== null}
                          checked={
                            extra.consumed_at !== null || selectedOption.includes(`extra${index}`)
                          }
                          value={`extra${index}`}
                          onChange={(e) => handleCheckboxChange(e, extra)}
                        />
                      }
                      label={extra.extra_name}
                    />
                    <Grid sx={{ ml: 4 }} container flexDirection="column">
                      {extra.extra_consumitions && extra.times_can_be_consumed > 1
                        ? [...Array(extra.times_can_be_consumed)].map((consumition, i) => (
                            <FormControlLabel
                              key={`extra-item-${i}`}
                              control={
                                <Checkbox
                                  sx={{
                                    ml: 2,
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                  key={index}
                                  disabled={extra.extra_consumitions[i]?.consumed_at != null}
                                  checked={
                                    extra.extra_consumitions[i]?.consumed_at != null ||
                                    selectedOption.includes(`extra${index}-item${i}`)
                                  }
                                  value={`extra${index}-item${i}`}
                                  onChange={(e) => handleCheckboxChange(e, extra)}
                                />
                              }
                              label={extra.extra_name}
                            />
                          ))
                        : null}
                    </Grid>
                  </>
                ))}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "var(--secondary-color)",
                      "&.Mui-checked": {
                        color: "var(--secondary-color)",
                      },
                    }}
                    value="all"
                    checked={selectedOption.includes("all")}
                    onChange={handleCheckboxChange}
                    disabled={
                      assistant?.consumed_at !== null ||
                      assistant?.extras.some((extra) => extra.consumed_at !== null)
                    }
                  />
                }
                label="Validar todo"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              type="submit"
              className="oniria-btn-cancel"
              variant="contained"
              size="medium"
              sx={{ fontSize: "12px", borderRadius: 3, mr: 2 }}
              onClick={handleCloseCheck}
            >
              {t("CANCEL")}
            </Button>
            <Button
              type="submit"
              className="oniria-btn"
              variant="contained"
              size="medium"
              sx={{ fontSize: "12px", borderRadius: 3 }}
              onClick={(e) => handleRegisterButtonClick(e, selectedOption)}
            >
              {t("ACCEPT")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventDetail;
