import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { editRrpp } from "../../classes/rrppClass";
import { useParams, Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getRrppById } from "../../services/rrppsServices";
import EditRrppForm from "../../components/rrpps/EditRrppForm";
import { Loading } from "../../components/shared/Loading";

const EditRrpp = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("RRPP_MANAGER"),
      link: "/rrpps",
    },
    {
      name: t("EDIT_RRPP"),
      link: `/rrpps/edit_rrpp`,
    },
  ];
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(editRrpp)));
  const params = useParams();
  const rrppId = params.userId;
  const { data, isError, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["editRrpp", rrppId],
    queryFn: () => getRrppById(rrppId),
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, []);

  if (isError) return <Navigate to="/404" />;
  if (isLoading || isRefetching) return <Loading />;

  if (data)
    return (
      <>
        <Header breadcrumbs={breadcrumbs} description={t("EDIT_RRPP_DESCRIPTION")} />
        <EditRrppForm formData={formData} data={data} rrppId={rrppId} />
      </>
    );
};

export default EditRrpp;
