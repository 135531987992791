import { configureStore } from "@reduxjs/toolkit"
import userInfoReducer from "./reducers/userInfoReducer"
import eventReducer from "./reducers/eventsReducer"
import tripsReducer from "./reducers/tripsReducer"

const store = configureStore({
    reducer: {
        userInfo: userInfoReducer,
        allEvents: eventReducer,
        allTrips: tripsReducer
    }
})

export default store