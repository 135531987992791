export const premise = {
  name: "",
  description: "",
  address: "",
  postal_code: "",
  country: "",
  city: "",
  state: "",
  phone_code: "",
  phone: "",
  email: "",
  logo_url: "",
  header_url: "",
  website: "",
  fiscal_name: "",
  fiscal_address: "",
  fiscal_city: "",
  fiscal_state: "",
  fiscal_postal_code: "",
  fiscal_country: "",
  cif: "",
  iban: "",
  bic: "e",
  vat_number: "",
  business_category: "",
  is_international: false,
  events: false,
  trips: false,
  comments: "",
  instagram_url: "",
  facebook_url: "",
  whatsapp_url: "",
  twitter_url: "",
  youtube_url: "",
  tiktok_url: "",
  spotify_url: "",
  itune_url: "",
  soundcloud_url: "",
  week_schedule: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
  organization_id: "",
  latitude: "",
  longitude: "",
};

// organization_id: string;
// last_modified_at?: string;
// comments?: string;
// // SHIPPING ADDRESS
// fiscal_name: string;
// iban: string;
// business_category: string;

// // RRSS
// is_active: boolean;

export const premiseErrors = {
  name: "empty",
  description: "empty",
  address: "empty",
  postal_code: "empty",
  country: "empty",
  city: "empty",
  state: "empty",
  phone_code: "empty",
  phone: "empty",
  email: "empty",
  //logo_url: "empty",
  //header_url: "empty",
  website: "empty",
  fiscal_name: "empty",
  fiscal_address: "empty",
  fiscal_city: "empty",
  fiscal_state: "empty",
  fiscal_postal_code: "empty",
  fiscal_country: "empty",
  cif: "empty",
  iban: "empty",
  // bic: "empty",
  // vat_number: "empty",
  business_category: "empty",
  is_international: "",
  events: "empty",
  trips: "empty",
  comments: "",
  instagram_url: "",
  facebook_url: "",
  whatsapp_url: "",
  twitter_url: "",
  youtube_url: "",
  tiktok_url: "",
  spotify_url: "",
  itune_url: "",
  soundcloud_url: "",
  week_schedule: "",
  organization_id: "",
  latitude: "",
  longitude: "",
};
export const premiseErrorsAllOk = {
  name: "",
  description: "",
  address: "",
  postal_code: "",
  country: "",
  city: "",
  state: "",
  phone_code: "",
  phone: "",
  email: "",
  //logo_url: "",
  //header_url: "",
  website: "",
  fiscal_name: "",
  fiscal_address: "",
  fiscal_city: "",
  fiscal_state: "",
  fiscal_postal_code: "",
  fiscal_country: "",
  cif: "",
  iban: "",
  bic: "",
  vat_number: "",
  business_category: "",
  is_international: "",
  events: "",
  trips: "",
  comments: "",
  instagram_url: "",
  facebook_url: "",
  whatsapp_url: "",
  twitter_url: "",
  youtube_url: "",
  tiktok_url: "",
  spotify_url: "",
  itune_url: "",
  soundcloud_url: "",
  week_schedule: "",
  organization_id: "",
  latitude: "",
  longitude: "",
};
