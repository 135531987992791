import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../shared/CustomDialog";
import { useState } from "react";

const PromotionalCode = (props) => {
  const { t } = useTranslation();
  const {
    checkPromoCodeDiscount,
    promo,
    errors,
    isDisabled,
    ticketIndex,
    promoIndex,
    maxPrice,
    catchButtonClicked,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDesactiveCode = (e) => {
    checkPromoCodeDiscount(
      "is_active",
      ticketIndex,
      promoIndex,
      e,
      t("MINIMUM_CHARACTERS_ERROR"),
      /^[A-Za-z0-9]{4,}$|^$/,
      0
    );
    handleCloseDialog();
  };

  const handleActiveCode = (e) => {
    checkPromoCodeDiscount(
      "is_active",
      ticketIndex,
      promoIndex,
      e,
      t("MINIMUM_CHARACTERS_ERROR"),
      /^[A-Za-z0-9]{4,}$|^$/,
      1
    );
    handleCloseConfirm();
  };

  const promoCodeErrors = errors[ticketIndex].promotional_code_discounts[promoIndex];
  return (
    <Grid
      xs={12}
      item
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "4px",
        marginBottom: "1rem",
        padding: "1rem",
      }}
    >
      <Grid item xs={5}>
        <TextField
          onChange={(e) =>
            checkPromoCodeDiscount(
              "code",
              ticketIndex,
              promoIndex,
              e,
              t("MINIMUM_CHARACTERS_ERROR"),
              /^[A-Za-z0-9]{4,}$|^$/
            )
          }
          value={promo.code}
          margin="normal"
          fullWidth
          id="promotional_code"
          name="promotional_code"
          label={t("PROMOTIONAL_CODE")}
          autoComplete="promotional_code"
          error={
            (promoCodeErrors?.code != "" && promoCodeErrors?.code != "empty") ||
            (promoCodeErrors?.code == "empty" && catchButtonClicked)
          }
          helperText={
            promoCodeErrors?.code && promoCodeErrors?.code != "empty" ? promoCodeErrors?.code : ""
          }
          disabled={isDisabled() || !promo.is_active}
          sx={[focusColor, { margin: 0 }]}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          onChange={(e) =>
            checkPromoCodeDiscount(
              "discount",
              ticketIndex,
              promoIndex,
              e,
              t("INCORRECT"),
              /^(?:\d+(?:\.\d+)?|)$/
            )
          }
          value={promo.discount}
          margin="normal"
          type="number"
          fullWidth
          id="promotional_code_discount"
          name="promotional_code_discount"
          label={t("IN_EU")}
          autoComplete="promotional_code_discount"
          error={
            (promoCodeErrors?.discount !== "" && promoCodeErrors?.discount !== "empty") ||
            (promoCodeErrors?.discount !== "empty" && catchButtonClicked)
          }
          helperText={
            promoCodeErrors?.discount && promoCodeErrors?.discount !== "empty"
              ? promoCodeErrors?.discount
              : ""
          }
          disabled={isDisabled() || !promo.is_active}
          sx={[focusColor, { margin: 0 }]}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0, max: maxPrice }}
        />
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        {promo.is_active ? (
          <IconButton onClick={handleOpenDialog}>
            <DeleteIcon sx={{ color: "var(--oniria-red)" }} />
          </IconButton>
        ) : (
          <Button
            sx={{ color: "var(--oniria-red)", border: "1px solid var(--oniria-red)" }}
            onClick={(e) => handleOpenConfirm(e)}
          >
            {t("INACTIVE ")}
          </Button>
        )}
      </Grid>
      <CustomDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        title={t("UNCHECK_CODE")}
        content={t("UNCHECK_CODE_TEXT")}
        onAccept={(e) => handleDesactiveCode(e)}
      />
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("CHECK_CODE")}
        content={t("CHECK_CODE_TEXT")}
        onAccept={(e) => handleActiveCode(e)}
      />
    </Grid>
  );
};

export default PromotionalCode;
