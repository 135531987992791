import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getPremises() {
  const { data } = await http.get(`${SERVICE.PREMISES}`);
  return data;
}

export async function getPremisesList(organization, group = "all" ) {
  const param = new URLSearchParams();
  if (organization && organization !== "") param.append("organizationid", organization);
  if (group && group !== "all") param.append("group", group);
  
  const { data } = await http.get(`${SERVICE.PREMISES}?${param}`);

  return data;
}

export async function getPremiseById(id) {
  const { data } = await http.get(`${SERVICE.PREMISES}${id}`);
  return data;
}

export async function createPremise(body) {
  const { data } = await http.post(`${SERVICE.PREMISES}`, body);
  return data;
}

export async function putPremise(id, body) {
  const { data } = await http.put(`${SERVICE.PREMISES}${id}`, body);
  return data;
}

export async function deletePremise(id) {
  const { data } = await http.delete(`${SERVICE.PREMISES}${id}`);
  return data;
}

export async function getCards() {
  const { data } = await http.get(`${SERVICE.CARDS}`);
  return data;
}

export async function createCard(body) {
  const { data } = await http.post(`${SERVICE.CARDS}`, body);
  return data;
}

export async function putCard(id, body) {
  const { data } = await http.put(`${SERVICE.CARDS}${id}`, body);
  return data;
}

export async function deleteCard(id) {
  const { data } = await http.delete(`${SERVICE.CARDS}${id}`);
  return data;
}

export async function getPremisePendingUsers(id) {
  const { data } = await http.get(`${SERVICE.PENDING_USERS}${id}`);
  return data;
}

export async function getAllPremisesValidation() {
  const { data } = await http.get(`${SERVICE.PREMISES_VALIDATION}`);
  return data;
}

export async function putValidatePremise(id) {
  const { data } = await http.put(`${SERVICE.PREMISE_VALID}${id}`);
  return data;
}
