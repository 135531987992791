import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getAllRrpp(premise, search, page, perPage) {
  const param = new URLSearchParams();
  if (premise && premise !== "all") param.append("premiseid", premise);
  if (search && search !== "") param.append("search", search);
  if (page && page !== "") param.append("page", page);
  if (perPage && perPage !== "") param.append("perpage", perPage);

  const { data } = await http.get(`${SERVICE.RRPP}?${param}`);

  return data;
}

export async function getRrppById(id) {
  const { data } = await http.get(`${SERVICE.RRPP}/${id}`);
  return data;
}

export async function createRrpp(body) {
  const { data } = await http.post(`${SERVICE.RRPP}`, body);
  return data;
}

export async function editRrpp(id, body) {
  const { data } = await http.put(`${SERVICE.RRPP}/${id}`, body);
  return data;
}

// export async function deleteRrpp(id) {
//   const { data } = await http.delete(`${SERVICE.RRPP}/${id}`);
//   return data;
// }