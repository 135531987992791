import React, { useState } from 'react'
import { Box, Button, Grid, Paper } from '@mui/material'
import ButtonsForms from '../account/forms/ButtonsForms'
import { allFieldsOk } from '../shared/FormsValidator'
import { useTranslation } from 'react-i18next'
import ExtraCard from './ExtraCard'
import { extra, extraErrors } from '../../classes/tripClass'
import { deleteExtra } from '../../services/eventsServices'


const Extras = (props) => {

  const { t } = useTranslation();
  const formController = props.formController;
  const { extras } = formController.formData;
  const { setFormData, setFormErrorsTicketsAndExtras } = formController;
  const { extras: extrasErrors } = formController.validation;
  const [buttonClicked, setButtonClicked] = useState(false)
  // const [errors, setErrors] = useState([extraErrors]);


  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  }

  const setErrors = (extrasErrors) => {
    setFormErrorsTicketsAndExtras("extras", extrasErrors);
  };

  const addExtra = () => {
    //
    const newExtra = JSON.parse(JSON.stringify(extra));
    //
    setFormData(prev => ({
      ...prev,
      extras: [...extras, newExtra]
    }));
    //
    const newExtraErrors = JSON.parse(JSON.stringify(extraErrors));
    setErrors([...extrasErrors, newExtraErrors]);
  };

  const removeExtra = async (index) => {
    //
    if (extras[index].id) {
      // TODO: Eliminar extra en caso de que tenga id
      const extraResponse = await deleteExtra(extras[index].id);
    }
    //
    const updatedExtras = extras.filter((extra, extraIndex) => extraIndex !== index);
    setFormData(prev => ({
      ...prev,
      extras: updatedExtras
    }));
    //
    const updatedExtrasErrors = extrasErrors.filter((extraError, extraIndex) => extraIndex !== index);
    setErrors(updatedExtrasErrors);
  }

  const checkExtraField = (key, e, regex, errorMessage, canBeEmpty, index, parameter = 'value') => {
    //
    const newValue = e.target[parameter];
    let errorValue = '';
    //
    if (newValue === '' && !canBeEmpty) {
      errorValue = 'empty';
    } 
    else if (key == 'initial_stock' && ((parseInt(extras[index].stock) + (parseInt(newValue) - parseInt(extras[index].initial_stock))) < 0 ) ) {
      errorValue = t("STOCK_ERROR")
    }
    else if (regex.test(newValue)) {
      errorValue = '';
    }
    else {
      errorValue = errorMessage;
    }
    //
    const errorsToModify = JSON.parse(JSON.stringify(extrasErrors));
    const currentExtraErrors = errorsToModify[index];
    currentExtraErrors[key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    // formParams(classForm, key, e)
    const updatedExtras = JSON.parse(JSON.stringify(extras));
    updatedExtras[index][key] = newValue;
    setFormData(prev => ({
      ...prev,
      extras: updatedExtras
    }));
  }

  const allExtrasOk = () => {
    //
    let allOk = true;
    //
    extrasErrors.map((extraErrors) => {
      const keys = Object.keys((extraErrors));
      keys.map((key) => {
        if (extraErrors[key] != "") allOk = false;
      })
    })
    //
    return allOk;
  };

  // to set next button form clicked 
  const catchButtonClicked = (value) =>{
    setButtonClicked(value)
  }

  return (
    <Grid container sx={{ mt: 5, mb: 20, px: 1, color: "black", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Grid item xs={11} md={8} >
        <Box component={Paper} elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px"
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            {
              extras && extras.length > 0 ? 
                extras.map((extra, index) => {
                  return <ExtraCard key={index} formController={props.formController} index={index}
                    checkExtraField={checkExtraField} errors={extrasErrors} removeExtra={removeExtra} sendButtonClicked={buttonClicked}/>
                }) : ''
            }
          </Box>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            {/* {trip.blocks.map((block, i) => (
                  block && <Block key={i} index={i} removeBlock={removeBlock} formController={formController} addErrorsBlocks={addErrorsBlock} />
                ))} */}
            <Button fullWidth variant='outlined' size='large' sx={{
              color: "var(--secondary-color)",
              borderColor: "var(--secondary-color)",
              "&:hover": {
                color: "var(--secondary-color)",
                borderColor: "var(--secondary-color)",
                backgroundColor: "white",
              },
            }}
              onClick={() => addExtra()}
            >
              {t("ADD_EXTRA")}
            </Button>
          </Grid>
          {!isDisabled() &&
            <ButtonsForms formController={formController} allFieldsOk={allExtrasOk} errors={extrasErrors} sendButtonClicked={catchButtonClicked}/>
          }
        </Box>
      </Grid>
    </Grid>
  )
}

export default Extras